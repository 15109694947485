export const GreenScreensRegionTypes = {
  city: "City",
  zip3: "3 Digit Zip",
  kma: "Key Market Area",
} as const;

export const GreenScreensDateRangeTypes = {
  last7: "Last 7 Days",
  last30: "Last 30 Days",
  last60: "Last 60 Days",
  last90: "Last 90 Days",
  lastYear: "Last Year",
} as const;
