import React, { useEffect } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Box,
  Stack,
  Divider,
  Typography,
  Skeleton,
  Accordion,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MarketPricingDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";
import PricingBreakdown from "../quote-details-2/PricingBreakdown";
import RecommendedPricing from "../quote-details-2/RecommendedPricing";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GreenScreensLogo from "icons/GreenScreensLogo";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import { SemiCircleProgress } from "@mantine/core";
import ThemeColors from "components/global/theme/theme_colors";
import ArrowUpIcon from "icons/svgs/ArrrowUpIcon";
import ArrowDownIcon from "icons/svgs/ArrrowDownIcon";
import FuelIcon from "icons/svgs/FuelIcon";
import {
  confidenceColor,
  confidenceColorBg,
} from "utilities/functions/ConfidenceLevelColor";

interface MarketPricingProps {
  quote: QuoteResponseDto;
  pricing: MarketPricingDto;
}

const PricingGreenscreensNetwork = (props: MarketPricingProps) => {
  useEffect(() => {}, [props.quote]);

  return (
    <Grid
      container
      sx={{ background: ThemeColors.paperBackground, padding: "4px 17px" }}
    >
      {/* Expected Buy Rate */}
      <Grid sx={{ px: "8px", flex: 1 }} container flexDirection="column">
        <Typography variant="cardTextLargeHeader">Expected Buy Rate</Typography>
        <Typography
          sx={{
            color: "#302E2C",
            fontSize: "20px",
            lineHeight: "20px",
          }}
        >
          {formatCurrency(props.pricing.targetBuyRate)}
        </Typography>
      </Grid>

      {/* Confidence */}
      <Grid
        sx={{ px: "8px", flex: 1, alignItems: "center" }}
        container
        flexDirection="column"
      >
        <Typography variant="cardTextLargeHeader">Confidence</Typography>
        <SemiCircleProgress
          fillDirection="left-to-right"
          orientation="up"
          filledSegmentColor={confidenceColor(
            props.pricing.confidenceLevel ?? 0
          )}
          emptySegmentColor={confidenceColorBg(
            props.pricing.confidenceLevel ?? 0
          )}
          size={50}
          thickness={7}
          value={props.pricing.confidenceLevel ?? 0}
          label={props.pricing.confidenceLevel ?? "-"}
          styles={{
            root: {
              marginTop: "-6px",
            },
            label: {
              color: "#595856",
              fontSize: "12px",
              fontWeight: 300,
              fontFamily: "Poppins",
              bottom: "-4px",
            },
          }}
        />
      </Grid>

      {/* High */}
      <Grid
        container
        sx={{
          flex: 1,
          padding: " 7px 10px",
          alignItems: "center",
        }}
      >
        <ArrowUpIcon
          sx={{
            color: ThemeColors.metaforaGreen,
            width: "24px",
            height: "24px",
            mr: "6px",
          }}
        />
        <Grid container flexDirection="column">
          <Typography variant="cardTextHeader">High</Typography>
          <Typography variant="cardText">
            {formatCurrency(props.pricing.high)}
          </Typography>
        </Grid>
      </Grid>

      {/* Low */}
      <Grid
        container
        sx={{
          flex: 1,
          padding: " 7px 10px",
          alignItems: "center",
        }}
      >
        <ArrowDownIcon
          sx={{
            color: ThemeColors.metaforaGreen,
            width: "24px",
            height: "24px",
            mr: "6px",
          }}
        />
        <Grid container flexDirection="column">
          <Typography variant="cardTextHeader">Low</Typography>
          <Typography variant="cardText">
            {formatCurrency(props.pricing.low)}
          </Typography>
        </Grid>
      </Grid>

      <Grid sx={{ mx: "5px", height: "36px" }}>
        <GreenScreensLogo />
      </Grid>
    </Grid>
  );
};

export default PricingGreenscreensNetwork;
