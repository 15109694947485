import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatProjectedCost } from "api/PricingPlatformApi.generated";
import ReadOnlyField from "components/global/common/ReadOnlyField/ReadOnlyField";
import DATLogo from "icons/DATLogo";
import { useState } from "react";
import { roundNumber } from "utilities/functions/FormatNumber";

interface DATRateViewProps {
  datProjectedCosts: DatProjectedCost[];
  bestFitDatResponse: DatProjectedCost | undefined;
}

const DATRateView = (props: DATRateViewProps) => {
  const { datProjectedCosts, bestFitDatResponse } = props;

  const [currentTimeFrame, setCurrentTimeFrame] = useState<string | undefined>(
    bestFitDatResponse?.days
  );
  const currentRateView = datProjectedCosts.find(
    (datCost) => datCost.days === currentTimeFrame
  );

  const handleTimeFrameChange = (event: SelectChangeEvent) => {
    setCurrentTimeFrame(event.target.value);
  };

  const filteredDatCostsList = datProjectedCosts.filter(
    (datCost) =>
      datCost.days !== bestFitDatResponse?.days ||
      datCost.escalationType === "BEST_FIT"
  );
  return (
    <>
      <Stack direction="row" width={500} spacing={1}>
        <ReadOnlyField
          boldLabel={false}
          boldValue={true}
          type="currency"
          variant="subtitle1"
          label="Predicted Rate"
          valueFontSize={34}
          value={currentRateView?.predictedRate}
        ></ReadOnlyField>

        <Stack direction="column" width={500}>
          <ReadOnlyField
            boldLabel={false}
            variant="subtitle1"
            boldValue={true}
            label="Load to Truck Ratio"
            valueFontSize={16}
            value={currentRateView?.loadToTruckRatio}
          ></ReadOnlyField>
          <ReadOnlyField
            boldLabel={false}
            variant="subtitle1"
            boldValue={true}
            label=""
            valueFontSize={16}
            value={currentRateView?.origin}
          ></ReadOnlyField>
        </Stack>
      </Stack>

      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyField
            boldLabel={false}
            label="Low"
            type="currency"
            value={currentRateView?.lowRate}
            boldValue={true}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            boldLabel={false}
            label="High"
            type="currency"
            value={currentRateView?.highRate}
            boldValue={true}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <Typography variant="body2" align="right">
            <DATLogo />
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction={"row"} spacing={2} sx={{ paddingTop: 2 }}>
        <Grid item>
          <Typography variant="h6">Current Spot Rate</Typography>
        </Grid>
      </Grid>

      <Grid container direction={"row"} spacing={2}>
        <Grid item container xs={6} spacing={1}>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Time Frame</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel id="DAT-timeframe-toggle">Days</InputLabel>
                <Select
                  value={currentTimeFrame}
                  autoWidth
                  label="Days"
                  onChange={handleTimeFrameChange}
                >
                  {filteredDatCostsList.map((datCostObj) => (
                    <MenuItem
                      value={datCostObj?.days}
                      key={datCostObj?.escalationType}
                    >
                      {" "}
                      {datCostObj.escalationType === "BEST_FIT"
                        ? `*${datCostObj?.days}`
                        : datCostObj?.days}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <ReadOnlyField boldLabel={false} label="" value={rateView?.days} boldValue={true}></ReadOnlyField> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2"># of Reports</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ReadOnlyField
                boldLabel={false}
                label=""
                value={currentRateView?.reportsCount}
                boldValue={true}
              ></ReadOnlyField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2"># of Companies</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ReadOnlyField
                boldLabel={false}
                label=""
                value={currentRateView?.companiesCount}
                boldValue={true}
              ></ReadOnlyField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6} spacing={1}>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Origin</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ReadOnlyField
                boldLabel={false}
                label=""
                value={currentRateView?.origin}
                boldValue={true}
              ></ReadOnlyField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Destination</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ReadOnlyField
                boldLabel={false}
                label=""
                value={currentRateView?.destination}
                boldValue={true}
              ></ReadOnlyField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item container direction={"row"} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Mileage</Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <ReadOnlyField
                boldLabel={false}
                label=""
                value={
                  !!currentRateView?.mileage
                    ? roundNumber(currentRateView?.mileage, 2)
                    : ""
                }
                boldValue={true}
              ></ReadOnlyField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DATRateView;
