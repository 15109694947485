import React, { useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { Box, Grid, Tooltip } from "@mui/material";
import StatusChipMenu from "components/global/common/StatusChip/StatusChipWithDropdown";
import { useSelector } from "react-redux";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import RoleTypeNames from "constants/RoleConstant";

// Define an interface for your props

const QuoteHistoryGrid = ({ data, onRowSelection }: any) => {
  const userData = useSelector((state: any) => state.userRequestReducer.user);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const getRowId = (row: { hashedId: any }) => row.hashedId;
  const columns: GridColDef[] = [
    {
      field: "bHashedId",
      headerName: "Quote ID",
      flex: 0.5,
      renderCell: (params) => (
        <a href={`/quote/${params.row.hashedId}`}>{params.row.bHashedId}</a>
      ),
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
      valueGetter: (value, row) => `${row.account.name}`,
    },
    { field: "equipment", headerName: "Equipment", flex: 0.5 },
    {
      field: "origin",
      headerName: "Origin",
      flex: 0.75,
      valueGetter: (value, row) =>
        `${row.stops[0].city}, ${row.stops[0].state}`,
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 0.75,
      valueGetter: (value, row) =>
        `${row.stops[1].city}, ${row.stops[1].state}`,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        const canEditStatus =
          userData.id === params.row.createdById ||
          userData.userRole === RoleTypeNames.OrgAdmin ||
          userData.userRole === RoleTypeNames.Pricing ||
          userData.isAdmin;
        return (
          <StatusChipMenu
            status={params.row.status}
            quoteId={params.id?.toString()}
            isAdmin={canEditStatus}
          />
        );
      },
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      flex: 0.5,
      renderCell: (params) => (
        <Grid container direction="column">
          <Grid item>{params.row.pickupDate?.toLocaleString()}</Grid>
          <Grid item>
            {params.row.estimatedPickupHour ? (
              params.row.estimatedPickupHour.toString().padStart(2, "0") +
              ":" +
              (params.row.estimatedPickupMinute
                ? params.row.estimatedPickupMinute.toString().padStart(2, "0")
                : "00")
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: "totalRate",
      headerName: "Total Rate",
      flex: 0.5,
      renderCell: (params) => <div>{formatCurrency(params.row.totalRate)}</div>,
    },
    {
      field: "createdByEmail",
      headerName: "Created by/on",
      flex: 0.75,
      renderCell: (params) => (
        <Tooltip title={params.row.createdByEmail}>
          <div>
            {params.row.createdByEmail}
            <br />
            {params.row.createdon?.toLocaleString()}
          </div>
        </Tooltip>
      ),
    },
  ];

  const handleRowSelection = (selectedRowIds: GridRowSelectionModel) => {
    setSelectedRows(selectedRowIds);
    onRowSelection(selectedRowIds);
  };

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={getRowId}
        // getRowId={(row) => row.hashedId}
        checkboxSelection
        disableRowSelectionOnClick
        autoHeight={true}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          handleRowSelection(newRowSelectionModel);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default QuoteHistoryGrid;
