import { createContext, useContext, useMemo } from "react";
import { useUserContext } from "./UserProvider";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { NewMetaforaTheme } from "components/global/theme/new_theme";
import {
  createTheme as createMantineTheme,
  MantineProvider,
} from "@mantine/core";

export type CustomThemeContextType = {};

export const CustomThemeContext = createContext<CustomThemeContextType>({});

export const useCustomThemeContext = () => {
  const currentUserContext = useContext(CustomThemeContext);
  if (!currentUserContext) {
    throw new Error("Cannot obtain user context outside of UserProvider");
  }
  return currentUserContext;
};

const mantineTheme = createMantineTheme({
  /** Put your mantine theme override here */
});

const CustomThemeProviderContext = ({ children }: any) => {
  const { currentUser } = useUserContext();

  const userTheme = useMemo(() => {
    let theme = NewMetaforaTheme;

    switch (currentUser?.tenant?.id) {
      case 1:
        return createTheme(theme);
      case 2:
        return createTheme(theme);
      default:
        return createTheme(theme);
    }
  }, [currentUser]);

  return (
    <CustomThemeContext.Provider value={{}}>
      <MantineProvider theme={mantineTheme}>
        <ThemeProvider theme={userTheme}>{children}</ThemeProvider>
      </MantineProvider>
    </CustomThemeContext.Provider>
  );
};

export const CustomThemeProvider = CustomThemeProviderContext;
