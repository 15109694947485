export interface CreateUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  userRoleId: string;
  isAdmin: boolean;
  officeId: string | null;
  accountId: string | null;
  id: number | null;
}

export const InitialCreateUserFormValues: CreateUserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  userRoleId: "",
  isAdmin: false,
  officeId: null,
  accountId: null,
  id: null,
};
export const InitialCreatePublicAPIUserFormValues: CreateUserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  accountId: null,
  userRoleId: "5",
  isAdmin: false,
  officeId: null,
  id: null,
};
