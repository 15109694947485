import {
  AccordionSummary,
  Alert,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  QuoteResponseDto,
  QuoteTargetSellRateDto,
} from "api/PricingPlatformApi.generated";
import { MtfaAccordion } from "components/global/common/MtfaAccordion";
import RecommendedMarkup from "components/pages/quote-details/RecommendedMarkup";
import PricingOverrideTypes from "constants/PricingOverrideType";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../../utilities/functions/FormatCurrency";
import { useApiContext } from "contexts/ApiProvider";
import CombinedMarkup from "./CombinedMarkup";
import { useDispatch } from "react-redux";

interface RecommendedMarkupProps {
  quote?: QuoteResponseDto;
  canEditMarkup: boolean;
  targetSellRate: QuoteTargetSellRateDto;
  onUpdateTargetSellRate: any;
  onPricingOverride?: any;
}

const MarkupBreakdown = (props: RecommendedMarkupProps) => {
  const { quote, canEditMarkup } = props;
  const [showToast, setShowToast] = useState(false);
  const { targetSellRate, onUpdateTargetSellRate } = props;
  const [expanded, setExpanded] = useState<string | false>(false);
  const [totalRulesMargin, setTotalRulesMargin] = useState<number>(0);
  const { quoteApi } = useApiContext();

  const updateTargetSellRate = async (
    newTargetSellRate: QuoteTargetSellRateDto
  ) => {
    onUpdateTargetSellRate(newTargetSellRate);
    const total = newTargetSellRate.markups
      ?.filter((markup) => markup.enabled)
      .reduce((sum, current) => sum + (current?.amount || 0), 0);
    setTotalRulesMargin(total ?? 0);

    var targetSellRate = await quoteApi.updateTargetSellRate(
      quote?.id,
      newTargetSellRate
    );

    setShowToast(true);
  };

  useEffect(() => {
    const total = targetSellRate?.markups
      ?.filter((markup) => markup.enabled)
      .reduce((sum, current) => sum + (current?.amount || 0), 0);
    setTotalRulesMargin(total ?? 0);
  }, [targetSellRate]);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {quote && (
        <RecommendedMarkup
          quote={quote}
          onPricingOverride={props.onPricingOverride}
        />
      )}
      <Typography variant="h6">Markups</Typography>

      <Grid container>
        <CombinedMarkup
          totalDistance={quote?.mileage || 0}
          targetSellRate={targetSellRate}
          updateTargetSellRate={updateTargetSellRate}
          canEditMarkup={canEditMarkup}
          baseQuote={quote}
        />
      </Grid>

      {(quote?.pricingOverrideDto?.overrideType ===
        PricingOverrideTypes.TargetSellRate ||
        quote?.pricingOverrideDto?.overrideType ===
          PricingOverrideTypes.TargetSellRpm) && (
        <MtfaAccordion expanded={false}>
          <AccordionSummary
            aria-controls="rule-based-markup"
            id="rule-based-markup"
          >
            <Stack spacing={1}>
              <Typography sx={{ fontWeight: 600 }}>
                Override Adjustment
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "text.secondary",
                }}
              >
                {formatCurrency(
                  (targetSellRate?.targetSellRate ?? 0) -
                    (targetSellRate?.originalTargetSellRate ?? 0)
                )}
              </Typography>
            </Stack>
          </AccordionSummary>
        </MtfaAccordion>
      )}
      <Snackbar
        open={showToast}
        autoHideDuration={4000}
        onClose={() => setShowToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Target Sell Rate updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default MarkupBreakdown;
