import { Alert, Chip, Menu, MenuItem, Snackbar } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import React, { SyntheticEvent, useState } from "react";
import { UpdateQuoteStatusRequest } from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";

export type StatusChipProps = {
  status?: string;
  quoteId?: string;
  isAdmin: boolean;
};

const StatusChipMenu = ({ status, quoteId, isAdmin }: StatusChipProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [showToast, setShowToast] = React.useState(false);
  const { requestQuoteApi } = useApiContext();

  const activeStatus = updatedStatus ? updatedStatus : status;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const callQuoteStatusUpdateApi = async (
    quoteId?: string,
    updatedStatus?: string
  ) => {
    const updateQuoteStatusRequest = new UpdateQuoteStatusRequest({
      hashedId: quoteId,
      status: updatedStatus,
    });

    requestQuoteApi
      .updateQuoteStatus(updateQuoteStatusRequest)
      .then((response) => {
        if (response === true) {
          setShowToast(true);
        }
      });
  };

  const handleOptionSelect = (event: SyntheticEvent, updatedStatus: string) => {
    event.stopPropagation();
    try {
      callQuoteStatusUpdateApi(quoteId, updatedStatus);
      setUpdatedStatus(updatedStatus);
    } catch (error) {
      console.log(error);
    }

    handleClose();
  };

  const determineChipColor = (status: string | undefined) => {
    switch (status) {
      case "Won":
        return "#4CAF50";
      case "Lost":
        return "#F44336";
      case "Pending":
        return "#E0E0E0";
      default:
        return "#E0E0E0";
    }
  };

  const statusList: string[] = ["Pending", "Won", "Lost"];

  return (
    <div>
      {isAdmin ? (
        <>
          <Chip
            label={activeStatus}
            onClick={handleClick}
            onDelete={handleClick}
            deleteIcon={<ArrowDropDownIcon />}
            style={{ backgroundColor: determineChipColor(activeStatus) }}
            size="small"
          />
          <Menu
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorEl={anchorEl}
          >
            {statusList
              .filter((s) => s !== activeStatus)
              .map((status) => (
                <MenuItem
                  key={status}
                  onClick={(e) => handleOptionSelect(e, status)}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
          </Menu>
        </>
      ) : (
        <Chip
          label={status}
          style={{ backgroundColor: determineChipColor(status) }}
          size="small"
        />
      )}
      <Snackbar
        open={showToast}
        autoHideDuration={4000}
        onClose={() => setShowToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Status Updated successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StatusChipMenu;
