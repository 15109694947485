import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import {
  BatchQuoteHistoryDto,
  FileResponse,
} from "../../../../api/PricingPlatformApi.generated";
import VerticalAlignBottomIcon from "../../../../icons/VerticalAlignBottom";
import { saveAs } from "file-saver";
import { useApiContext } from "contexts/ApiProvider";

const BatchHistoryTable = ({ refreshTable }: { refreshTable: any }) => {
  const [BatchQuoteHistory, setBatchQuoteHistory] = useState<
    BatchQuoteHistoryDto[] | null
  >([]);
  const { batchQuoteApi } = useApiContext();

  const downloadErrors = async (batchID: any) => {
    var response: FileResponse = await batchQuoteApi.createErrorFile(batchID);
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const fileName = response.fileName;
    saveAs(blob, fileName);
  };
  const downloadSuccessfulBatch = async (batchID: any) => {
    var response: FileResponse = await batchQuoteApi.exportBatchQuotes(batchID);
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const fileName = response.fileName;
    saveAs(blob, fileName);
  };

  useEffect(() => {
    const getBatchQuoteHitory = async () => {
      try {
        const data = await batchQuoteApi.getBatchHistory();
        setBatchQuoteHistory(data);
      } catch (error) {
        console.error("Error retreieving batchQuoteHistory", error);
      }
    };
    getBatchQuoteHitory();
  }, [refreshTable]);

  const columns: GridColDef[] = [
    {
      field: "batchID",
      align: "center",
      headerAlign: "center",
      headerName: "Batch Id",
      flex: 1,
    },
    {
      field: "fileName",
      headerName: "Batch Name",
      align: "center",
      headerAlign: "center",
      flex: 2,
    },

    {
      field: "",
      headerName: "",
      flex: 0.1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <VerticalAlignBottomIcon
            sx={{ cursor: "pointer", color: "#026EA1" }}
            onClick={() => {
              if (params.row.status === "Failed") {
                downloadErrors(params.row.batchID);
              } else {
                downloadSuccessfulBatch(params.row.batchID);
              }
            }}
          />
        </>
      ),
    },

    {
      field: "numberOfQuotesIncluded",
      align: "center",
      headerAlign: "center",
      headerName: "Quotes",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => row.status || "Pending",
    },
    {
      field: "createdOnUtc",
      align: "center",
      headerAlign: "center",
      headerName: "Created On",
      flex: 2,
      valueGetter: (value, row) => row.createdOnUtc?.toLocaleString(),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 2,
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => row.createdBy?.email || "", // Access email property
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={BatchQuoteHistory?.length ? BatchQuoteHistory : []}
        getRowId={(row) => row.batchID.toString()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
        autoHeight={true}
      />
    </Box>
  );
};

export default BatchHistoryTable;
