import {
  Alert,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Address,
  ExtraStop,
  OverridePricingRequestDto,
  QuoteResponseDto,
  QuoteTargetSellRateDto,
  SocketHistoricalLoadRequestModel,
  SocketHistoricalLoadResponseModel,
} from "api/PricingPlatformApi.generated";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShareIcon from "../../../icons/Share";
import StatusChipMenu from "../../global/common/StatusChip/StatusChipWithDropdown";
import MarketPricing from "./MarketPricing";
import MarkupBreakdown from "./MarkupBreakdown";
import ProjectedCost from "./ProjectedCost";
import QuoteSummary from "./QuoteSummary";
import ShareQuote from "./ShareQuote";
import { useTheme } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RoleTypeNames from "constants/RoleConstant";
import CloseIcon from "@mui/icons-material/Close";

import PricingOverrideTypes from "constants/PricingOverrideType";
import HistoricalLoad from "../historical-loads/HistoricalLoad";
import {
  HistoricalDateRangeType,
  HistoricalLoadForm,
  RegionType,
} from "utilities/form-initial-values/HistoricalLoadInitialValues";
import { DateTime, Duration } from "luxon";
import {
  GreenScreensDateRangeTypes,
  GreenScreensRegionTypes,
} from "constants/LoadHistoryConstant";
import { useApiContext } from "contexts/ApiProvider";
import QuoteStatus from "../win-loss/QuoteStatus";
import { useFlags } from "launchdarkly-react-client-sdk";
import SimilarQuotesTable from "../similar-quotes/SimilarQuotes";
import {
  pricingMarkupChange,
  pricingOverrideSuccess,
  quoteLoaded,
  resetPricingOverride,
} from "redux/slices/PricingOverride/pricingOverrideSlice";

const QuoteDetails = () => {
  const { hashedId } = useParams();
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [showTextToast, setShowTextToast] = useState(false);
  const [showLinkToast, setShowLinkToast] = useState(false);
  const [showOverrideToast, setShowOverrideToast] = useState(false);
  const [showResetOverrideToast, setResetShowOverrideToast] = useState(false);
  const [historicalLoadData, setHistoricalLoadData] =
    useState<SocketHistoricalLoadResponseModel | null>(null);
  const { quoteApi, historicalLoadApi } = useApiContext();

  const ldFlags = useFlags();

  const navigate = useNavigate();
  const theme = useTheme();

  const openDialog = () => {
    setOpenPopup(true);
  };

  const closeDialog = () => {
    setOpenPopup(false);
  };

  const handleCopyTextToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setShowTextToast(true);
    setOpenPopup(false);
  };

  const handleCopyUrlToClipboard = () => {
    let currentUrl = window.location.href;
    console.log(window.location);
    currentUrl = currentUrl.replace("quote", "quote-details");

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => setShowLinkToast(true))
      .catch((err) => console.error("Failed to copy link", err));
  };

  const [quote, setQuote] = useState<QuoteResponseDto | null>(null);
  const [targetSellRate, setTargetSellRate] =
    useState<QuoteTargetSellRateDto | null>(
      quote?.overriddenQuoteTargetSellRateDto ||
        quote?.quoteTargetSellRateDto ||
        null
    );

  const userData = useSelector((state: any) => state.userRequestReducer.user);
  console.log("userData", userData);
  console.log("quote userData", quote?.createdBy);
  const canEditStatus =
    userData.id === quote?.createdBy?.id ||
    userData.userRole === RoleTypeNames.OrgAdmin ||
    userData.isAdmin;
  const isNotSellRateOverride =
    quote?.pricingOverrideDto == null ||
    (quote?.pricingOverrideDto.overrideType !==
      PricingOverrideTypes.TargetSellRate &&
      quote?.pricingOverrideDto.overrideType !==
        PricingOverrideTypes.TargetSellRpm);
  const hasPermissionToEditMarkup =
    userData.id === quote?.createdBy?.id ||
    userData.userRole === RoleTypeNames.OrgAdmin ||
    userData.userRole === RoleTypeNames.Pricing ||
    (userData.userRole === RoleTypeNames.CompanyStore && userData.isAdmin) ||
    (userData.userRole === RoleTypeNames.Agent && userData.isAdmin);
  const canEditMarkup = isNotSellRateOverride && hasPermissionToEditMarkup;

  useEffect(() => {
    const getQuoteDetails = async () => {
      try {
        const data = await quoteApi.getQuoteDetails(hashedId);
        dispatch(quoteLoaded(data));
        setTargetSellRate(
          data?.overriddenQuoteTargetSellRateDto ||
            data?.quoteTargetSellRateDto ||
            null
        );
        console.log("setQuote");
        setQuote(data);
      } catch (error) {
        // TODO: check more specific status code 403 before redirecting
        navigate("/permission-denied");
      }
    };

    getQuoteDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedId]);

  const handlePricingOverride = async (
    overridePricingRequestDto: OverridePricingRequestDto
  ) => {
    overridePricingRequestDto.quoteId = quote?.id;
    try {
      const data = await quoteApi.overridePricing(overridePricingRequestDto);
      setQuote(data);
      setTargetSellRate(
        data?.overriddenQuoteTargetSellRateDto ||
          data?.quoteTargetSellRateDto ||
          null
      );

      console.log("handlePricingOverride", data);
      setShowOverrideToast(true);
      dispatch(pricingOverrideSuccess(data));
    } catch (error) {
      // navigate('/permission-denied');
    }
  };
  const handleResetPricing = async () => {
    try {
      const resetRequest = new OverridePricingRequestDto();
      resetRequest.quoteId = quote?.id;
      const data = await quoteApi.resetPricingOverride(resetRequest);
      setQuote(data);
      setTargetSellRate(
        data?.overriddenQuoteTargetSellRateDto ||
          data?.quoteTargetSellRateDto ||
          null
      );
      setResetShowOverrideToast(true);
      dispatch(resetPricingOverride(data));
    } catch (error) {
      // navigate('/permission-denied');
    }
  };

  const getHistoricalLoadDetails = async (form: HistoricalLoadForm) => {
    try {
      const request: SocketHistoricalLoadRequestModel | null =
        generateHistoricalLoadRequest(quote, form);
      if (request != undefined) {
        const data = await historicalLoadApi.requestHistoricalLoadData(request);
        setHistoricalLoadData(data);
      }
    } catch (error) {
      // TODO: check more specific status code 403 before redirecting
      navigate("/permission-denied");
    }
  };

  const generateHistoricalLoadRequest = (
    quoteData: QuoteResponseDto | null,
    form: HistoricalLoadForm
  ): SocketHistoricalLoadRequestModel | null => {
    const dateRange = getDateRangeFromInput(form);
    if (
      quoteData != null &&
      quoteData.stops &&
      quoteData.stops.length > 1 &&
      quoteData.stops[0].city
    ) {
      // console.log("form", form);
      return new SocketHistoricalLoadRequestModel({
        originCountry: "US",
        originCity: quoteData.stops[0].city,
        originState: quoteData.stops[0].state,
        originZip: quoteData.stops[0].zipCode,
        destinationCountry: "US",
        destinationCity: quoteData.stops[quoteData.stops.length - 1].city,
        destinationState: quoteData.stops[quoteData.stops.length - 1].state,
        destinationZip: quoteData.stops[quoteData.stops.length - 1].zipCode,
        extraStops:
          quoteData.stops.length > 2
            ? quoteData.stops.slice(1, quoteData.stops.length).map(
                (currElement, index) =>
                  new ExtraStop({
                    order: index + 1,
                    address: new Address({
                      country: "US",
                      city: currElement.city,
                      state: currElement.state,
                      zip: currElement.zipCode,
                    }),
                  })
              )
            : [],
        dateFrom: dateRange.dateFrom.toISODate() ?? "",
        dateTo: dateRange.dateTo.toISODate() ?? "",
        transportType: quoteData.equipmentType?.label.toUpperCase() ?? "VAN",
        currency: "USD",
        region: translateRegion(form.regionType as RegionType),
      });
    }
    return null;
  };

  const getDateRangeFromInput = (
    form: HistoricalLoadForm
  ): { dateFrom: DateTime; dateTo: DateTime } => ({
    dateFrom: getFromDate(form?.dateRangeType as HistoricalDateRangeType),
    dateTo: DateTime.now(),
  });

  const translateRegion = (regionTypeIndicated: RegionType): string => {
    switch (regionTypeIndicated) {
      default:
      case GreenScreensRegionTypes.city: {
        return "CITY";
      }
      case GreenScreensRegionTypes.kma: {
        return "KMA";
      }
      case GreenScreensRegionTypes.zip3: {
        return "3DZIP";
      }
    }
  };

  const getFromDate = (
    dateRangeIndicated: HistoricalDateRangeType
  ): DateTime => {
    switch (dateRangeIndicated) {
      default:
      case GreenScreensDateRangeTypes.last7: {
        return DateTime.now().minus(Duration.fromObject({ weeks: 1 }));
      }
      case GreenScreensDateRangeTypes.last30: {
        return DateTime.now().minus(Duration.fromObject({ days: 30 }));
      }
      case GreenScreensDateRangeTypes.last60: {
        return DateTime.now().minus(Duration.fromObject({ days: 60 }));
      }
      case GreenScreensDateRangeTypes.last90: {
        return DateTime.now().minus(Duration.fromObject({ days: 90 }));
      }
      case GreenScreensDateRangeTypes.lastYear: {
        return DateTime.now().minus(Duration.fromObject({ years: 1 }));
      }
    }
  };

  const handleUpdateTargetSellRate = (newRate: QuoteTargetSellRateDto) => {
    setTargetSellRate(newRate);

    if (quote !== null) {
      quote.quoteTargetSellRateDto = newRate;
      setQuote(quote);

      //dispatch(pricingOverrideSuccess(quote));
      dispatch(pricingMarkupChange(quote));
    }
  };

  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container direction="column" spacing={3}>
          <Grid item container xs={12} direction="row" alignItems="center">
            <Grid item container xs={5} alignItems="baseline" spacing={2}>
              <Grid item>
                <Typography variant="h4">Quote Details </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  #
                  {quote?.batchId === 0
                    ? `${quote?.hashedId}`
                    : `${quote?.batchId} - ${quote?.hashedId}`}
                </Typography>
              </Grid>
              <Grid item>
                {/* <StatusChipMenu
                  status={quote?.status}
                  quoteId={quote?.hashedId}
                  isAdmin={canEditStatus}
                /> */}
                <QuoteStatus
                  quote={quote}
                  canEditStatus={canEditStatus}
                  canEditMarkup={canEditMarkup}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={7}>
              <Button
                color="secondary"
                sx={{ border: "1px solid #1B4960", marginTop: "6px" }}
                onClick={openDialog}
              >
                <Box sx={{ display: "flex", padding: "6px 6px" }}>
                  <span color="#1B4960" style={{ paddingRight: "4px" }}>
                    Share with Customer
                  </span>
                  <ShareIcon color="#1B4960" />
                </Box>
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{ marginLeft: "16px", marginTop: "6px" }}
                onClick={() => navigate("/quote/request")}
              >
                CREATE NEW QUOTE
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {quote ? <QuoteSummary quote={quote} /> : ""}
          </Grid>
          {quote?.pricingOverrideDto?.overrideType &&
            hasPermissionToEditMarkup && (
              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                container
                sx={{ paddingTop: 1 }}
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleResetPricing}
                  sx={{ backgroundColor: "#ed6c03" }}
                >
                  Reset Pricing
                </Button>
              </Grid>
            )}
          <Grid item container spacing={2} pb={2}>
            <Grid item xs={6} sx={{ borderRight: "1px solid #ccc" }}>
              <Stack spacing={1}>
                {quote ? (
                  <ProjectedCost
                    projectedCost={quote.projectedCost?.companyRate}
                    pricingOverrideDto={quote.pricingOverrideDto}
                    onPricingOverride={handlePricingOverride}
                    quoteCreatedByUser={quote?.createdBy}
                  />
                ) : (
                  ""
                )}
                {quote?.projectedCost ? (
                  <MarketPricing
                    quote={quote}
                    projectedCost={quote?.projectedCost}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1}>
                {quote && targetSellRate ? (
                  <MarkupBreakdown
                    targetSellRate={targetSellRate}
                    onUpdateTargetSellRate={handleUpdateTargetSellRate}
                    onPricingOverride={handlePricingOverride}
                    quote={quote}
                    canEditMarkup={canEditMarkup}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
          </Grid>
          {quote ? (
            <Grid
              container
              style={{ border: `1px solid ${theme.palette.info.main}` }}
              padding={1}
              marginLeft={1.3}
            >
              <Grid
                container
                item
                justifyContent="center"
                xs={0.4}
                style={{ height: "100%" }}
              >
                <InfoOutlinedIcon htmlColor={`${theme.palette.info.main}`} />
              </Grid>
              <Grid item container xs={10} direction="column">
                <Grid item>
                  <Typography
                    style={{
                      color: `${theme.palette.primary.main}`,
                      fontWeight: 550,
                    }}
                    variant="subtitle1"
                  >
                    Users are responsible to validate all pricing suggestions
                    and expenses.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ color: `${theme.palette.primary.main}` }}
                    variant="subtitle2"
                  >
                    Quotes are for one pick and one drop. Cargo value under
                    $100K. Standard van, reefer, and flatbed only.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {quote != null && ldFlags["quote-details-show-similar-quotes"] ? (
            <SimilarQuotesTable quote={quote} />
          ) : null}
          {quote != null && ldFlags["quote-details-show-historical-load"] ? (
            <HistoricalLoad
              getHistoricalLoadDetails={getHistoricalLoadDetails}
              loadSearchData={historicalLoadData?.items ?? []}
            />
          ) : null}
        </Grid>
      </Paper>

      {/* Define the popup (Dialog) */}
      <Dialog
        open={openPopup}
        onClose={closeDialog}
        sx={{
          position: "fixed",
          left: "0 !important",
          top: "0 !important",
          zIndex: 9999,
        }}
      >
        <div style={{ maxWidth: "100%" }}>
          <DialogTitle>
            <Typography
              variant="h6"
              textAlign="center"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Copy quote information to share it with a customer
            </Typography>

            <IconButton
              onClick={closeDialog}
              sx={{ position: "absolute", top: "13px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {quote ? (
            <ShareQuote
              shareQuote={quote}
              copyTextToClipboard={handleCopyTextToClipboard}
              copyUrlToClipboard={handleCopyUrlToClipboard}
            />
          ) : (
            ""
          )}
          {/* {quote != null && (
            <HistoricalLoad
              getHistoricalLoadDetails={getHistoricalLoadDetails}
              loadSearchData={historicalLoadData?.items ?? []}
            />
          )} */}
        </div>
      </Dialog>

      <Snackbar
        open={showTextToast}
        autoHideDuration={4000}
        onClose={() => setShowTextToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Text copied to clipboard
        </Alert>
      </Snackbar>
      <Snackbar
        open={showLinkToast}
        autoHideDuration={4000}
        onClose={() => setShowLinkToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Link copied to clipboard
        </Alert>
      </Snackbar>
      <Snackbar
        open={showOverrideToast}
        autoHideDuration={4000}
        onClose={() => setShowOverrideToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Pricing Override Successful
        </Alert>
      </Snackbar>
      <Snackbar
        open={showResetOverrideToast}
        autoHideDuration={4000}
        onClose={() => setResetShowOverrideToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Reset Pricing Override Successful
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default QuoteDetails;
