const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const ToCurrency = (number: number | null | undefined) => {
  if (number === null || number === undefined) {
    return "";
  }
  return formatter.format(number);
};
