import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  ProjectedCostDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";
import { MtfaAccordion } from "components/global/common/MtfaAccordion";
import ReadOnlyField from "components/global/common/ReadOnlyField/ReadOnlyField";
import GreenscreensNetworkRate from "components/pages/quote-details/GreenscreensNetworkRate";
import DATRateView from "components/pages/quote-details/DATRateView";
import React from "react";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import { useSelector } from "react-redux";
import RoleTypeNames from "constants/RoleConstant";

interface MarketPricingProps {
  projectedCost: ProjectedCostDto;
  quote: QuoteResponseDto;
}

const MarketPricing = (props: MarketPricingProps) => {
  const { projectedCost, quote } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const bestFitDatResponse = projectedCost.datProjectedCosts
    ? (projectedCost.datProjectedCosts.find(
        (datCost) => datCost.escalationType === "BEST_FIT"
      ) ?? projectedCost.datProjectedCosts[0])
    : undefined;
  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Typography variant="h6">Market Pricing</Typography>
      <MtfaAccordion
        expanded={expanded === "gsNetworkRate"}
        onChange={handleChange("gsNetworkRate")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="gs-network-rate-content"
          id="gs-network-rate-header"
          sx={{ border: "none" }}
        >
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>
              Greenscreens Network Rate
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {formatCurrency(projectedCost?.networkRate?.targetBuyRate)}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <GreenscreensNetworkRate
            networkRate={projectedCost?.networkRate}
          ></GreenscreensNetworkRate>
        </AccordionDetails>
      </MtfaAccordion>
      <MtfaAccordion
        expanded={expanded === "datRateView"}
        onChange={handleChange("datRateView")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dat-rate-view-content"
          id="dat-rate-view-header"
        >
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>DAT RateView</Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {formatCurrency(bestFitDatResponse?.predictedRate)}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DATRateView
            datProjectedCosts={projectedCost?.datProjectedCosts ?? []}
            bestFitDatResponse={bestFitDatResponse}
          ></DATRateView>
        </AccordionDetails>
      </MtfaAccordion>

      {(quote.equipmentType?.value === 3 ||
        [RoleTypeNames.OrgAdmin, RoleTypeNames.Pricing].includes(
          userData.userRole
        )) && (
        <MtfaAccordion
          expanded={expanded === "truckStop"}
          onChange={handleChange("truckStop")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="truck-stop-content"
            id="truck-stop-header"
          >
            <Stack>
              <Typography sx={{ fontWeight: 600 }}>
                TruckStop Rate Insights
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "text.secondary",
                }}
              >
                {formatCurrency(
                  projectedCost?.truckStopProjectedCost?.predictedRate
                )}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" width={400} spacing={1}>
              <ReadOnlyField
                boldLabel={false}
                type="currency"
                variant="subtitle1"
                label="Predicted Rate"
                boldValue={true}
                valueFontSize={34}
                value={projectedCost?.truckStopProjectedCost?.predictedRate}
              ></ReadOnlyField>
            </Stack>

            <Stack direction="row" spacing={1}>
              <ReadOnlyField
                boldLabel={false}
                label="Low"
                type="currency"
                value={projectedCost?.truckStopProjectedCost?.lowRate}
              ></ReadOnlyField>
              <ReadOnlyField
                boldLabel={false}
                label="High"
                type="currency"
                value={projectedCost?.truckStopProjectedCost?.highRate}
              ></ReadOnlyField>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography style={{ marginBottom: "6px" }}>
                Based on Booked Rates
              </Typography>
              <img
                src={require("../../../assets/truck-stop.png")}
                width={160}
                height={45}
              />
            </Stack>
          </AccordionDetails>
        </MtfaAccordion>
      )}
    </>
  );
};

export default MarketPricing;
