import { SvgIcon } from "@mui/material";

const MarginAmountIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M3 6.1665H21V18.1665H3V6.1665ZM12 9.1665C12.7956 9.1665 13.5587 9.48257 14.1213 10.0452C14.6839 10.6078 15 11.3709 15 12.1665C15 12.9622 14.6839 13.7252 14.1213 14.2878C13.5587 14.8504 12.7956 15.1665 12 15.1665C11.2044 15.1665 10.4413 14.8504 9.87868 14.2878C9.31607 13.7252 9 12.9622 9 12.1665C9 11.3709 9.31607 10.6078 9.87868 10.0452C10.4413 9.48257 11.2044 9.1665 12 9.1665ZM7 8.1665C7 8.69694 6.78929 9.20564 6.41421 9.58072C6.03914 9.95579 5.53043 10.1665 5 10.1665V14.1665C5.53043 14.1665 6.03914 14.3772 6.41421 14.7523C6.78929 15.1274 7 15.6361 7 16.1665H17C17 15.6361 17.2107 15.1274 17.5858 14.7523C17.9609 14.3772 18.4696 14.1665 19 14.1665V10.1665C18.4696 10.1665 17.9609 9.95579 17.5858 9.58072C17.2107 9.20564 17 8.69694 17 8.1665H7Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default MarginAmountIcon;
