import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BatchQuoteProgressBar from "./BatchQuoteProgressBar";
import BatchQuoteSuccessBar from "./BatchQuoteSuccessBar";
import BatchQuoteFailedBar from "./BatchQuoteFailedBar";
import {
  BatchQuoteHistoryDto,
  FileResponse,
} from "api/PricingPlatformApi.generated";
import { saveAs } from "file-saver";
import { useApiContext } from "contexts/ApiProvider";

interface BatchQuoteBarProps {
  batchQuoteStatus: BatchQuoteHistoryDto | null;
}

const BatchQuotelBar: React.FC<BatchQuoteBarProps> = ({ batchQuoteStatus }) => {
  const [isClosed, setIsClosed] = useState(true);
  const [isSuccessHidden, setIsSuccessHidden] = useState(true);
  const [isFailedHidden, setIsFailedHidden] = useState(true);
  const [isInProgressHidden, setIsInProgressHidden] = useState(true);
  const { batchQuoteApi } = useApiContext();

  useEffect(() => {
    // Update state based on batchQuoteStatus
    if (batchQuoteStatus?.status === "Completed") {
      setIsFailedHidden(true); // Hide failed panel
      setIsSuccessHidden(false); // Show success panel
      setIsInProgressHidden(true); // Hide in progress panel
      setIsClosed(false);
    } else if (batchQuoteStatus?.status === "Failed") {
      setIsSuccessHidden(true); // Hide success panel
      setIsFailedHidden(false); // Show failed panel
      setIsInProgressHidden(true); // Hide in progress panel
      setIsClosed(false);
    } else if (batchQuoteStatus?.status === "InProgress") {
      setIsSuccessHidden(true); // Hide success panel
      setIsFailedHidden(true); // Hide failed panel
      setIsInProgressHidden(false); // Show in progress panel
      setIsClosed(false);
    } else {
      setIsClosed(true);
      setIsFailedHidden(true); // Hide failed panel
      setIsSuccessHidden(true); // Hide success panel
      setIsInProgressHidden(true); // Hide in progress panel
    }
  }, [batchQuoteStatus?.status]);

  const handleClose = () => {
    setIsClosed(true);
  };

  const downloadErrors = async () => {
    var response: FileResponse = await batchQuoteApi.createErrorFile(
      batchQuoteStatus?.batchID
    );
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const fileName = response.fileName;
    saveAs(blob, fileName);
  };
  const downloadSuccessfulBatch = async () => {
    var response: FileResponse = await batchQuoteApi.exportBatchQuotes(
      batchQuoteStatus?.batchID
    );
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const fileName = response.fileName;
    saveAs(blob, fileName);
  };
  return (
    <Box
      sx={{
        position: "relative",
        borderBottom: "2px solid #ccc ",
        borderRight: ".5px solid #ccc ",
        borderLeft: ".5px solid #ccc ",
        borderTop: ".5px solid #ccc ",
        width: "100%",
        padding: "10px",
        display: isClosed ? "none" : "block", // Hide the content if isHidden is true
      }}
    >
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={10}>
            <Typography variant="h6">{batchQuoteStatus?.fileName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: "5px",
                right: "0px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} mt={2} mb={2} sx={{ margin: "30px" }}>
          <Grid
            item
            container
            xs={12}
            sx={{ display: isSuccessHidden ? "none" : "block" }}
          >
            <BatchQuoteSuccessBar
              downloadSuccessfulQuotes={downloadSuccessfulBatch}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ display: isFailedHidden ? "none" : "block" }}
          >
            <BatchQuoteFailedBar downloadErrors={downloadErrors} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: isInProgressHidden || false ? "none" : "block" }}
          >
            <BatchQuoteProgressBar
              statusMessage={"Processing quotes. Please wait"}
              infoMessage={
                "Leave this page open to be notified when quotes are finished processing."
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchQuotelBar;
