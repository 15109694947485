import { Alert, Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {
  AccountDto,
  AddOfficeRequestDto,
  UpdateOfficeRequestDto,
} from "api/PricingPlatformApi.generated";
import ManageOfficeAccounts from "components/pages/add-new-office/ManageOfficeAccounts";
import { useApiContext } from "contexts/ApiProvider";
import { setIn } from "final-form";
import { TextField } from "mui-rff";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateOfficeFormValues,
  InitialCreateOfficeFormValues,
} from "utilities/form-initial-values/AddOfficeInitialValues";
import { CreateOfficeValidationSchema } from "utilities/form-validation/CreateOfficeValidationSchema";

const AddNewOffice = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editMode = id && Number(id) > 0;
  const [showOfficeNameExistsMsg, setShowOfficeNameExistsMsg] =
    React.useState(false);
  const [editOfficeFormValues, setEditOfficeFormValues] =
    React.useState<CreateOfficeFormValues>(InitialCreateOfficeFormValues);
  const [accounts, setAccounts] = React.useState<AccountDto[]>([]);
  const { officeApi } = useApiContext();

  useEffect(() => {
    const getOfficeDetails = async () => {
      try {
        const data = await officeApi.getOfficeById(Number(id));
        setAccounts(data.accounts ?? []);
        const newFormValues = {
          name: data.name ?? "",
          accounts: data.accounts,
        };
        setEditOfficeFormValues(newFormValues);
      } catch (error) {
        // TODO: check more specific status code 403 before redirecting
        navigate("/permission-denied");
      }
    };
    if (editMode) {
      getOfficeDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, id]);

  const submitOffice = async (values: CreateOfficeFormValues) => {
    setShowOfficeNameExistsMsg(false);
    officeApi
      .getOfficeByName(values.name)
      .then((response) => {
        if (
          (response && !editMode) ||
          (editMode && response.id !== Number(id))
        ) {
          setShowOfficeNameExistsMsg(true);
        }
        if (editMode) {
          callUpdateOfficeApi(values);
        }
      })
      .catch(() => {
        if (editMode) {
          callUpdateOfficeApi(values);
        } else {
          callCreateOfficeApi(values);
        }
      });
  };
  const callCreateOfficeApi = async (values: CreateOfficeFormValues) => {
    const addOfficeRequestDto = AddOfficeRequestDto.fromJS(values);
    addOfficeRequestDto.accountIds = values.accounts?.map(
      (account) => account.id || 0
    );
    officeApi
      .create(addOfficeRequestDto)
      .then((response) => {
        navigate("/settings/office_management", {
          state: {
            officeCreated: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const callUpdateOfficeApi = async (values: CreateOfficeFormValues) => {
    const updateOfficeRequestDto = UpdateOfficeRequestDto.fromJS(values);
    updateOfficeRequestDto.id = Number(id);
    updateOfficeRequestDto.accountIds = values.accounts?.map(
      (account) => account.id || 0
    );
    officeApi
      .update(updateOfficeRequestDto)
      .then((response) => {
        navigate("/settings/office_management", {
          state: {
            officeCreated: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validateFormValues =
    (schema: any) => async (values: CreateOfficeFormValues) => {
      if (typeof schema === "function") {
        schema = schema();
      }
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (err: any) {
        const errors = err.inner.reduce((formError: any, innerError: any) => {
          return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
      }
    };
  const validate = validateFormValues(CreateOfficeValidationSchema);

  return (
    <Grid size={{ xs: 12 }} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid size={{ xs: 12 }} mb={2}>
            <Typography variant="h4">
              {editMode ? "Update Office" : "Add New Office"}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Grid container direction="column">
              <Form
                initialValues={editOfficeFormValues}
                onSubmit={(values: CreateOfficeFormValues) => {
                  submitOffice(values);
                }}
                mutators={{
                  setAccounts: (args, state, utils) => {
                    utils.changeValue(state, "accounts", () => args[0]);
                  },
                }}
                validate={validate}
                render={({ handleSubmit, form, values }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        direction="column"
                        spacing={1}
                        pl={1}
                        pt={1}
                      >
                        {showOfficeNameExistsMsg ? (
                          <Grid>
                            <Alert severity="error" sx={{ width: "100%" }}>
                              Office Name is already in use.
                            </Alert>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Grid container>
                          <Grid size={{ xs: 6 }}>
                            <Typography variant="h6" fontWeight={600} mb={1}>
                              Office
                            </Typography>
                            <TextField
                              id="name"
                              value={values.name}
                              label="Office Name"
                              required={true}
                              variant="outlined"
                              name="name"
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid size={{ xs: 6 }}>
                            <Typography variant="h6" fontWeight={600} mb={1}>
                              Accounts
                            </Typography>
                            <ManageOfficeAccounts
                              accounts={accounts}
                              setAccounts={setAccounts}
                              listUpdated={(accounts: AccountDto[]) => {
                                console.log("accounts list updated", accounts);
                                form.mutators.setAccounts(accounts);
                              }}
                            ></ManageOfficeAccounts>
                          </Grid>
                        </Grid>

                        <Grid container justifyContent="flex-end" mt={5} pr={2}>
                          <Button
                            disabled={form.getState().invalid}
                            type="submit"
                            variant="contained"
                          >
                            {editMode ? "Update Office" : "Create Office"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AddNewOffice;
