import React, { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AccountDto, OfficeDto } from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";

interface AccountTableProps {
  accountList: AccountDto[];
}

const AccountTable = (props: AccountTableProps) => {
  const { accountList } = props;

  const columns: GridColDef[] = [
    { field: "name", headerName: "Account", flex: 1.1 },
    {
      field: "office",
      headerName: "Office",
      flex: 1.1,
      valueGetter: (value, row) => {
        const accountNames = row.offices?.map(
          (office: OfficeDto) => office.name
        );
        return accountNames?.[0] ?? "";
      },
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={accountList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight={true}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default AccountTable;
