import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { UserDto } from "api/PricingPlatformApi.generated";
import AddNewUserDrawer from "components/pages/user-management/AddNewUserDrawer";
import Edit from "icons/Edit";
import React, { useState } from "react";
import AddNewPublicAPIUserDrawer from "./AddNewPublicAPIUser";

const UserTable = ({ userInfo, userUpdated }: any) => {
  const [userDrawerOpen, setUserDrawerOpen] = React.useState(false);
  const [publicAPIDrawerOpen, setPublicAPIDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserDto | null>(null);

  const toggleUserDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setUserDrawerOpen(open);
    };

  const toggleAPIUserDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setPublicAPIDrawerOpen(open);
    };
  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", flex: 1.1 },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.75,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.75,
    },
    {
      field: "userRole",
      headerName: "Role",
      flex: 0.75,
    },
    {
      field: "officeName",
      headerName: "Office",
      flex: 0.75,
      valueGetter: (value, row) => row?.office?.name,
    },
    {
      field: "team",
      headerName: "Team",
      flex: 0.75,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <Edit
            sx={{ cursor: "pointer" }}
            onClick={(e: any) => {
              if (params.row.apiKey) {
                setSelectedUser(params.row);
                setPublicAPIDrawerOpen(true);
              } else {
                setSelectedUser(params.row);
                setUserDrawerOpen(true);
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={userInfo}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight={true}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
      />
      <AddNewUserDrawer
        newUserAdded={(newUser: UserDto) => {
          userUpdated(newUser);
          setUserDrawerOpen(false);
        }}
        user={selectedUser}
        setState={setUserDrawerOpen}
        state={userDrawerOpen}
        toggleDrawer={toggleUserDrawer}
      />
      <AddNewPublicAPIUserDrawer
        newUserAdded={(newUser: UserDto) => {
          userUpdated(newUser);
          setPublicAPIDrawerOpen(false);
        }}
        user={selectedUser}
        setState={setPublicAPIDrawerOpen}
        state={publicAPIDrawerOpen}
        toggleDrawer={toggleAPIUserDrawer}
      />
    </Box>
  );
};

export default UserTable;
