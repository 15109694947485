import { SvgIcon } from "@mui/material";

const MetaforaQuoteIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <g clipPath="url(#clip0_246_275)">
          <path
            d="M24.8501 18L14.8501 23V19L18.8501 18L14.8501 17V13L24.8501 18ZM21.8501 14.26L19.8501 13.26V5H5.8501V18.26L6.8501 17.6L9.8501 19.6L12.8501 17.6V20L9.8501 22L6.8501 20L3.8501 22V3H21.8501V14.26ZM17.8501 7V9H7.8501V7H17.8501ZM12.8501 13V11H7.8501V13H12.8501Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_246_275">
            <rect
              width="24"
              height="24"
              fill="currentColor"
              transform="translate(0.850098)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default MetaforaQuoteIcon;
