import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SimilarQuotesTable from "./SimilarQuotesTable";
import {
  QuoteHistoryDto,
  QuoteResponseDto,
  RequestQuoteOptionsDto,
  SimilarQuoteRequestDto,
} from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface SimilarQuotesParentProps {
  readonly quote: QuoteResponseDto;
}

const SimilarQuotes = (props: SimilarQuotesParentProps) => {
  const { quoteApi } = useApiContext();
  const [similarQuotes, setSimilarQuotes] = useState<QuoteHistoryDto[]>([]);
  const [regionFilter, setRegionFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("All");

  const statusFilterOptions = ["All", "Won", "Pending", "Lost"];

  const optionsData = useSelector<any, RequestQuoteOptionsDto>(
    (state: any) => state.userRequestReducer.options
  );

  useEffect(() => {
    const request = SimilarQuoteRequestDto.fromJS({
      //quote: props.quote,
      hashedQuoteId: props.quote.hashedId,
      regionZoneFilter: Number(regionFilter),
      statusFilter: statusFilter,
    });
    console.log("similar quote req", request);
    quoteApi.similarQuotes(request).then((result) => {
      setSimilarQuotes(result);
    });
  }, [props, quoteApi, regionFilter, statusFilter]);

  return (
    <Paper
      sx={{
        padding: "20px 40px 12px 45px",
        width: "100%",
        marginTop: "10px",
        minHeight: "calc(45vh - 120px)",
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid size={{ xs: 12 }} mb={2}>
          <Typography variant="h4">Similar Quotes</Typography>
          <Grid container mt={2}>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel>Region</InputLabel>
              <Select
                name="region"
                id="region-filter"
                labelId="region-helper-label"
                value={regionFilter}
                label="Region"
                variant="outlined"
                onChange={(e) => setRegionFilter(e.target.value)}
                sx={{ height: "2.985em" }}
                displayEmpty
                fullWidth
              >
                {(optionsData.regionFilterOptions ?? []).map((e) => (
                  <MenuItem key={`region_${e.value}`} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                id="status-filter"
                labelId="status-helper-label"
                value={statusFilter}
                label="Status"
                variant="outlined"
                onChange={(e) => setStatusFilter(e.target.value)}
                sx={{ height: "2.985em", ml: 1 }}
                displayEmpty
                fullWidth
              >
                {statusFilterOptions.map((e) => (
                  <MenuItem key={`status_${e}`} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <SimilarQuotesTable data={similarQuotes} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default SimilarQuotes;
