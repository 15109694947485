import Box from "@mui/material/Box";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserMenu from "./UserMenu";
import AppBar from "@mui/material/AppBar";
import { Button, Container, useTheme } from "@mui/material";

import ViewListIcon from "@mui/icons-material/ViewList";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";
import { UserDto } from "api/PricingPlatformApi.generated";
import { NavLink } from "react-router-dom";
import MetaforaHistoryIcon from "icons/MetaforaHistoryIcon";
import MetaforaQuoteIcon from "icons/MetaforaQuoteIcon";
import MetaforaSettingsIcon from "icons/MetaforaSettingsIcon";
import { OrgAdminRoles, SettingsAccessRoles } from "constants/RoleConstant";
import { useSelector } from "react-redux";
import NavBreadcrumbs from "./NavBreadcrumbs";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const PricingAppBar = (props: AppBarProps) => {
  const navLinks = [
    {
      key: "history",
      label: "History",
      to: "/quote/history",
      icon: (
        <MetaforaHistoryIcon
          sx={{ mr: 1, color: "white", height: 24, width: 24 }}
        />
      ),
      visible: (user: UserDto) => true,
    },
    {
      key: "quote",
      label: "Quote",
      to: "/quote/request",
      icon: (
        <MetaforaQuoteIcon
          sx={{ mr: 1, color: "white", height: 24, width: 24 }}
        />
      ),
      visible: (user: UserDto) => true,
    },
    {
      key: "settings",
      label: "Settings",
      to: "/settings/pricing",
      icon: <MetaforaSettingsIcon sx={{ mr: 1, color: "white" }} />,
      visible: (user: UserDto) =>
        SettingsAccessRoles.includes(user.userRole as any),
    },
  ];

  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, p: 0 }}
      elevation={0}
    >
      <Container sx={{ p: 0, maxWidth: "unset !important", width: "100%" }}>
        <Toolbar disableGutters>
          <Button variant="text">
            <Box
              component="img"
              sx={{
                height: 27,
                width: 86.85,
                mr: 2,
              }}
              alt="Metafora"
              src={require("../../../assets/Metafora_Logo_AppBar.png")}
            />
          </Button>

          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {navLinks.map((l) =>
              l.visible(userData) ? (
                <NavLink
                  key={`nav-${l.key}`}
                  to={l.to}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button sx={{ my: 1, display: "flex" }}>
                    {l.icon}
                    <Typography variant="navLinkText">{l.label}</Typography>
                  </Button>
                </NavLink>
              ) : null
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
      <NavBreadcrumbs />
    </AppBar>
  );
};

export default PricingAppBar;
