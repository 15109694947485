import { Alert, Paper, Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { setIn } from "final-form";
import { Autocomplete, TextField } from "mui-rff";
import React from "react";
import { Form } from "react-final-form";
import {
  AccountDto,
  AddAccountRequestDto,
  OfficeDto,
  UpdateAccountRequestDto,
} from "../../../api/PricingPlatformApi.generated";
import Close from "../../../icons/Close";
import {} from "../../../utilities/form-initial-values/AddUserInitialValues";
import { useApiContext } from "contexts/ApiProvider";
import * as yup from "yup";

interface CreateAccountFormValues {
  name: string;
  id: number | null;
  officeId: number | null;
}

const InitialCreateAccountFormValues: CreateAccountFormValues = {
  name: "",
  id: null,
  officeId: null,
};

interface AddNewAccountDrawerProps {
  state: boolean;
  setState: any;
  newAccountAdded?: any;
  toggleDrawer: any;
  account?: AccountDto | null;
  existingAccounts: AccountDto[];
  existingOffices: OfficeDto[];
}

const AccountDrawer = (props: AddNewAccountDrawerProps) => {
  const {
    state,
    setState,
    newAccountAdded,
    toggleDrawer,
    account,
    existingAccounts,
    existingOffices,
  } = props;

  const editMode = account !== null && account !== undefined;

  const editAccountFormValues: CreateAccountFormValues = {
    name: account?.name ?? "",
    id: account?.id ?? null,
    officeId:
      !!account && Array.isArray(account?.offices) && account.offices.length > 0
        ? (account?.offices[0].id ?? null)
        : null,
  };
  const [showToast, setShowToast] = React.useState(false);
  const [showEmailExistsMsg] = React.useState(false);

  const { accountApi } = useApiContext();

  const submitAccount = async (values: CreateAccountFormValues) => {
    if (editMode) {
      callUpdateAccountApi(values);
    } else {
      callCreateAccountApi(values);
    }
  };
  const callCreateAccountApi = async (values: CreateAccountFormValues) => {
    const accountDto = AddAccountRequestDto.fromJS(values);

    if (values.id) console.log("create acct", values, accountDto);

    accountApi
      .createAccount(accountDto)
      .then((response) => {
        setShowToast(true);
        newAccountAdded(response);
        setState(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const callUpdateAccountApi = async (values: CreateAccountFormValues) => {
    const accountDto = UpdateAccountRequestDto.fromJS(values);

    accountApi
      .updateAccount(accountDto)
      .then(() => {
        setShowToast(true);
        setState(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TestValidationSchema = yup.object().shape({
    name: yup
      .string()
      .test({
        message: () => "Account name must be unique",
        test(value) {
          const val = value?.toLowerCase().trim();
          const accountNames = existingAccounts.map((o) =>
            o.name?.toLowerCase()
          );
          return accountNames.indexOf(val) === -1;
        },
      })
      .required("Required")
      .max(100, "Account Name must be less than 100 characters."),
  });

  const validateFormValues =
    (schema: any) => async (values: CreateAccountFormValues) => {
      if (typeof schema === "function") {
        schema = schema();
      }
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (err: any) {
        const errors = err.inner.reduce((formError: any, innerError: any) => {
          return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
      }
    };
  const validate = validateFormValues(TestValidationSchema);

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor={"right"}
          open={state}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: "#DAE8EE",
            },
          }}
        >
          <Grid container mt={9} direction="column">
            <Grid container justifyContent="flex-end">
              <Button onClick={toggleDrawer(false)} color="secondary">
                <Close />
              </Button>
            </Grid>
            <Grid ml={2}>
              <Typography color="secondary" variant="h6">
                {editMode ? "Update Account" : "Add New Account"}
              </Typography>
            </Grid>
            <Grid>
              <Paper
                elevation={10}
                sx={{
                  margin: "10px 10px 10px 10px",
                  width: "350px",
                  minHeight: "calc(100vh - 160px)",
                }}
              >
                <Grid container direction="column">
                  <Form
                    initialValues={
                      editMode
                        ? editAccountFormValues
                        : InitialCreateAccountFormValues
                    }
                    onSubmit={(values: CreateAccountFormValues) => {
                      submitAccount(values);
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, values }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            pl={1}
                            pt={1}
                          >
                            {showEmailExistsMsg ? (
                              <Grid>
                                <Alert severity="error" sx={{ width: "100%" }}>
                                  Email is already in use.
                                </Alert>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid>
                              <Typography variant="h6" mb={1}>
                                Name{" "}
                              </Typography>
                              <TextField
                                id="name"
                                value={values.name}
                                label="Account"
                                required={true}
                                variant="outlined"
                                disabled={editMode}
                                name="name"
                                sx={{ width: "75%" }}
                              />
                            </Grid>

                            {
                              <Grid>
                                <Typography variant="h6" mb={1}>
                                  Office{" "}
                                </Typography>

                                <Autocomplete
                                  id="officeId"
                                  options={existingOffices}
                                  freeSolo={false}
                                  getOptionLabel={(option) => "" + option.name}
                                  name="officeId"
                                  value={account?.offices}
                                  sx={{ width: "75%" }}
                                  getOptionValue={(option) => option.id}
                                  isOptionEqualToValue={(option, value) => {
                                    if (value.id === undefined) return true;
                                    return option.id === value.id;
                                  }}
                                />
                              </Grid>
                            }

                            <Grid
                              container
                              justifyContent="flex-end"
                              mt={10}
                              pr={2}
                            >
                              <Button
                                disabled={form.getState().invalid}
                                type="submit"
                                variant="contained"
                              >
                                {editMode ? "Update Account" : "Create Account"}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </>
                    )}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Drawer>
        <Snackbar
          open={showToast}
          autoHideDuration={4000}
          onClose={() => setShowToast(false)}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {editMode
              ? "Account updated successfully"
              : "Account created successfully"}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </div>
  );
};

export default AccountDrawer;
