import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Form } from "react-final-form";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GreenScreensDateRangeTypes,
  GreenScreensRegionTypes,
} from "constants/LoadHistoryConstant";
import { HistoricalLoadForm } from "utilities/form-initial-values/HistoricalLoadInitialValues";

export interface HistoricalLoadSearchProps {
  readonly searchHistoricalLoads: (form: HistoricalLoadForm) => Promise<void>;
}

const HistoricalLoadSearch = (props: HistoricalLoadSearchProps) => {
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("");
  const [regionType, setRegionType] = useState("");

  return (
    <Form
      onSubmit={async () => {
        const formValues = {
          dateRangeType,
          regionType,
        };
        // console.log("form values", formValues);
        setIsLoading(true);
        try {
          const res: any = await props.searchHistoricalLoads(
            formValues as HistoricalLoadForm,
          );
          setIsLoading(false);
          return res;
        } catch (err: any) {
          setErrorMessage(err);
          setShowToast(true);
        }
        return;
      }}
      render={({ form, values }) => (
        <React.Fragment>
          <Grid container style={{ minHeight: "10vh", color: "black" }}>
            {/* Container 1,2,3 parent */}
            <Grid container direction="row">
              <Grid item container spacing={2} paddingTop={5}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Pickup Date Range</InputLabel>
                    <Select
                      name="pickupDateRange"
                      labelId="pickupDateRange-helper-label"
                      id="pickupDateRange"
                      value={values.dateRangeType}
                      label="Pickup Date Range"
                      defaultValue={""}
                      onChange={(e) => setDateRangeType(e.target.value)}
                      variant="outlined"
                      sx={{ height: "2.985em" }}
                      displayEmpty
                      fullWidth
                    >
                      {(Object.entries(GreenScreensDateRangeTypes) ?? []).map(
                        (e) => (
                          <MenuItem key={e[1]} value={e[1]}>
                            {e[1]}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Region</InputLabel>
                    <Select
                      name="region"
                      id="region"
                      labelId="region-helper-label"
                      value={values.regionType}
                      label="Region"
                      variant="outlined"
                      defaultValue={""}
                      onChange={(e) => setRegionType(e.target.value)}
                      sx={{ height: "2.985em" }}
                      displayEmpty
                      fullWidth
                    >
                      {(Object.entries(GreenScreensRegionTypes) ?? []).map(
                        (e) => (
                          <MenuItem key={e[1]} value={e[1]}>
                            {e[1]}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={form.submit}
                    disabled={isLoading}
                    endIcon={<SearchIcon />}
                    sx={{ height: "3.35em" }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : "Search"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Snackbar
              open={showToast}
              autoHideDuration={15000}
              onClose={() => {
                setShowToast(false);
                setErrorMessage("");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {errorMessage}
              </Alert>
            </Snackbar>
          </Grid>
        </React.Fragment>
      )}
    />
  );
};

export default HistoricalLoadSearch;
