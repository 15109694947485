import { useState } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Box,
  Stack,
  Typography,
  Switch,
  Button,
  Tooltip,
  TextField,
  Autocomplete,
  Chip,
  InputAdornment,
  IconButton,
  styled,
  Skeleton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MarkupConditionDto,
  MarkupDto,
  PricingUpdate,
  QuoteResponseDto,
  RequestQuoteOptionsDto,
} from "api/PricingPlatformApi.generated";
import ThemeColors from "components/global/theme/theme_colors";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import ArrowDownIcon from "icons/svgs/ArrrowDownIcon";
import PlusIcon from "icons/svgs/PlusIcon";
import { useApiContext } from "contexts/ApiProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { saveQuote } from "redux/slices/Quote/quoteSlice";
import {
  CancelRounded,
  ExpandLess,
  ExpandMore,
  RemoveCircle,
} from "@mui/icons-material";
import ArrowUpIcon from "icons/svgs/ArrrowUpIcon";
import { useDisclosure } from "@mantine/hooks";
import { Collapse, SemiCircleProgress } from "@mantine/core";
import React from "react";
import { isNumeric } from "utilities/functions/FormatNumber";
import MarginAmountIcon from "icons/svgs/MarginAmountIcon";
import MarginPercentIcon from "icons/svgs/MarginPercentIcon";
import RatePerMileIcon from "icons/svgs/RatePerMileIcon";
import DollarIcon from "icons/svgs/DollarIcon";
import MileageIcon from "icons/svgs/MileageIcon";
import FuelIcon from "icons/svgs/FuelIcon";
import GreenScreensLogo from "icons/GreenScreensLogo";
import {
  confidenceColor,
  confidenceColorBg,
} from "utilities/functions/ConfidenceLevelColor";
import {
  hideNotification,
  showNotification,
} from "redux/slices/NotificationMessage/notificationMessageSlice";

const NumericTextField = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const PricingBreakdown = () => {
  const { quoteApi } = useApiContext();
  const ldFlags = useFlags();
  const dispatch = useDispatch();

  const adjustmentAmountRef = React.useRef<HTMLInputElement>(null);
  const overrideTotalRef = React.useRef<HTMLInputElement>(null);

  const [newMarkupPercent, setNewMarkupPercent] = useState<string>("");
  const [newMarkupAmount, setNewMarkupAmount] = useState<string>("");
  const [newTotalAmount, setNewTotalAmount] = useState<string>("");
  const [selectedMarkupReasons, setSelectedMarkupReasons] = useState<string[]>(
    []
  );

  const [openedAdjustmentsPanel, { toggle: toggleAdjustmentsPanel }] =
    useDisclosure(true);
  const [openedBuyRatePanel, { toggle: toggleBuyRatePanel }] =
    useDisclosure(true);
  const [openedTotalPanel, totalPanelHandlers] = useDisclosure(true);

  const onChangePercent = (value: string) => {
    if (!isNaN(+value)) {
      setNewMarkupPercent(value);

      setNewMarkupAmount(
        ((quoteData.quotePricing?.targetBuyRate! * +value) / 100).toFixed(2)
      );
    }
  };

  const onChangeAmount = (value: string) => {
    if (!isNaN(+value)) {
      setNewMarkupAmount(value);

      setNewMarkupPercent(
        (
          (100 * +value) /
          (quoteData.quotePricing?.targetBuyRate! || 1)
        ).toFixed(2)
      );
    }
  };

  const onChangeTotal = (value: string) => {
    if (!isNaN(+value)) {
      setNewTotalAmount(value);

      const newMarkupAmount = (
        +value - quoteData.quotePricing?.targetSellRate!
      ).toFixed(2);

      setNewMarkupAmount(newMarkupAmount);

      setNewMarkupPercent(
        (
          (100 * +newMarkupAmount) /
          (quoteData.quotePricing?.targetBuyRate! || 1)
        ).toFixed(2)
      );
    }
  };

  const [addingMarkup, setAddingMarkup] = useState<boolean>(false);
  const [overridingTotal, setOverridingTotal] = useState<boolean>(false);

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  const quoteOptions = useSelector<any, RequestQuoteOptionsDto>(
    (state: any) => state.userRequestReducer.options
  );

  const handleToggle = (index: number) => {
    if (quoteData.markups === undefined) {
      return;
    }

    const markup = quoteData.markups[index];
    markup.enabled = !markup.enabled;

    const request = new PricingUpdate({
      quoteId: quoteData.id,
      markups: quoteData.markups,
    });

    quoteApi
      .changePricing(request)
      .then((response) => {
        console.log("pricing updated:", response.quotePricing);
        totalPanelHandlers.open();

        dispatch(saveQuote(response));
        showPricingUpdateNotification(response);
      })
      .catch((error) => {
        dispatch(
          showNotification({
            message: `Error: ${error}`,
            delayMs: null,
            type: "error",
          })
        );
      });
  };

  function showPricingUpdateNotification(response: QuoteResponseDto) {
    dispatch(
      showNotification({
        message: `Pricing Updated, new total is: ${formatCurrency(response.quotePricing?.targetSellRate)}`,
        delayMs: 2000,
        type: "success",
      })
    );
  }

  const handleSaveCustomMarkup = (type: string) => {
    if (!isNumeric(newMarkupAmount)) {
      return;
    }

    const markup = new MarkupDto({
      enabled: true,
      amount: +(newMarkupAmount ?? 0),
      markupPercent: +(newMarkupPercent ?? 0),
      isCustom: true,
      markupValue: 0,
      type: type,
      conditions: selectedMarkupReasons.map(
        (r) =>
          new MarkupConditionDto({
            fieldName: "Custom",
            value: r,
          })
      ),
    });

    const request = new PricingUpdate({
      quoteId: quoteData.id,
      markups: [...(quoteData.markups ?? []), markup],
    });

    quoteApi.changePricing(request).then((response) => {
      console.log("pricing updated:", response.quotePricing);

      totalPanelHandlers.open();
      dispatch(saveQuote(response));
      showPricingUpdateNotification(response);
      clearCustomMarkup();
    });
  };

  const handleSaveCustomTotal = () => {
    console.log(
      "saving total: {markup, markup%, total}",
      newMarkupAmount,
      newMarkupPercent,
      newTotalAmount
    );
    handleSaveCustomMarkup("flat");
  };

  const handleAddMarkupClick = () => {
    clearCustomMarkup();
    setOverridingTotal(false);

    setAddingMarkup(!addingMarkup);

    //if (addingMarkup) {
    setTimeout(() => {
      adjustmentAmountRef.current?.focus();
    }, 100);
    //}
  };

  const handleRemoveMarkup = (index: number) => {
    if (quoteData.markups === undefined) {
      return;
    }

    const markup = quoteData.markups[index];
    markup.isDeleted = true;

    const request = new PricingUpdate({
      quoteId: quoteData.id,
      markups: quoteData.markups,
    });

    quoteApi.changePricing(request).then((response) => {
      totalPanelHandlers.open();
      dispatch(saveQuote(response));
      showPricingUpdateNotification(response);
    });
  };

  const clearCustomMarkup = () => {
    setNewMarkupPercent("");
    setNewMarkupAmount("");
    setNewTotalAmount("");
    setSelectedMarkupReasons([]);
    setAddingMarkup(false);
    setOverridingTotal(false);
  };

  const handleOverrideTotalClick = () => {
    clearCustomMarkup();
    setAddingMarkup(false);

    setSelectedMarkupReasons(["Negotiation"]);
    //setNewMarkupAmount(quoteData.quotePricing?.targetSellRate);

    setOverridingTotal(!overridingTotal);
    //if (overridingTotal) {
    setTimeout(() => {
      overrideTotalRef.current?.focus();
    }, 100);
    //}
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: "8px",
        border: "1px solid var(--Color-Primary-Default, #396A6A)",
        background: "var(--Color-Neutral-White, #FFF)",
        boxShadow: "0px 0px 6px 0px rgba(176, 195, 195, 0.25)",
        display: "flex",
        width: "100%",
      }}
    >
      <Box sx={{ m: 2, width: "100%" }}>
        <Typography variant="cardTitle" sx={{ mt: 1, mb: 1 }} component="div">
          Pricing Breakdown
        </Typography>
        <Paper
          variant="outlined"
          sx={{
            borderColor: ThemeColors.metaforaGreen,
            overflow: "hidden",
          }}
        >
          <Stack>
            <Paper
              variant="outlined"
              square={true}
              sx={{
                bgcolor: ThemeColors.primaryBackground100,
                borderLeft: 0,
                borderRight: 0,
                padding: "12px 16px",
              }}
            >
              <Grid container gap={"16px"} sx={{ alignItems: "center" }}>
                <Grid sx={{ flex: 1 }}>
                  <Typography variant="gridTextLarge">
                    Expected Buy Rate
                  </Typography>
                </Grid>

                {/* Confidence */}
                <Grid
                  size={{}}
                  sx={{
                    px: "8px",
                    alignItems: "center",
                    py: "-8px",
                    mt: "-6px",
                    mr: "-13px",
                  }}
                  container
                  flexDirection="column"
                  gap={0}
                  spacing={0}
                >
                  <Typography
                    variant="cardTextLargeHeader"
                    sx={{
                      fontSize: "10px",
                      mb: "-8px",
                    }}
                  >
                    Confidence
                  </Typography>
                  <SemiCircleProgress
                    fillDirection="left-to-right"
                    orientation="up"
                    filledSegmentColor={confidenceColor(
                      quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                    )}
                    emptySegmentColor={confidenceColorBg(
                      quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                    )}
                    size={60}
                    thickness={10}
                    value={
                      quoteData.projectedCost?.companyRate?.confidenceLevel ?? 0
                    }
                    label={
                      quoteData.projectedCost?.companyRate?.confidenceLevel ??
                      "-"
                    }
                    styles={{
                      label: {
                        color: "#595856",
                        fontSize: "10px",
                        fontWeight: 300,
                        fontFamily: "Poppins",
                        bottom: "-4px",
                      },
                    }}
                  />
                </Grid>

                <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                  <Typography variant="gridTextBold">
                    {!quoteData.quotePricing && (
                      <Skeleton width={90} sx={{ justifySelf: "end" }} />
                    )}
                    {quoteData.quotePricing &&
                      formatCurrency(quoteData.quotePricing.targetBuyRate)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{ flex: "0 0 50px", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={toggleBuyRatePanel}
                    sx={{ padding: 0, mr: "8px" }}
                    disabled={!quoteData?.quotePricing}
                  >
                    {openedBuyRatePanel && <ExpandLess />}
                    {!openedBuyRatePanel && <ExpandMore />}
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <Collapse in={openedBuyRatePanel}>
              <Grid container>
                <Grid size={{ md: 12 }} sx={{ p: "11px" }} container>
                  <Typography variant="cardTextLargeHeader" sx={{ flex: 1 }}>
                    Internal Company Buy Rate predicted via
                  </Typography>
                  <Grid sx={{ mx: "5px", height: "36px" }}>
                    <GreenScreensLogo />
                  </Grid>
                </Grid>
                <Grid size={{ md: 12 }} container sx={{}}>
                  {/* Low */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDownIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">Low</Typography>
                      {!quoteData.projectedCost && <Skeleton width={75} />}
                      {quoteData && (
                        <Typography variant="cardText">
                          {formatCurrency(
                            quoteData.projectedCost?.companyRate?.lowBuyRate
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* High */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <ArrowUpIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">High</Typography>
                      {!quoteData.projectedCost && <Skeleton width={75} />}
                      {quoteData && (
                        <Typography variant="cardText">
                          {formatCurrency(
                            quoteData.projectedCost?.companyRate?.highBuyRate
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* Fuel */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <FuelIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">Fuel</Typography>
                      {!quoteData.projectedCost && <Skeleton width={75} />}
                      {quoteData && (
                        <Typography variant="cardText">
                          {formatCurrency(
                            quoteData.projectedCost?.companyRate?.fuelRate
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* Mileage */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <MileageIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">Mileage</Typography>
                      {!quoteData.projectedCost && <Skeleton width={75} />}
                      {quoteData && (
                        <Typography variant="cardText">
                          {quoteData.projectedCost?.companyRate?.mileage?.toFixed(
                            0
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
            <Paper
              variant="outlined"
              square={true}
              sx={{
                bgcolor: ThemeColors.primaryBackground100,
                borderLeft: 0,
                borderRight: 0,
                padding: "12px 16px",
              }}
            >
              <Grid container gap={"16px"}>
                <Grid sx={{ flex: 1 }}>
                  <Typography variant="gridTextLarge">Adjustments</Typography>
                </Grid>
                <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
                  <Typography variant="gridTextBold">
                    {!quoteData.quotePricing && (
                      <Skeleton width={60} sx={{ justifySelf: "end" }} />
                    )}
                    {quoteData.quotePricing &&
                      `${quoteData.quotePricing.markupPercentTotal?.toFixed(
                        2
                      )}%`}
                  </Typography>
                </Grid>
                <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                  <Typography variant="gridTextBold">
                    {!quoteData.quotePricing && (
                      <Skeleton width={60} sx={{ justifySelf: "end" }} />
                    )}
                    {quoteData.quotePricing &&
                      formatCurrency(quoteData.quotePricing.markupAmountTotal)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{ flex: "0 0 50px", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={toggleAdjustmentsPanel}
                    sx={{ padding: 0, mr: "8px" }}
                    disabled={!quoteData?.quotePricing}
                  >
                    {openedAdjustmentsPanel && <ExpandLess />}
                    {!openedAdjustmentsPanel && <ExpandMore />}
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
            <Collapse in={openedAdjustmentsPanel}>
              {!quoteData.markups &&
                [1, 2, 3].map((markup, markupIndex) => {
                  return (
                    <Grid
                      container
                      key={`markup_${markupIndex}`}
                      gap={"16px"}
                      sx={{
                        padding: "12px 16px",
                        borderBottom: "1px solid #E6E9F5",
                        alignItems: "center",
                      }}
                    >
                      <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                        <Skeleton width={75} />
                      </Grid>
                      <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                        <Skeleton width={100} />
                      </Grid>

                      <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
                        <Skeleton width={50} sx={{ justifySelf: "end" }} />
                      </Grid>
                      <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                        <Skeleton width={60} sx={{ justifySelf: "end" }} />
                      </Grid>
                      <Grid
                        sx={{
                          flex: "0 0 50px",
                          width: "50px",
                          justifyContent: "end",
                        }}
                        container
                      >
                        <Switch
                          checked={true}
                          disabled={true}
                          style={{
                            textDecoration: "blue",
                            color: ThemeColors.textAction,
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              {quoteData.markups?.map((markup, markupIndex) => {
                return (
                  <Grid
                    container
                    key={`markup_${markupIndex}`}
                    gap={"16px"}
                    sx={{
                      padding: "12px 16px",
                      borderBottom: "1px solid #E6E9F5",
                      alignItems: "center",
                    }}
                  >
                    <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                      {markup.isCustom && (
                        <Typography variant="gridText">Custom</Typography>
                      )}
                      {!markup.isCustom &&
                        markup.conditions?.map((c, cIndex) => {
                          return (
                            <Typography
                              key={`condition_${cIndex}`}
                              variant="gridText"
                            >
                              {c.fieldName}
                            </Typography>
                          );
                        })}
                    </Grid>
                    <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                      {markup.conditions?.map((c, cIndex) => {
                        return (
                          <Typography
                            key={`conditionValue_${cIndex}`}
                            variant="gridText"
                          >
                            {c.value}
                          </Typography>
                        );
                      })}
                    </Grid>

                    <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
                      <Typography
                        variant="gridText"
                        sx={{
                          textDecoration: markup.enabled
                            ? "none"
                            : "line-through",
                        }}
                      >
                        {`${markup.markupPercent?.toFixed(2)}%`}
                      </Typography>
                    </Grid>
                    <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                      <Typography
                        variant="gridText"
                        sx={{
                          textDecoration: markup.enabled
                            ? "none"
                            : "line-through",
                        }}
                      >
                        {formatCurrency(markup.amount)}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        flex: "0 0 50px",
                        width: "50px",
                        justifyContent: "end",
                      }}
                      container
                    >
                      {markup.isCustom && (
                        <IconButton
                          onClick={() => {
                            handleRemoveMarkup(markupIndex);
                          }}
                        >
                          <RemoveCircle color="error"></RemoveCircle>
                        </IconButton>
                      )}
                      {!markup.isCustom && (
                        <Switch
                          checked={markup.enabled}
                          onChange={() => {
                            handleToggle(markupIndex);
                            markup.enabled = !markup.enabled;
                          }}
                          style={{
                            textDecoration: "blue",
                            color: markup.enabled
                              ? ThemeColors.textAction
                              : "#F5F5F5",
                            //backgroundColor: ThemeColors.textAction,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {ldFlags["quote-add-custom-markups"] && (
                <Grid
                  gap={"16px"}
                  container
                  sx={{
                    padding: "6px 16px",
                    borderBottom: "1px solid #E6E9F5",
                    alignItems: "center",
                  }}
                >
                  {addingMarkup ? (
                    <Grid
                      container
                      key={`markup_new`}
                      gap={"12px"}
                      sx={{ width: "100%", alignItems: "center" }}
                    >
                      <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                        <Autocomplete
                          multiple
                          freeSolo
                          fullWidth
                          options={quoteOptions.customMarkupReasons ?? []}
                          value={selectedMarkupReasons}
                          onChange={(event, newValue) => {
                            setSelectedMarkupReasons(newValue);
                          }}
                          defaultValue={[]}
                          renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => {
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  key={key}
                                  {...tagProps}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Reason"
                              placeholder="Reason"
                            />
                          )}
                        ></Autocomplete>
                      </Grid>
                      <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
                        <NumericTextField
                          placeholder="0.00"
                          size="small"
                          type="number"
                          value={newMarkupPercent}
                          onChange={(e) => onChangePercent(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSaveCustomMarkup("percent");
                            }
                          }}
                          slotProps={{
                            input: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            },
                          }}
                        ></NumericTextField>
                      </Grid>
                      <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                        <NumericTextField
                          placeholder="0.00"
                          size="small"
                          type="number"
                          inputRef={adjustmentAmountRef}
                          value={newMarkupAmount}
                          onChange={(e) => onChangeAmount(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Tab") {
                              handleSaveCustomMarkup("flat");
                            }
                          }}
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            },
                          }}
                        ></NumericTextField>
                      </Grid>
                      <Grid sx={{ flex: "0 0 50px", width: "50px" }}>
                        <IconButton onClick={handleAddMarkupClick}>
                          <CancelRounded />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{ justifyContent: "end" }}
                      size={{ xs: 12 }}
                    >
                      <Button
                        variant="text"
                        startIcon={<PlusIcon />}
                        onClick={handleAddMarkupClick}
                        disabled={!quoteData.quotePricing}
                      >
                        Adjustment
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Collapse>
            <Paper
              variant="outlined"
              square={true}
              sx={{
                bgcolor: ThemeColors.primaryBackground100,
                borderLeft: 0,
                borderRight: 0,
                mt: 0,
                padding: "12px 16px",
              }}
            >
              <Grid container sx={{ alignItems: "center", columnGap: "12px" }}>
                <Grid sx={{ flexGrow: overridingTotal ? 0 : 1 }}>
                  <Typography variant="gridTextLarge">Total</Typography>
                </Grid>
                {/* Up/Down arrow */}
                {!overridingTotal && (
                  <Grid sx={{ mr: "15px", cursor: "help" }}>
                    {quoteData.quotePricing?.targetSellRate! <
                      quoteData.quotePricing?.originalTargetSellRate! && (
                      <ArrowDownIcon
                        sx={{
                          color: ThemeColors.warning,
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    )}
                    {quoteData.quotePricing?.targetSellRate! >
                      quoteData.quotePricing?.originalTargetSellRate! && (
                      <ArrowUpIcon
                        sx={{
                          color: ThemeColors.warning,
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    )}
                  </Grid>
                )}

                {/* Total read-only */}
                {!overridingTotal && (
                  <Grid container flexDirection="column">
                    {!quoteData.quotePricing && (
                      <Skeleton width={140} height={40} variant="rounded" />
                    )}
                    {quoteData.quotePricing && (
                      <Tooltip
                        arrow
                        title="Click to override the total sell rate"
                      >
                        <Button
                          variant="text"
                          sx={{}}
                          onClick={handleOverrideTotalClick}
                        >
                          <Grid container flexDirection="column">
                            {quoteData.quotePricing && (
                              <Typography
                                variant="cardTextLarge"
                                sx={{
                                  color:
                                    quoteData.quotePricing?.targetSellRate! !==
                                    quoteData.quotePricing
                                      ?.originalTargetSellRate!
                                      ? ThemeColors.warning
                                      : ThemeColors.textBody,
                                }}
                              >
                                {formatCurrency(
                                  quoteData.quotePricing.targetSellRate
                                )}
                              </Typography>
                            )}
                            <Grid container>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  whiteSpace: "preserve",
                                }}
                              >
                                {"( "}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    quoteData.quotePricing?.targetSellRate! !==
                                    quoteData.quotePricing
                                      ?.originalTargetSellRate!
                                      ? ThemeColors.warning
                                      : ThemeColors.textBody,
                                  fontSize: "12px",
                                  fontWeight: "700",
                                }}
                              >
                                {quoteData.quotePricing != null
                                  ? formatCurrency(
                                      quoteData.quotePricing.targetRpm
                                    )
                                  : formatCurrency(
                                      quoteData.overriddenQuoteTargetSellRateDto
                                        ?.totalRpm ??
                                        quoteData.quoteTargetSellRateDto
                                          ?.totalRpm
                                    )}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  whiteSpace: "preserve",
                                }}
                              >
                                {" / "}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#858BA0",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  mx: "4px",
                                }}
                              >
                                mile
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#302E2C",
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  whiteSpace: "preserve",
                                }}
                              >
                                {" )"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                )}

                {/* Total reason */}
                {overridingTotal && (
                  <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                    <Autocomplete
                      multiple
                      freeSolo
                      fullWidth
                      options={quoteOptions.customMarkupReasons ?? []}
                      value={selectedMarkupReasons}
                      onChange={(event, newValue) => {
                        setSelectedMarkupReasons(newValue);
                      }}
                      defaultValue={[]}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => {
                          const { key, ...tagProps } = getTagProps({
                            index,
                          });
                          return (
                            <Chip
                              variant="outlined"
                              label={option}
                              key={key}
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Reason"
                          placeholder="Reason"
                        />
                      )}
                      sx={{ backgroundColor: "white" }}
                    ></Autocomplete>
                  </Grid>
                )}
                {/* Total amount */}
                {overridingTotal && (
                  <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                    <NumericTextField
                      placeholder={quoteData.quotePricing?.targetSellRate?.toFixed(
                        2
                      )}
                      label={formatCurrency(
                        quoteData.quotePricing?.targetSellRate
                      )}
                      size="small"
                      type="number"
                      value={newTotalAmount}
                      inputRef={overrideTotalRef}
                      onChange={(e) => onChangeTotal(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleSaveCustomTotal();
                        }
                      }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      sx={{ backgroundColor: "white" }}
                    ></NumericTextField>
                  </Grid>
                )}
                {/* Total Cancel */}
                {overridingTotal && (
                  <Grid sx={{ flex: "0 0 50px", width: "50px" }}>
                    <IconButton onClick={handleOverrideTotalClick}>
                      <CancelRounded />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Paper>

            {quoteData.quotePricing?.originalTargetSellRate !==
              quoteData.quotePricing?.targetSellRate && (
              <Grid
                container
                sx={{
                  background: ThemeColors.warning100,
                }}
              >
                <Grid
                  sx={{
                    flex: 1,
                    textAlign: "end",
                  }}
                >
                  <Typography variant="gridTextLarge">
                    The current total is
                  </Typography>
                  <Typography
                    variant="gridTextLarge"
                    sx={{ color: ThemeColors.warning, fontWeight: "700" }}
                  >
                    {" "}
                    {formatCurrency(
                      Math.abs(
                        quoteData.quotePricing?.targetSellRate! -
                          quoteData.quotePricing?.originalTargetSellRate!
                      )
                    )}
                    {quoteData.quotePricing?.targetSellRate! >
                    quoteData.quotePricing?.originalTargetSellRate!
                      ? " higher"
                      : " lower"}{" "}
                  </Typography>
                  <Typography variant="gridTextLarge">
                    than the Recommend Sell Rate
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    flex: "0 0 50px",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      totalPanelHandlers.toggle();
                    }}
                    sx={{ padding: 0, mr: "8px", color: ThemeColors.warning }}
                  >
                    {openedTotalPanel && <ExpandLess />}
                    {!openedTotalPanel && <ExpandMore />}
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {quoteData.quotePricing?.originalTargetSellRate !==
              quoteData.quotePricing?.targetSellRate && (
              <Collapse in={openedTotalPanel}>
                <Grid size={{ md: 12 }} container sx={{ pl: 1 }}>
                  <Grid
                    size={{ md: 12 }}
                    sx={{ mx: "5px", pl: "6px", pt: "6px", height: "36px" }}
                  >
                    <Typography
                      variant="cardText"
                      sx={{ color: ThemeColors.textDisabled }}
                    >
                      Recommended Pricing
                    </Typography>
                  </Grid>
                  {/* Sell Rate */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: "7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <DollarIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">
                        Sell Rate
                      </Typography>
                      <Typography variant="cardText">
                        {formatCurrency(
                          quoteData.quotePricing?.originalTargetSellRate
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* RPM */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <DollarIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">RPM</Typography>
                      <Typography variant="cardText">
                        {formatCurrency(
                          quoteData.quoteTargetSellRateDto?.totalRpm
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* % Margin */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <MarginPercentIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">% Margin</Typography>
                      <Typography variant="cardText">
                        {`${quoteData.quoteTargetSellRateDto?.totalMarginPercent?.toFixed(
                          2
                        )}%`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* $ Margin */}
                  <Grid
                    size={{ md: 3 }}
                    container
                    sx={{
                      padding: " 7px 10px",
                      alignItems: "center",
                    }}
                  >
                    <MarginAmountIcon
                      sx={{
                        color: ThemeColors.metaforaGreen,
                        width: "24px",
                        height: "24px",
                        mr: "6px",
                      }}
                    />
                    <Grid container flexDirection="column">
                      <Typography variant="cardTextHeader">$ Margin</Typography>
                      <Typography variant="cardText">
                        {formatCurrency(
                          quoteData.quoteTargetSellRateDto?.totalMarginAmount
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            )}
          </Stack>
        </Paper>
      </Box>
    </Paper>
  );
};

export default PricingBreakdown;
