import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserDto } from "api/PricingPlatformApi.generated";
import { useDispatch } from "react-redux";
import { useApiContext } from "./ApiProvider";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { saveUser } from "redux/slices/User/userSlice";
import { requestFailure } from "redux/slices/Shared/sharedSlice";

export type UserContextType = {
  currentUser: UserDto | null;
  userInitials: string;
  token: string | undefined;
  setCurrentUser: (user: UserDto) => void;
  createUserInitials: (user: UserDto) => void;
};

export const UserContext = createContext<UserContextType>({
  currentUser: null,
  setCurrentUser: (user: UserDto) => {},
  userInitials: "",
  createUserInitials: (user: UserDto) => {},
  token: undefined,
});

export const useUserContext = () => {
  const currentUserContext = useContext(UserContext);
  if (!currentUserContext) {
    throw new Error("Cannot obtain user context outside of UserProvider");
  }
  return currentUserContext;
};

const UserProviderContext = ({ children }: any) => {
  const [currentUser, setUser] = useState<UserDto | null>(null);
  const [userInitials, setUserInitials] = useState("");
  //okta
  const { oktaAuth, authState } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  //launch darkly
  const ldClient = useLDClient();
  //redux
  const dispatch = useDispatch();
  //api
  const { userApi } = useApiContext();

  const setCurrentUser = useCallback((user: UserDto) => {
    setUser(user);
    setUserInitials(createUserInitials(user));
  }, []);

  const createUserInitials = (user: UserDto) => {
    if (!user) {
      return "";
    }
    const initials = `${user?.firstName?.[0]}.${user.lastName?.[0]}.`;
    return initials;
  };

  const setLaunchDarklyUser = useCallback((profile: UserDto | null) => {
    try {
      if(profile){
        ldClient?.identify({
          kind: "multi",
          user: {
            kind: "user",
            key: profile?.email,
            email: profile?.email,
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            tenant: profile?.tenant?.name,
          },
          tenant: {
            kind: "tenant",
            key: profile?.tenant?.name,
          },
        });
      }
    } catch (error: Error | any){
      dispatch(requestFailure(error))
    }
  }, [ldClient, dispatch]);

  const fetchCurrentUser = useCallback(async() => {
        try {
          const profile = await userApi.getCurrentUser();
          const options = await userApi.getUserOptions();
          setCurrentUser(profile);
          setLaunchDarklyUser(profile);
          dispatch(saveUser({profile, options}));
        } catch (error: Error | any){
          dispatch(requestFailure(error))
        }
  }, [dispatch, setCurrentUser, setLaunchDarklyUser, userApi])

  useEffect(() =>{
    if(authState?.isAuthenticated){
    fetchCurrentUser();
    }
  }, [authState?.isAuthenticated, userApi, ldClient, fetchCurrentUser, token])

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        createUserInitials,
        userInitials,
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = UserProviderContext;
