import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { PublicQuoteDto, QuoteApi } from "api/PricingPlatformApi.generated";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import { formatDateV1 } from "utilities/functions/FormatDate";

const PublicQuote = () => {
  const { hashedId } = useParams();

  const [quote, setQuote] = useState<PublicQuoteDto | null>();

  useEffect(() => {
    const getQuoteDetails = async () => {
      const api = new QuoteApi();

      const quote = await api.getPublicQuoteDetails(hashedId);
      setQuote(quote);
    };
    getQuoteDetails();
  }, [hashedId]);

  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid item md={12} sx={{ m: 2 }}>
            <Typography>
              Thank you for requesting a quote from {quote?.companyName}. Please
              let us know if you would like to set up booking, or any additional
              feedback/questions you may have.
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card sx={{ m: 2 }} variant="outlined">
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5">Origin</Typography>
                    <Divider />
                    <Typography mt={2}>
                      {quote?.origin?.city}, {quote?.origin?.state}{" "}
                      {quote?.origin?.zipCode}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h5">Destination</Typography>
                    <Divider />
                    <Typography mt={2}>
                      {quote?.destination?.city}, {quote?.destination?.state}{" "}
                      {quote?.destination?.zipCode}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h5" mt={3}>
                      Pickup
                    </Typography>
                    <Divider />
                    <Typography mt={2}>
                      {quote?.pickupDate != null
                        ? formatDateV1(quote?.pickupDate)
                        : "Unkown"}
                    </Typography>
                    {quote?.estimatedDeliveryHour ? (
                      quote?.estimatedDeliveryHour.toString().padStart(2, "0") +
                      ":" +
                      (quote.estimatedDeliveryMinute
                        ? quote.estimatedDeliveryMinute
                            .toString()
                            .padStart(2, "0")
                        : "00")
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h5" mt={3}>
                      Delivery
                    </Typography>
                    <Divider />
                    <Typography mt={2}>
                      {quote?.deliveryDate != null
                        ? formatDateV1(quote?.deliveryDate)
                        : "Unkown"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <Divider>
                      <Chip label="Additional Details" size="small"></Chip>
                    </Divider>
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <Typography variant="h6">Equipment</Typography>
                    <Divider />
                    <Typography mt={1} mb={2}>
                      {quote?.equipment}
                    </Typography>

                    <Typography variant="h6">Commodity</Typography>
                    <Divider />
                    <Typography mt={1} mb={2}>
                      {quote?.commodity !== null ? quote?.commodity : "-"}
                    </Typography>

                    <Typography variant="h6" mt={1}>
                      Services
                    </Typography>
                    <Divider />
                    {(quote?.specializedServices?.length ?? 0) > 0
                      ? quote?.specializedServices?.map((s, index) => (
                          <Typography mt={index === 0 ? 1 : 0} mb={2}>
                            {s.label}
                          </Typography>
                        ))
                      : "-"}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12} md={6}>
            <Card sx={{ m: 2 }} variant="outlined">
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5">Rate</Typography>
                    <Divider />
                    <Typography mt={2}>
                      {formatCurrency(quote?.totalRate)}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h5">Status</Typography>
                    <Divider />
                    <Typography mt={2}>
                      {quote?.status === "Pending" ? "Open" : "Closed"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PublicQuote;
