import * as yup from "yup";

export const UpdateQuoteStatusValidationSchema = yup.object().shape({
  customerName: yup
    .string()
    .required("Required")
    .max(100, "Office Name must be less than 100 characters."),
  customerPhone: yup.string(),
  customerEmail: yup.string().email("Must be an email"),
  reasonLostId: yup.number(),
  reasonLostText: yup.string(),
});
