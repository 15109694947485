import { Button, Grid, Stack, Typography } from "@mui/material";
import { SettingDto } from "api/PricingPlatformApi.generated";
import SettingKeys from "components/pages/settings/Settings-Constant";
import { useApiContext } from "contexts/ApiProvider";
import Edit from "icons/Edit";
import { DateTime } from "luxon";
import { TimePicker } from "mui-rff";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";

interface BusinessHoursFormValues {
  businessHoursStartTime: Date;
  businessHoursEndTime: Date;
}

const BusinessHoursSetting = ({ canEdit }: { canEdit: boolean }) => {
  const [editMode, setEditMode] = useState(false);
  const [settings, setSettings] = useState<SettingDto[]>([]);

  const { settingsApi } = useApiContext();

  const INITIAL_BUS_HOURS = {
    businessHoursStartTime: new Date(),
    businessHoursEndTime: new Date(),
  };
  const [busHours, setBusHours] =
    useState<BusinessHoursFormValues>(INITIAL_BUS_HOURS);
  useEffect(() => {
    const getSettings = async () => {
      const data = await settingsApi.getBusinessHours();
      setSettings(data);
      let busHoursData: BusinessHoursFormValues = {
        businessHoursStartTime: findBusHoursSetting(
          data,
          SettingKeys.BUSINESS_START_TIME
        ),
        businessHoursEndTime: findBusHoursSetting(
          data,
          SettingKeys.BUSINESS_END_TIME
        ),
      };
      setBusHours(busHoursData);
    };

    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findBusHoursSetting = (
    data: SettingDto[],
    settingKey: string
  ): Date => {
    const setting = data.find((setting) => setting.settingKey === settingKey);
    return DateTime.fromFormat(setting?.settingValue ?? "00:00", "HH:mm")
      .toLocal()
      .toJSDate();
  };
  const handleSubmit = (values: BusinessHoursFormValues) => {
    settings.forEach((setting) => {
      if (setting.settingKey === SettingKeys.BUSINESS_START_TIME) {
        setting.settingValue = DateTime.fromJSDate(
          values.businessHoursStartTime
        ).toFormat("HH:mm");
      } else if (setting.settingKey === SettingKeys.BUSINESS_END_TIME) {
        setting.settingValue = DateTime.fromJSDate(
          values.businessHoursEndTime
        ).toFormat("HH:mm");
      }
    });

    const saveSetting = async () => {
      await settingsApi.updateSetting(settings);
      setEditMode(false);
    };
    saveSetting();
  };
  const handleCancel = () => {
    setEditMode(false);
  };
  return (
    <Grid item xs={12} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            onSubmit={(values: BusinessHoursFormValues) => {
              handleSubmit(values);
            }}
            initialValues={busHours}
            render={({ handleSubmit, values }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ alignItems: "center" }}
                  >
                    <Typography variant="body1">Business Hours</Typography>
                    <TimePicker
                      disabled={!editMode}
                      sx={{ width: 140 }}
                      value={values.businessHoursStartTime}
                      name="businessHoursStartTime"
                      ampm={false}
                    />
                    <Typography variant="body1">To</Typography>

                    <TimePicker
                      disabled={!editMode}
                      sx={{ width: 140 }}
                      value={values.businessHoursEndTime}
                      ampm={false}
                      name="businessHoursEndTime"
                    />
                    {canEdit ? (
                      editMode === false ? (
                        <Edit
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditMode(true);
                          }}
                        />
                      ) : (
                        <>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </>
                      )
                    ) : null}
                  </Stack>
                </form>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Business Hours are used to calculate Lead Time for each quote.
            Business hours are set in local time, Monday through Friday, and
            only business hours will count towards the total lead time.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessHoursSetting;
