import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loading: React.FC = () => {
  return (
      <CircularProgress
        color="info"
        size={80}
        thickness={5}
        sx={{           
          position: "fixed",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          top: "50%",
          zIndex: 99999,
        }}
      />
  );
};

export default Loading;
