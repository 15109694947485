import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ExpandLess from "../../../../icons/ExpandLess";
import ExpandMore from "../../../../icons/ExpandMore";
import BatchQuoteUploadTemplate from "./BatchQuoteUploadTemplate";
import UploadQuoteBatchFile from "./BatchQuoteUploadFile";
import BatchQuoteAdditionalDetails from "./BatchQuoteAdditionalDetails";
import { BatchQuoteHistoryDto } from "api/PricingPlatformApi.generated";

interface UploadQuoteBatchFileProps {
  onBatchCreated: (batchData: BatchQuoteHistoryDto) => void;
}

const BatchQuoteToolBar: React.FC<UploadQuoteBatchFileProps> = ({
  onBatchCreated,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandChange = () => {
    setExpanded(!expanded);
  };
  const handleBatchCreated = (responseData: BatchQuoteHistoryDto) => {
    onBatchCreated(responseData);
  };
  return (
    <Box
      sx={{
        borderBottom: "2px solid #ccc ",
        borderRight: ".5px solid #ccc ",
        borderLeft: ".5px solid #ccc ",
        width: "100%",
        marginBottom: "6px",
        padding: "10px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} ml={1}>
          <Typography variant="h5">New Batch</Typography>
        </Grid>
        <Grid item container xs={12} mt={2} mb={2}>
          <Grid
            item
            xs={3}
            container
            justifyContent="flex-end"
            alignContent="center"
          >
            <BatchQuoteUploadTemplate />
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end"></Grid>
          <Grid container item xs={6}>
            <UploadQuoteBatchFile onBatchCreated={handleBatchCreated} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12} mt={1}>
          {expanded ? <BatchQuoteAdditionalDetails /> : null}

          <Typography
            variant="button"
            color="secondary"
            onClick={handleExpandChange}
            style={{ cursor: "pointer", fontSize: 12 }}
          >
            {expanded ? "HIDE INSTRUCTIONS" : "VIEW INSTRUCTIONS"}
          </Typography>
          {expanded ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchQuoteToolBar;
