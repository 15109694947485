import { Grid, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  ListItemDto,
  PricingParametersApi,
} from "../../../api/PricingPlatformApi.generated";
import PricingRegion from "./PricingRegion";
import { useDispatch, useSelector } from "react-redux";
import BusinessHoursSetting from "components/pages/settings/BusinessHoursSetting";
import RoleTypeNames from "constants/RoleConstant";
import { useOktaAuth } from "@okta/okta-react";
import { RootState } from "redux/Store";
import { savePricingParameters } from "redux/slices/PricingParameters/pricingParametersSlice";
import {
  request,
  requestFailure,
  requestSuccess,
} from "redux/slices/Shared/sharedSlice";

const PricingParameters = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const equipmentTypes = useSelector(
    (state: any) => state.pricingParametersReducer.equipmentTypes
  );
  const regions = useSelector(
    (state: RootState) => state.pricingParametersReducer.regions
  );

  const canEditParamaters =
    userData.userRole === RoleTypeNames.OrgAdmin ||
    (userData.userRole === RoleTypeNames.Pricing && userData.isAdmin);

  useEffect(() => {
    const token = oktaAuth.getAccessToken();
    const pricingParametersApi = new PricingParametersApi();
    dispatch(request());
    pricingParametersApi.token = token;
    pricingParametersApi
      .getPricingParameters()
      .then((response) => {
        dispatch(requestSuccess());
        dispatch(savePricingParameters(response));
      })
      .catch((error) => {
        dispatch(requestFailure(error));
      });
  }, [dispatch, oktaAuth]);

  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Pricing Parameters</Typography>
          </Grid>
          <Grid item xs={12} ml={2}>
            <Typography variant="body1">
              The following tables display the core pricing parameter buckets
              and their associated markups. These values will be used to
              calculate the base markup for each quote. Markups can be adjusted
              further via the Rules Engine and manual refinements on the Quote
              Details page.
            </Typography>
          </Grid>
          <Grid item xs={12} ml={7}>
            <BusinessHoursSetting
              canEdit={canEditParamaters}
            ></BusinessHoursSetting>
          </Grid>
          {regions?.map((region) =>
            equipmentTypes.map((equipmentType: ListItemDto) => (
              <Grid
                item
                xs={12}
                key={`${region.regionId}-${equipmentType.value}`}
              >
                <PricingRegion
                  region={region}
                  equipmentType={equipmentType}
                  canEdit={canEditParamaters}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PricingParameters;
