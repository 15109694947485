import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "contexts/UserProvider";
import RoleTypeNames from "constants/RoleConstant";

const LoginPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { currentUser } = useUserContext();

  const login = async () => {
    if(oktaAuth.isLoginRedirect()){
      try {
         console.log("login redirect");
          await oktaAuth.handleLoginRedirect();
        } catch (error) {
          console.error("error", error)
      }
    }else if (!await oktaAuth.isAuthenticated()) {
      console.log("signin with redirect");
      await oktaAuth.signInWithRedirect();
    }
  };

  if (authState?.isAuthenticated && currentUser != null) {
    console.log("current user", currentUser);
    switch (currentUser.userRole) {
      case RoleTypeNames.SystemAdmin:
      case RoleTypeNames.OrgAdmin:
      case RoleTypeNames.CompanyStore:
      case RoleTypeNames.Agent:
      case RoleTypeNames.Pricing:
        return <Navigate to="/quote/history" replace />;
      default:
        return <Navigate to="/404" replace />;
    }
  } else {
    login().then(() => console.log("LOGIN"));
    return null;
  }
};

export default LoginPage;
