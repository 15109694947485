import { Grid, Typography } from "@mui/material";

const BatchQuoteAdditionalDetails = () => {
  return (
    <Grid item container spacing={2}>
      <Grid item container xs={4} justifyContent="center">
        <Grid item mb={1} xs={12}>
          <Typography align="center" variant="h5">
            Download and Complete Template
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align="center" variant="subtitle2">
            The Batch Quote Template contains sample data to help show proper
            data formatting. Please replace this with your own data. All columns
            and row headers must stay the same. Fields marked with * are
            required.
          </Typography>
        </Grid>
      </Grid>
      <Grid justifyContent="center" item container xs={4}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            Upload Quotes
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle2">
            The file must match the provided template format. Each file must
            have no greater than 100 quotes
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BatchQuoteAdditionalDetails;
