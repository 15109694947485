import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, Checkbox } from "@mui/material";
import { EquipmentTypeDto } from "api/PricingPlatformApi.generated";

interface EquipmentTypeTableProps {
  dataList: EquipmentTypeDto[];
}

const EquipmentTypeTable = (props: EquipmentTypeTableProps) => {
  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", flex: 0 },
    { field: "name", headerName: "Name", flex: 1.1 },
    {
      field: "hasTemperatureOption",
      headerName: "Tempurature",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
    {
      field: "hasTarpOption",
      headerName: "Tarps",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
    {
      field: "hasOversizedOption",
      headerName: "Oversized",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
  ];

  const { dataList } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={dataList}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight={true}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default EquipmentTypeTable;
