import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SharedInterface } from "./interface";

const initialState: SharedInterface = {
    loading: false,
    error: null
};

const sharedSlice = createSlice({
    name: "shared",
    initialState,
    reducers: {
        /**
         * Request action to indicate that the request is loading
         * @param loading - boolean flag to indicate if the request is loading
         * @param error - error message if the request fails
         */
        request: (state) => {
            state.loading = true;
            state.error = null;
        },
        /**
         * Request action to indicate that the request is successful
         * @param loading - boolean flag to indicate if the request is loading
         * @param error - error message if the request fails
         */
        requestSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        /**
         * Request action to indicate that the request is failing
         * @param loading - boolean flag to indicate if the request is loading
         * @param error - error message if the request fails
         */
        requestFailure: (state, action: PayloadAction<Error>) => {
            state.loading = false;
            state.error = action.payload
        },
    }
});

export const { request, requestFailure, requestSuccess } = sharedSlice.actions;
export default sharedSlice.reducer;
