import { SvgIcon } from "@mui/material";

const ArrowDownIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M16.5 5V26.255L11.115 20.885L9 23L18 32L27 23L24.87 20.885L19.5 26.255V5H16.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowDownIcon;
