import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";

interface BatchQuoteProgressBarProps {
  statusMessage: string;
  infoMessage: string;
}

const BatchQuoteProgressBar = (
  progressBarProps: BatchQuoteProgressBarProps,
) => {
  return (
    <Box>
      <Grid container item xs={12} alignItems="center">
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="info" />
        </Box>
      </Grid>
      <Grid item xs={9} paddingTop={2}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <Typography>{progressBarProps.statusMessage}</Typography>
          <Typography>{progressBarProps.infoMessage}</Typography>
        </Stack>
      </Grid>
    </Box>
  );
};

export default BatchQuoteProgressBar;
