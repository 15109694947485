import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuoteInterface } from "./interface";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";

const initialState: QuoteInterface = {
  quote: new QuoteResponseDto(),
};

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    saveQuote: (state, action: PayloadAction<QuoteResponseDto>) => {
      state.quote = action.payload;
    },
    updateQuote: (state, action: PayloadAction<QuoteResponseDto>) => {
      state.quote = action.payload;
    },
  },
});

export const { saveQuote, updateQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
