import { DateTime } from "luxon";

export const formatDate = (
  inputDateStr: string,
  format: string = "default"
) => {
  const inputDate = new Date(inputDateStr);

  return formatDateV2(inputDate);
};
export const formatDateV1 = (
  inputDate: DateTime | undefined,
  format: string = "default"
) => {
  if (!inputDate) return "Invalid Format";
  return formatDate(inputDate?.toLocaleString() || "", format);
};
export const formatDateV2 = (
  inputDate: Date | undefined,
  format: string = "default"
) => {
  if (!inputDate) return "Invalid Format";
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  if (format === "default") {
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const year = inputDate.getFullYear();
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    return `${dayOfWeek} ${month}-${day}-${year}`;
  } else if (format === "custom") {
    const month = months[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, "0");
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours().toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    return `${month} ${day} ${year}   ${hours}:${minutes}`;
  } else {
    return "Invalid format";
  }
};
export const formatDateTime = (
  inputDate: DateTime | undefined,
  format: string = "default"
) => {
  if (!inputDate) return "Invalid Format";
  const daysOfWeek = ["", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  if (format === "default") {
    const month = inputDate.month.toString().padStart(2, "0");
    const day = inputDate.day.toString().padStart(2, "0");
    const year = inputDate.year;
    const dayOfWeek = daysOfWeek[inputDate.day];
    return `${dayOfWeek} ${month}-${day}-${year}`;
  } else if (format === "custom") {
    const dayOfWeek = daysOfWeek[inputDate.weekday];
    const month = inputDate.month.toString().padStart(2, "0");
    const day = inputDate.day.toString().padStart(2, "0");
    const year = inputDate.year;
    const hours = inputDate.hour.toString().padStart(2, "0");
    const minutes = inputDate.minute.toString().padStart(2, "0");
    return `${dayOfWeek} ${month}-${day}-${year} ${hours}:${minutes}`;
  } else {
    return "Invalid format";
  }
};
