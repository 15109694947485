const BatchQuoteSampleData = [
  {
    Account: "JOSEPH OAT CORP",
    Equipment: "Van",
    "Temperature Max": "",
    "Temperature Min": "",
    "Tarps Required": "No",
    "Oversize / Overdimensional": "No",
    Commodity: "Aggregate",
    "Specialized Services": "Trailer Wash",
    "Reference Number": "123456",
    Shipper: "Shipper 1",
    Receiver: "Receiver 1",
    "Origin City": "Frisco",
    "Origin State": "TX",
    "Origin ZIP Code": "75036",
    "Pickup Date": "2024-01-01",
    "Estimated Pickup Time": "09:00",
    "Destination City": "WESTLAND",
    "Destination State": "MI",
    "Destination ZIP Code": "48185",
    "Delivery Date": "2024-01-02",
    "Estimated Delivery Time": "03:00",
  },
  {
    Account: "UNITED AIRLINES",
    Equipment: "Reefer",
    "Temperature Max": 30,
    "Temperature Min": 15,
    "Tarps Required": "No",
    "Oversize / Overdimensional": "No",
    Commodity: "Agricultural Machinery and Implements",
    "Specialized Services": "Team",
    "Reference Number": "3456753",
    Shipper: "Shipper 2",
    Receiver: "Receiver 2",
    "Origin City": "WESTLAND",
    "Origin State": "MI",
    "Origin ZIP Code": "48185",
    "Pickup Date": "2024-01-03",
    "Estimated Pickup Time": "01:00",
    "Destination City": "LAWRENCE",
    "Destination State": "MA",
    "Destination ZIP Code": "01840",
    "Delivery Date": "2024-01-04",
    "Estimated Delivery Time": "05:00",
  },
  {
    Account: "UNITED AIRLINES",
    Equipment: "Flatbed",
    "Temperature Max": "",
    "Temperature Min": "",
    "Tarps Required": "Yes",
    "Oversize / Overdimensional": "Yes",
    Commodity: "Agricultural Machinery and Implements",
    "Specialized Services": "Team",
    "Reference Number": "3456753",
    Shipper: "Shipper 2",
    Receiver: "Receiver 2",
    "Origin City": "Cary",
    "Origin State": "NC",
    "Origin ZIP Code": "27502",
    "Pickup Date": "2024-01-03",
    "Estimated Pickup Time": "01:00",
    "Destination City": "LAWRENCE",
    "Destination State": "MA",
    "Destination ZIP Code": "01840",
    "Delivery Date": "2024-01-04",
    "Estimated Delivery Time": "05:00",
  },
];

export default BatchQuoteSampleData;
