import { CityStateLookup } from "api/PricingPlatformApi.generated";

export interface RequestQuoteForm {
  account: number | string;
  equipment: number | string;
  tarpsRequirement: boolean;
  oversizeTarp: boolean;
  minimumTemp: string;
  maxTemp: string;
  pickupDate: null | Date;
  deliveryDate: null | Date;
  pickupDateUnknown: boolean;
  commodity: null | number;
  specializedServices: number[];
  referenceNumber: string;
  shipperName: string;
  receiverName: string;
  estimatedPickupTime: null | Date;
  estimatedDeliveryTime: null | Date;
  origin: null | CityStateLookup;
  destination: null | CityStateLookup;
  isTestQuote: boolean | null;
}

export const RequestQuoteInitialValues: RequestQuoteForm = {
  account: "",
  equipment: "",
  tarpsRequirement: false,
  oversizeTarp: false,
  minimumTemp: "",
  maxTemp: "",
  pickupDate: null,
  deliveryDate: null,
  pickupDateUnknown: false,
  commodity: null,
  specializedServices: [],
  referenceNumber: "",
  shipperName: "",
  receiverName: "",
  estimatedPickupTime: null,
  estimatedDeliveryTime: null,
  origin: null,
  destination: null,
  isTestQuote: null,
};
