import { SvgIcon } from "@mui/material";

const FilterIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M6 13.5H18V11.5H6M3 6.5V8.5H21V6.5M10 18.5H14V16.5H10V18.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default FilterIcon;
