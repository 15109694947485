import { Grid, Typography } from "@mui/material";
import { Checkboxes, Select, TextField } from "mui-rff";
import MenuItem from "@mui/material/MenuItem";
import { RequestQuoteOptionsDto } from "../../../api/PricingPlatformApi.generated";

const EquipmentDetails = ({
  quoteOptions,
  values,
}: {
  quoteOptions: RequestQuoteOptionsDto | undefined;
  values: Record<string, any>;
}) => {
  return (
    <Grid item xs={12} container direction="row" spacing={2}>
      <Grid item xs={4}>
        <Select
          name="equipment"
          id="equipment"
          value={values.equipment}
          label="Equipment*"
        >
          {(quoteOptions?.equipmentTypes ?? []).map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      {values.equipment === 2 ? (
        <Grid
          item
          xs={8}
          container
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle1">Temperature (°f)</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="minimumTemp"
              value={values.minimumTemp}
              label="Min"
              variant="outlined"
              fullWidth
              name="minimumTemp"
              type="number"
              onKeyDown={(e) =>
                (e.key === "-" || e.key === "e") && e.preventDefault()
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">to</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="maxTemp"
              value={values.maxTemp}
              label="Max"
              variant="outlined"
              fullWidth
              name="maxTemp"
              type="number"
              onKeyDown={(e) =>
                (e.key === "-" || e.key === "e") && e.preventDefault()
              }
            />
          </Grid>
        </Grid>
      ) : null}
      {values.equipment === 3 ? (
        <Grid item xs={8} container direction="row" alignItems="center">
          <Grid item>
            <Checkboxes
              name="tarpsRequirement"
              data={values.tarpsRequirement}
            />
          </Grid>
          <Grid item>
            <Typography ml={-2} mr={1}>
              Tarps Required
            </Typography>
          </Grid>
          <Grid item>
            <Checkboxes name="oversizeTarp" data={values.oversizeTarp} />
          </Grid>
          <Grid item>
            <Typography ml={-2}>Oversize/Over Dimensional</Typography>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default EquipmentDetails;
