import { Grid, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  OverridePricingRequestDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";
import PricingOverrideTypes from "constants/PricingOverrideType";
import RoleTypeNames from "constants/RoleConstant";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utilities/functions/FormatCurrency";
import { PricingOverrideInterface } from "redux/slices/PricingOverride/interface";
import { PricingOverrideActionTypes } from "redux/ActionTypes/pricingOverrideActionTypes";

interface RecommendedMarkupProps {
  onPricingOverride?: any;
  quote: QuoteResponseDto;
}

const RecommendedMarkup = (props: RecommendedMarkupProps) => {
  const {
    quote: {
      quoteTargetSellRateDto: originalTargetSellRate,
      overriddenQuoteTargetSellRateDto,
      pricingOverrideDto,
      createdBy: quoteCreatedByUser,
    },
  } = props;
  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const targetSellRate =
    overriddenQuoteTargetSellRateDto || originalTargetSellRate;

  const [sellRate, setSellRate] = useState(
    targetSellRate?.targetSellRate?.toFixed(2)
  );
  const [sellRpm, setSellRpm] = useState(targetSellRate?.totalRpm?.toFixed(2));

  const canOverrideSellRate =
    userData.userRole === RoleTypeNames.OrgAdmin ||
    userData.userRole === RoleTypeNames.Pricing ||
    (userData.userRole === RoleTypeNames.CompanyStore && userData.isAdmin) ||
    (userData.userRole === RoleTypeNames.Agent && userData.isAdmin) ||
    userData.id === quoteCreatedByUser?.id;
  const handleSellRateTextFieldChange = (event: any) => {
    setSellRate(event.target.value);
  };
  const pricingOverrideState: PricingOverrideInterface = useSelector(
    (state: any) => state.pricingOverrideReducer
  );
  useEffect(() => {
    console.log(
      "pricingOverrideState from recommended markup",
      pricingOverrideState
    );

    if (
      pricingOverrideState?.type ===
        PricingOverrideActionTypes.ResetPricingOverride &&
      !pricingOverrideState?.quote?.pricingOverrideDto
    ) {
      setSellRate(
        pricingOverrideState?.quote?.quoteTargetSellRateDto?.targetSellRate?.toFixed(
          2
        )
      );
      setSellRpm(
        pricingOverrideState?.quote?.quoteTargetSellRateDto?.totalRpm?.toFixed(
          2
        )
      );
    } else if (
      pricingOverrideState?.type ===
      PricingOverrideActionTypes.PricingMarkupChange
    ) {
      console.log("set rate!!");
      setSellRate(
        pricingOverrideState?.quote?.quoteTargetSellRateDto?.targetSellRate?.toFixed(
          2
        )
      );
      setSellRpm(
        pricingOverrideState?.quote?.quoteTargetSellRateDto?.totalRpm?.toFixed(
          2
        )
      );
    }
  }, [pricingOverrideState]);
  const handleSellRateKeyPress = (event: any) => {
    if (event.keyCode == 13 || event.keyCode == 9) {
      if (
        event.target.value &&
        Number(event.target.value).toFixed(2) !=
          targetSellRate?.targetSellRate?.toFixed(2)
      ) {
        triggerSellRateUpdate(event.target.value);
      }
    }
  };

  const handleSellRateBlur = (event: any) => {
    if (
      event.target.value &&
      Number(event.target.value).toFixed(2) !=
        targetSellRate?.targetSellRate?.toFixed(2)
    ) {
      triggerSellRateUpdate(Number(sellRate));
    } else {
      setSellRate(targetSellRate?.targetSellRate?.toFixed(2));
    }
  };

  const triggerSellRateUpdate = (sellRate: number) => {
    var overridePricingRequestDto = new OverridePricingRequestDto();
    overridePricingRequestDto.overrideType =
      PricingOverrideTypes.TargetSellRate;
    overridePricingRequestDto.overrideValue = sellRate;
    props.onPricingOverride(overridePricingRequestDto);
  };
  const handleSellRpmTextFieldChange = (event: any) => {
    setSellRpm(event.target.value);
  };
  const handleSellRpmKeyPress = (event: any) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      if (
        event.target.value &&
        event.target.value !== targetSellRate?.totalRpm
      ) {
        triggerSellRpmUpdate(event.target.value);
      }
    }
  };
  const handleSellRpmBlur = (event: any) => {
    console.log(
      "sell updated {event, target}",
      event.target.value,
      targetSellRate?.totalRpm
    );
    if (
      !isNaN(+event.target.value) &&
      Number(event.target.value).toFixed(2) !==
        targetSellRate?.totalRpm?.toFixed(2)
    ) {
      triggerSellRpmUpdate(Number(event.target.value));
    } else {
      setSellRpm(targetSellRate?.totalRpm?.toFixed(2));
    }
  };
  const triggerSellRpmUpdate = (sellRpm: number) => {
    var overridePricingRequestDto = new OverridePricingRequestDto();
    overridePricingRequestDto.overrideType = PricingOverrideTypes.TargetSellRpm;
    overridePricingRequestDto.overrideValue = sellRpm;
    props.onPricingOverride(overridePricingRequestDto);
  };
  return (
    <Stack spacing={1} pb={2}>
      <Stack direction="row" alignItems="flex-end">
        {" "}
        <Typography variant="h6">Recommended Rate</Typography>
      </Stack>
      <Stack direction="row" width={500} spacing={1}>
        <Grid container pl={2}>
          <Grid item>
            <Typography
              sx={{
                color:
                  pricingOverrideDto?.overrideType ===
                  PricingOverrideTypes.TargetSellRate
                    ? "#ed6c03"
                    : "#00000099",
                fontSize: "16px",
              }}
              variant="body2"
            >
              {pricingOverrideDto?.overrideType ===
              PricingOverrideTypes.TargetSellRate
                ? "Override Sell Rate"
                : "Target Sell Rate"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography
              sx={{
                color:
                  pricingOverrideDto?.overrideType ===
                  PricingOverrideTypes.TargetSellRpm
                    ? "#ed6c03"
                    : "#00000099",
                fontSize: "16px",
              }}
              variant="body2"
            >
              {pricingOverrideDto?.overrideType ===
              PricingOverrideTypes.TargetSellRpm
                ? "Override RPM"
                : "RPM"}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" width={500} spacing={1}>
        <Grid container pl={2}>
          <Grid item>
            {canOverrideSellRate &&
            (!pricingOverrideDto?.overrideType ||
              pricingOverrideDto.overrideType ===
                PricingOverrideTypes.TargetSellRate) ? (
              <TextField
                id="totalSellRateInput"
                value={sellRate}
                variant="outlined"
                inputProps={{
                  style: {
                    fontWeight: 600,
                    fontSize: 34,
                    padding: 0,
                    paddingLeft: 5,
                    width: 150,
                  },
                }}
                onChange={handleSellRateTextFieldChange}
                onKeyDown={handleSellRateKeyPress}
                onBlur={handleSellRateBlur}
              />
            ) : (
              <Typography
                sx={{
                  color: pricingOverrideDto?.overrideType
                    ? "#ed6c03"
                    : "rgba(0, 0, 0, 0.87)",
                  fontSize: "34px",
                  fontWeight: 600,
                }}
                variant="body2"
              >
                {formatCurrency(targetSellRate?.targetSellRate ?? 0)}
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ margin: "20px 0 0 6px" }}>
            {originalTargetSellRate?.originalTargetSellRate?.toFixed(2) !==
              targetSellRate?.targetSellRate?.toFixed(2) && (
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.40)",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "line-through",
                }}
                variant="body2"
              >
                {formatCurrency(
                  originalTargetSellRate?.originalTargetSellRate ?? 0
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            {canOverrideSellRate &&
            (!pricingOverrideDto?.overrideType ||
              pricingOverrideDto.overrideType ===
                PricingOverrideTypes.TargetSellRpm) ? (
              <TextField
                id="totalSellRpmInput"
                value={sellRpm}
                variant="outlined"
                inputProps={{
                  style: {
                    fontWeight: 600,
                    fontSize: 34,
                    padding: 0,
                    paddingLeft: 5,
                    width: 150,
                  },
                }}
                onChange={handleSellRpmTextFieldChange}
                onKeyDown={handleSellRpmKeyPress}
                onBlur={handleSellRpmBlur}
              />
            ) : (
              <Typography
                variant="body2"
                sx={{
                  color: pricingOverrideDto?.overrideType
                    ? "#ed6c03"
                    : "rgba(0, 0, 0, 0.87)",
                  fontSize: "34px",
                  fontWeight: 600,
                }}
              >
                {formatCurrency(targetSellRate?.totalRpm ?? 0)}
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ margin: "20px 0 0 6px" }}>
            {targetSellRate?.totalRpm?.toFixed(2) !==
              originalTargetSellRate?.originalTotalRpm?.toFixed(2) && (
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.40)",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "line-through",
                }}
                variant="body2"
              >
                {formatCurrency(originalTargetSellRate?.originalTotalRpm)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Stack>

      <Stack direction="row" width={400} spacing={1} pl={2}>
        <Grid container>
          <Grid item>
            <Typography sx={{ color: "#00000099" }} variant="body2">
              % Margin
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography sx={{ color: "#00000099" }} variant="body2">
              $ Margin
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" width={400} spacing={1} pl={2}>
        <Grid container>
          <Grid item>
            <Typography
              sx={{
                color: pricingOverrideDto?.overrideType
                  ? "#ed6c03"
                  : "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                fontWeight: 600,
              }}
              variant="body2"
            >{`${(targetSellRate?.totalMarginPercent ?? 0).toFixed(2)}%`}</Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "6px" }}>
            {targetSellRate?.totalMarginPercent?.toFixed(2) !==
              originalTargetSellRate?.originalTotalMarginPercent?.toFixed(
                2
              ) && (
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.40)",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "line-through",
                }}
                variant="body2"
              >{`${(originalTargetSellRate?.originalTotalMarginPercent ?? 0).toFixed(2)}%`}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                color: pricingOverrideDto?.overrideType
                  ? "#ed6c03"
                  : "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {formatCurrency(targetSellRate?.totalMarginAmount ?? 0)}
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: "6px" }}>
            {targetSellRate?.totalMarginAmount?.toFixed(2) !==
              originalTargetSellRate?.originalTotalMarginAmount?.toFixed(2) && (
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.40)",
                  fontSize: "14px",
                  fontWeight: 600,
                  textDecoration: "line-through",
                }}
                variant="body2"
              >
                {formatCurrency(
                  originalTargetSellRate?.originalTotalMarginAmount
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default RecommendedMarkup;
