import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { UserDto } from "api/PricingPlatformApi.generated";
import React, { useEffect } from "react";
import PersonAdd from "../../../icons/PersonAdd";
import AddNewUserDrawer from "./AddNewUserDrawer";
import UserTable from "./UserTable";
import AddNewPublicAPIUserDrawer from "./AddNewPublicAPIUser";
import { useApiContext } from "contexts/ApiProvider";

const UserManagement = () => {
  const [addUserDrawerOpen, setAddUserDrawerOpen] = React.useState(false);
  const [addPublicAPIUserDrawerOpen, setAddPublicAPIUserDrawerOpen] =
    React.useState(false);
  const [users, setUsers] = React.useState<UserDto[] | null>([]);

  const { userApi } = useApiContext();

  useEffect(() => {
    const getUsers = async () => {
      try {
        console.log("user api token: ", userApi.token);

        const data = await userApi.getAllUsers();
        setUsers(data);
      } catch (error) {
        console.error("Error retrieving users", error);
      }
    };
    getUsers();
  }, [userApi]);

  const toggleAddUserDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setAddUserDrawerOpen(open);
    };
  const toggleAPIUserDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setAddPublicAPIUserDrawerOpen(open);
    };
  const newUserAddedHandler = (newUser: UserDto) => {
    const newUsersList = [...(users || []), newUser];
    setUsers(newUsersList);
  };
  const userUpdatedHandler = (updatedUser: UserDto) => {
    var index = users?.findIndex(
      (userDto: UserDto) => userDto.id === updatedUser.id
    );

    if (index && index !== -1 && users !== null) {
      var newUsers = [...users];
      newUsers[index] = updatedUser;
      setUsers([...newUsers]);
    }
  };
  return (
    <Grid size={{ xs: 12 }} width="100%">
      <Paper variant="basePaper">
        <Grid container sx={{ paddingBottom: "2%" }}>
          <Grid container size={{ xs: 12 }}>
            <Typography variant="h4">User Management</Typography>
          </Grid>
          <Grid
            size={{ xs: 12 }}
            container
            direction="row"
            justifyContent="flex-end"
            spacing={0.4}
          >
            <Grid>
              <Button
                color="primary"
                variant="contained"
                onClick={toggleAPIUserDrawer(true)}
              >
                Public API
                <PersonAdd sx={{ ml: 1 }} />
              </Button>
            </Grid>
            <Grid>
              <Button
                color="primary"
                variant="contained"
                onClick={toggleAddUserDrawer(true)}
              >
                Add User
                <PersonAdd sx={{ ml: 1 }} />
              </Button>
            </Grid>
            <AddNewUserDrawer
              newUserAdded={(newUser: UserDto) => {
                newUserAddedHandler(newUser);
              }}
              setState={setAddUserDrawerOpen}
              state={addUserDrawerOpen}
              toggleDrawer={toggleAddUserDrawer}
            />
            <AddNewPublicAPIUserDrawer
              newUserAdded={(newUser: UserDto) => {
                newUserAddedHandler(newUser);
              }}
              setState={setAddPublicAPIUserDrawerOpen}
              state={addPublicAPIUserDrawerOpen}
              toggleDrawer={toggleAPIUserDrawer}
            />
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12 }}>
          <UserTable userInfo={users} userUpdated={userUpdatedHandler} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UserManagement;
