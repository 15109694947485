import { SvgIcon } from "@mui/material";

const TruckCheckIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M18.5 19C19.33 19 20 18.33 20 17.5C20 16.67 19.33 16 18.5 16C17.67 16 17 16.67 17 17.5C17 18.33 17.67 19 18.5 19ZM20 10H17.5V12.5H21.96L20 10ZM6.5 19C7.33 19 8 18.33 8 17.5C8 16.67 7.33 16 6.5 16C5.67 16 5 16.67 5 17.5C5 18.33 5.67 19 6.5 19ZM20.5 8.5L23.5 12.5V17.5H21.5C21.5 19.16 20.16 20.5 18.5 20.5C16.84 20.5 15.5 19.16 15.5 17.5H9.5C9.5 19.16 8.16 20.5 6.5 20.5C4.84 20.5 3.5 19.16 3.5 17.5H1.5V6.5C1.5 5.39 2.39 4.5 3.5 4.5H17.5V8.5H20.5ZM3.5 6.5V15.5H4.26C4.81 14.89 5.61 14.5 6.5 14.5C7.39 14.5 8.19 14.89 8.74 15.5H15.5V6.5H3.5ZM5.5 11L7 9.5L8.5 11L12 7.5L13.5 9L8.5 14L5.5 11Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default TruckCheckIcon;
