import { createContext, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  AccountApi,
  BatchQuoteApi,
  CommodityApi,
  EquipmentApi,
  HistoricalLoadApi,
  ImpersonationApi,
  LocationApi,
  OfficeApi,
  QuoteApi,
  RequestQuoteApi,
  SettingApi,
  SpecializedServiceApi,
  TenantManagementApi,
  UserApi,
} from "api/PricingPlatformApi.generated";
import OktaAuth from "@okta/okta-auth-js";

export type ApiContextType = {
  oktaAuth: OktaAuth | null;
  userApi: UserApi;
  locationApi: LocationApi;
  officeApi: OfficeApi;
  accountApi: AccountApi;
  requestQuoteApi: RequestQuoteApi;
  quoteApi: QuoteApi;
  batchQuoteApi: BatchQuoteApi;
  historicalLoadApi: HistoricalLoadApi;
  settingsApi: SettingApi;
  tenantApi: TenantManagementApi;
  equipmentApi: EquipmentApi;
  commodityApi: CommodityApi;
  specializedServiceApi: SpecializedServiceApi;
  impersonationApi: ImpersonationApi;
};

export const ApiContext = createContext<ApiContextType>({
  oktaAuth: null,
  userApi: new UserApi(),
  locationApi: new LocationApi(),
  officeApi: new OfficeApi(),
  accountApi: new AccountApi(),
  requestQuoteApi: new RequestQuoteApi(),
  quoteApi: new QuoteApi(),
  batchQuoteApi: new BatchQuoteApi(),
  historicalLoadApi: new HistoricalLoadApi(),
  settingsApi: new SettingApi(),
  tenantApi: new TenantManagementApi(),
  equipmentApi: new EquipmentApi(),
  commodityApi: new CommodityApi(),
  specializedServiceApi: new SpecializedServiceApi(),
  impersonationApi: new ImpersonationApi(),
});

export const useApiContext = () => {
  const currentContext = useContext(ApiContext);

  const token = currentContext.oktaAuth?.getAccessToken();

  currentContext.userApi.token = token;
  currentContext.locationApi.token = token;
  currentContext.officeApi.token = token;
  currentContext.accountApi.token = token;
  currentContext.requestQuoteApi.token = token;
  currentContext.quoteApi.token = token;
  currentContext.batchQuoteApi.token = token;
  currentContext.historicalLoadApi.token = token;
  currentContext.settingsApi.token = token;
  currentContext.tenantApi.token = token;
  currentContext.equipmentApi.token = token;
  currentContext.commodityApi.token = token;
  currentContext.specializedServiceApi.token = token;
  currentContext.impersonationApi.token = token;

  return currentContext;
};

const ApiProviderContext = ({ children }: any) => {
  const { oktaAuth } = useOktaAuth();

  return (
    <ApiContext.Provider
      value={{
        oktaAuth: oktaAuth,
        userApi: new UserApi(),
        locationApi: new LocationApi(),
        officeApi: new OfficeApi(),
        accountApi: new AccountApi(),
        requestQuoteApi: new RequestQuoteApi(),
        quoteApi: new QuoteApi(),
        batchQuoteApi: new BatchQuoteApi(),
        historicalLoadApi: new HistoricalLoadApi(),
        settingsApi: new SettingApi(),
        tenantApi: new TenantManagementApi(),
        equipmentApi: new EquipmentApi(),
        commodityApi: new CommodityApi(),
        specializedServiceApi: new SpecializedServiceApi(),
        impersonationApi: new ImpersonationApi(),
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const ApiProvider = ApiProviderContext;
