import * as React from "react";
import { Checkbox, Chip, Grid, Typography } from "@mui/material";
import { Autocomplete, TextField } from "mui-rff";
import {
  ListItemDto,
  RequestQuoteOptionsDto,
} from "../../../api/PricingPlatformApi.generated";
import { createFilterOptions } from "@mui/material/useAutocomplete";

const AdditionalDetails = ({
  quoteOptions,
  values,
}: {
  quoteOptions: RequestQuoteOptionsDto | undefined;
  values: Record<string, any>;
}) => {
  const filter = createFilterOptions<ListItemDto>();

  return (
    <Grid item container spacing={1} direction="column">
      <Grid item xs={12}>
        <Typography variant="h6">Additional Details</Typography>
      </Grid>
      <Grid item container spacing={3.4} direction="column">
        <Grid item xs={6}>
          <Autocomplete
            label="Commodity"
            name="commodity"
            multiple={false}
            required={false}
            options={quoteOptions?.commodities ?? []}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option: ListItemDto) => option.label}
            disableCloseOnSelect={false}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            freeSolo={false}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            label="Specialized Services"
            name="specializedServices"
            multiple={true}
            required={false}
            options={quoteOptions?.specializedServices ?? []}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option: ListItemDto) => option.label}
            disableCloseOnSelect={true}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </li>
            )}
            renderTags={(values: readonly ListItemDto[], getTagProps) =>
              values.length > 1 ? (
                <Chip variant="outlined" label={`${values.length} items`} />
              ) : (
                values.map((option: ListItemDto, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              )
            }
            freeSolo={false}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="referenceNumber"
            value={values.referenceNumber}
            label="Reference Number"
            variant="outlined"
            fullWidth
            name="referenceNumber"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="shipperName"
            value={values.shipperName}
            label="Shipper"
            variant="outlined"
            fullWidth
            name="shipperName"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="receiverName"
            value={values.ReceiverName}
            label="Receiver"
            variant="outlined"
            fullWidth
            name="receiverName"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalDetails;
