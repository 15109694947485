import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import VerticalAlignBottomIcon from "../../../../icons/VerticalAlignBottom";
import {
  ListItemDto,
  RequestQuoteOptionsDto,
} from "../../../../api/PricingPlatformApi.generated";
import BatchQuoteSampleData from "./BatchQuoteSampleData";
import ExcelJS from "exceljs";
import batchColumns from "./BatchColumns";
import { useApiContext } from "contexts/ApiProvider";

const BatchQuoteUploadTemplate = () => {
  const [options, setOptions] = useState<RequestQuoteOptionsDto | undefined>(
    undefined
  );

  const { requestQuoteApi } = useApiContext();

  useEffect(() => {
    const getQuoteOptions = async () => {
      const data = await requestQuoteApi.getRequestQuoteOptions();
      setOptions(data);
    };

    getQuoteOptions();
  }, []);

  const commoditiesData =
    options?.commodities?.map((commodity: ListItemDto) => [commodity.label]) ||
    [];
  const specializedServicesData =
    options?.specializedServices?.map((service: ListItemDto) => [
      service.label,
    ]) || [];

  const generateExcelFile = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Quotes");

    const headerRow = sheet.addRow(batchColumns.map((column) => column.label));
    headerRow.font = { bold: true };

    // Add rows
    BatchQuoteSampleData.forEach((item) => {
      const row = sheet.addRow(
        batchColumns.map((column) => item[column.value as keyof typeof item])
      );
    });

    batchColumns.forEach((column, index) => {
      const columnWidth = 20; // Set the desired width
      sheet.getColumn(index + 1).width = columnWidth;
    });

    // Add "Commodities Ref" sheet
    const commoditiesSheet = workbook.addWorksheet("Commodities Ref");
    const commoditiesHeaderRow = commoditiesSheet.addRow(["Commodities"]);
    commoditiesHeaderRow.font = { bold: true };
    commoditiesSheet.columns = [{ width: 50 }];
    commoditiesData.forEach((data) => {
      commoditiesSheet.addRow(data);
    });

    // Add "Specialized Services Ref" sheet
    const specializedServicesSheet = workbook.addWorksheet(
      "Specialized Services Ref"
    );
    const specializedServicesHeaderRow = specializedServicesSheet.addRow([
      "Specialized Services",
    ]);
    specializedServicesHeaderRow.font = { bold: true };
    specializedServicesSheet.columns = [{ width: 30 }];
    specializedServicesData.forEach((data) => {
      specializedServicesSheet.addRow(data);
    });

    // Save the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the click event
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "BatchUploadTemplate.xlsx";
    link.click();
  };

  return (
    <Box color="#026EA1" sx={{ cursor: "pointer" }} onClick={generateExcelFile}>
      <VerticalAlignBottomIcon color="#1B4960" />
      <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
        Download Batch Quote Template
      </span>
    </Box>
  );
};

export default BatchQuoteUploadTemplate;
