import { SvgIcon } from "@mui/material";

const FuelIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M8.5 12.5H10.5V14.5H8.5V12.5ZM8.5 7.5H10.5V11.5H8.5V7.5ZM18.5 19C19.3 19 20 18.3 20 17.5C20 16.7 19.3 16 18.5 16C17.7 16 17 16.7 17 17.5C17 18.3 17.7 19 18.5 19ZM20 10H17.5V12.5H22L20 10ZM6.5 19C7.3 19 8 18.3 8 17.5C8 16.7 7.3 16 6.5 16C5.7 16 5 16.7 5 17.5C5 18.3 5.7 19 6.5 19ZM20.5 8.5L23.5 12.5V17.5H21.5C21.5 19.2 20.2 20.5 18.5 20.5C16.8 20.5 15.5 19.2 15.5 17.5H9.5C9.5 19.2 8.2 20.5 6.5 20.5C4.8 20.5 3.5 19.2 3.5 17.5H1.5V6.5C1.5 5.4 2.4 4.5 3.5 4.5H17.5V8.5H20.5ZM3.5 6.5V15.5H4.3C4.8 14.9 5.7 14.5 6.5 14.5C7.3 14.5 8.2 14.9 8.7 15.5H15.5V6.5H3.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default FuelIcon;
