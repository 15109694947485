import { Breadcrumbs, Grid, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

interface Crumb {
  page: string;
}
interface Breadcrumb {
  path: string;
  pages: Crumb[];
}

const breadcrumbNameMap: { [key: string]: string } = {
  "/quote": "Quote History",
  "/quote/request": "New Quote",
  "/settings": "Settings",
  "/settings/pricing": "Pricing Parameters",
  "/settings/user_management": "Users",
  "/settings/office_management": "Offices",
  "/settings/add_office": "New Office",
  "/settings/edit_office": "Edit Office",
  "/settings/parameters": "Parameters",
  "/settings/tenants": "Tenants",
  "/quote-request": "Quote Details",
  "/quote-refactor": "Quote Details",
  "/api/errorlog": "Error Log",
};

const NavBreadcrumbs = () => {
  const theme = useTheme();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Grid
      container
      sx={{
        padding: "7px 86px 8px 86px",
        backgroundColor: theme.palette.breadcrumbs.main,
        borderTop: "1px solid #D6D5D5",
      }}
    >
      <Breadcrumbs>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          let crumbText = breadcrumbNameMap[to];

          // override for quote details using the hashid length of 6
          if (!crumbText && to.startsWith("/quote") && to.length === 13) {
            crumbText = "Quote Details";
          }

          return crumbText ? (
            <Typography
              key={to}
              variant="breadcrumbText"
              sx={{
                color: theme.palette.breadcrumbs.contrastText,
                cursor: "default",
              }}
            >
              {crumbText}
            </Typography>
          ) : null;
        })}
      </Breadcrumbs>
    </Grid>
  );
};

export default NavBreadcrumbs;
