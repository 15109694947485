import React, { DragEvent, useRef, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ExcelJS from "exceljs";
import VerticalAlignTop from "icons/VerticalAlignTop";
import InputLabel from "@mui/material/InputLabel";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import batchColumns from "./BatchColumns";
import {
  BatchQuoteHistoryDto,
  FileParameter,
} from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";

interface UploadQuoteBatchFileProps {
  onBatchCreated: (batchData: BatchQuoteHistoryDto) => void;
}

const UploadQuoteBatchFile: React.FC<UploadQuoteBatchFileProps> = ({
  onBatchCreated,
}) => {
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(
    ""
  );

  const { batchQuoteApi } = useApiContext();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const uploadedFileRef = useRef<HTMLInputElement>(null);

  const clickUploadHandler = () => {
    if (uploadedFileRef.current !== null) {
      uploadedFileRef.current.click();
    }
  };
  const uploadFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && validateUploadedFile(files)) {
      setUploadedFile(files[0]);
    }
  };

  const fileDropHandler = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;

    if (validateUploadedFile(files)) {
      setUploadedFile(files[0]);
    }
  };

  const enableDragAndDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  function validateUploadedFile(files: FileList | null) {
    if (files && files.length) {
      const file = files[0];
      setUploadedFile(file);

      if (files.length !== 1) {
        setUploadErrorMessage("Only one file can be uploaded at a time.");
        return false;
      }

      const allowed_file_type = "xlsx";
      var parts = file.name.split(".");
      const fileType = parts[parts.length - 1];
      if (fileType.toLowerCase() !== allowed_file_type.toLowerCase()) {
        setUploadErrorMessage("File must be .xlsx format");
        return false;
      }
      if (file.size > 1024 * 1024 * 4) {
        setUploadErrorMessage("File must be less than 4 MB.");
        return false;
      }
      return validateFileFormat(file);
    }
    return false;
  }

  async function validateFileFormat(file: File) {
    let index = 0;
    const fileBuffer = await file.arrayBuffer();
    const workbook = new ExcelJS.Workbook().xlsx.load(fileBuffer);
    const firstRow = (await workbook).worksheets[0].getRow(1);
    firstRow.eachCell((cell) => {
      if (cell.value !== batchColumns[index++].label) {
        setUploadErrorMessage("File columns must match template exactly.");
        return false;
      }
    });
    return true;
  }

  const setInputRefToNull = () => {
    if (uploadedFileRef.current) {
      uploadedFileRef.current.value = "";
      uploadedFileRef.current.type = "file";
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setUploadErrorMessage("");
    setUploadedFile(null);
    setInputRefToNull();
  };

  const createBatch = async () => {
    const fileParameter: FileParameter = {
      data: uploadedFile,
      fileName: uploadedFile?.name ?? "BatchQuoteUploadFile",
    };
    try {
      const response = await batchQuoteApi.createBatch(fileParameter);
      setUploadedFile(null);
      onBatchCreated(response);
      setInputRefToNull();
    } catch (errr) {
      console.error(errr);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item>
        <input
          type="file"
          ref={uploadedFileRef}
          style={{ position: "absolute", left: "-999px" }}
          onChange={uploadFileHandler}
        />
      </Grid>
      <Grid
        item
        container
        style={{
          border:
            uploadErrorMessage === ""
              ? "2px dashed #ddd"
              : "1px ridge rgb(223 175 175)",
          borderRadius: "6px",
          width: "350px",
          padding: "10px 0",
          minHeight: "90px",
          cursor: uploadErrorMessage === "" ? "pointer" : "",
        }}
        alignItems="center"
        justifyContent="space-around"
        onDragOver={enableDragAndDrop}
        onDrop={fileDropHandler}
        onClick={clickUploadHandler}
        xs={6}
      >
        {
          // Which icon should be present based on success or error
          uploadedFile === null || uploadedFile === undefined ? (
            <Grid item xs={1} style={{ margin: "20px" }}>
              <VerticalAlignTop fontSize="large" style={{ color: "#026EA1" }} />
            </Grid>
          ) : uploadedFile && uploadErrorMessage === "" ? (
            <Grid item container xs={1} justifyContent="flex-end">
              <CheckCircleIcon
                fontSize="medium"
                style={{ color: "#008000", marginBottom: "20px" }}
              />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={1}
              justifyContent="flex-end"
              style={{ height: "70%" }}
            >
              <InfoIcon fontSize="medium" style={{ color: "#C92519" }} />
            </Grid>
          )
        }
        {
          // Text
          uploadedFile === null || uploadedFile === undefined ? (
            <Grid item container md={8}>
              <Grid item>
                <Typography>
                  <span style={{ color: "#026EA1" }}>Select file </span>or drag
                  and drop
                </Typography>
              </Grid>
              <Grid item>
                <InputLabel>XLSX, max: 100 qoutes</InputLabel>
              </Grid>
            </Grid>
          ) : uploadedFile && uploadErrorMessage === "" ? (
            <Grid
              item
              container
              md={8}
              ml={1}
              mr={1}
              direction="column"
              alignContent="center"
            >
              <Grid item>
                <Typography variant="subtitle2">
                  {uploadedFile ? uploadedFile.name : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" fontSize={"small"}>
                  File added.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid item container md={8} ml={1} mr={1}>
              <Grid item>
                <Typography variant="subtitle2" color="#C92519">
                  {uploadedFile ? uploadedFile.name : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  color="#C92519"
                  className="upload-file-error-message"
                >
                  {uploadErrorMessage}
                </Typography>
              </Grid>
            </Grid>
          )
        }
        {
          // Cancel button
          uploadedFile === null ||
          uploadedFile === undefined ? null : uploadedFile &&
            uploadErrorMessage === "" ? (
            <Grid item xs={1} style={{ margin: "auto" }}>
              <Button onClick={handleCancel}>
                <DeleteRoundedIcon
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                />
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1} style={{ height: "70%" }} pb={1}>
              <CancelIcon
                style={{ cursor: "pointer" }}
                onClick={handleCancel}
                htmlColor="#C92519"
              />
            </Grid>
          )
        }
      </Grid>

      <Grid xs={6} container item justifyContent="center" alignItems="baseline">
        <Button
          variant="contained"
          onClick={() => createBatch()}
          disabled={uploadedFile && uploadErrorMessage === "" ? false : true}
          style={{ marginTop: "30px" }}
        >
          CREATE BATCH
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadQuoteBatchFile;
