import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Paper, Typography } from "@mui/material";
import { PublicAPIErrorLogDto } from "../../../api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";

const APIErrorLogHistory = () => {
  const [APIBatchQuoteError, setAPIBatchQuoteError] = useState<
    PublicAPIErrorLogDto[] | null
  >([]);

  const { batchQuoteApi } = useApiContext();

  useEffect(() => {
    const getBatchQuoteHitory = async () => {
      try {
        const data = await batchQuoteApi.getPublicAPIErrorList();
        console.log("data", data);
        setAPIBatchQuoteError(data);
      } catch (error) {
        console.error("Error retrieving batchQuoteHistory", error);
      }
    };

    getBatchQuoteHitory();
  }, []);
  const columns: GridColDef[] = [
    {
      field: "account",
      align: "center",
      headerAlign: "center",
      headerName: "Account Name",
      flex: 1,
      valueGetter: (value, row) => {
        return row.account?.name || "";
      },
    },
    {
      field: "requestedQuote",
      align: "center",
      headerAlign: "center",
      headerName: "Requested Payload",
      flex: 2,
    },
    {
      field: "errorList",
      align: "center",
      headerAlign: "center",
      headerName: "Error List",
      flex: 2,
    },
    {
      field: "createdOnUtc",
      align: "center",
      headerAlign: "center",
      headerName: "Created On",
      flex: 1,
      valueGetter: (value, row) => {
        return row.createdOnUtc?.toLocaleString();
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => {
        return row.createdBy?.email || "";
      },
    },
  ];
  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid container xs={12} item mb={6}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ marginBottom: "30px", marginLeft: "16px" }}
              >
                API Error Log
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                columns={columns}
                rows={APIBatchQuoteError?.length ? APIBatchQuoteError : []}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 20]}
                autoHeight={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default APIErrorLogHistory;
