import {
  Button,
  ButtonGroup,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  QuoteResponseDto,
  ReasonLostDto,
  RequestQuoteOptionsDto,
  UpdateQuoteStatusRequest,
} from "api/PricingPlatformApi.generated";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-final-form";
import { UpdateQuoteStatusValidationSchema } from "utilities/form-validation/UpdateQuoteStatusValidationSchema";
import { setIn } from "final-form";
import { UpdateQuoteStatusValues } from "utilities/form-initial-values/UpdateQuoteStatusValues";
import { useSelector } from "react-redux";
import { Autocomplete } from "mui-rff";
import { useTheme } from "@mui/material/styles";
import { useApiContext } from "contexts/ApiProvider";
import { PricingOverrideInterface } from "redux/slices/PricingOverride/interface";

interface QuoteStatusProps {
  quote: QuoteResponseDto | null;
  canEditStatus: boolean;
  canEditMarkup: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const QuoteStatus = (props: QuoteStatusProps) => {
  const theme = useTheme();
  const { quote, canEditStatus } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [finalRate, setFinalRate] = useState<string>();
  const [lossRate, setLossRate] = useState<string>();
  const { requestQuoteApi } = useApiContext();

  const [status, setStatus] = useState<string>();

  const optionsData = useSelector<any, RequestQuoteOptionsDto>(
    (state: any) => state.userRequestReducer.options
  );

  const filter = createFilterOptions<ReasonLostDto>();

  const pricingOverrideState: PricingOverrideInterface = useSelector(
    (state: any) => state.pricingOverrideReducer
  );

  useEffect(() => {
    console.log(
      "pricingOverrideState from recommended markup",
      pricingOverrideState
    );

    if (pricingOverrideState.quote) {
      updateFinalRate(pricingOverrideState.quote);
    }
  }, [pricingOverrideState]);

  useEffect(() => {
    console.log("quote status updated");
    if (quote) {
      updateFinalRate(quote);
      setStatus(quote.status);
    }
  }, [quote]);

  const updateFinalRate = (quote: QuoteResponseDto) => {
    if (quote?.overriddenQuoteTargetSellRateDto) {
      setFinalRate(
        quote?.overriddenQuoteTargetSellRateDto?.targetSellRate?.toFixed(2)
      );
    } else {
      setFinalRate(quote.quoteTargetSellRateDto?.targetSellRate?.toFixed(2));
    }
  };

  const handleClickOpen = () => {
    if (canEditStatus) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    console.log("quote status form submitted");
    callQuoteStatusUpdateApi(quote?.hashedId, status);
  };

  const handleUpdateStatus = () => {
    onSubmit();
    handleClose();
  };

  const callQuoteStatusUpdateApi = async (
    quoteId?: string,
    updatedStatus?: string
  ) => {
    const updateQuoteStatusRequest = new UpdateQuoteStatusRequest({
      hashedId: quoteId,
      status: updatedStatus,
    });

    requestQuoteApi
      .updateQuoteStatus(updateQuoteStatusRequest)
      .then((response) => {
        if (response === true) {
        }
      });
  };

  const validateFormValues =
    (schema: any) => async (values: UpdateQuoteStatusValues) => {
      if (typeof schema === "function") {
        schema = schema();
      }

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (err: any) {
        const errors = err.inner.reduce((formError: any, innerError: any) => {
          return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
      }
    };
  const validate = validateFormValues(UpdateQuoteStatusValidationSchema);

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} sx={{ width: 120 }}>
        {quote?.status}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          variant="h6"
          component="h6"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          Update Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Form
            onSubmit={() => onSubmit}
            validate={() => validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                      Tack the status of this quote by marking it as Won or
                      Lost. Capture information relating to customer and
                      additional pertinent details.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonGroup disableElevation variant="text" fullWidth>
                      <Button
                        onClick={() => {
                          setStatus("Won");
                        }}
                        sx={{
                          background:
                            status === "Won"
                              ? `${theme.palette.primary.main}`
                              : "default",
                          color: status === "Won" ? "white" : "default",
                          borderBottom: status === "Won" ? "2px solid" : "0px",
                          borderRadius: 0,
                        }}
                      >
                        Win
                      </Button>
                      <Button
                        onClick={() => {
                          setStatus("Pending");
                        }}
                        sx={{
                          background:
                            status === "Pending"
                              ? `${theme.palette.primary.main}`
                              : "default",
                          color: status === "Pending" ? "white" : "default",
                          borderBottom:
                            status === "Pending" ? "2px solid" : "0px",
                          borderRadius: 0,
                        }}
                      >
                        Pending
                      </Button>
                      <Button
                        onClick={() => {
                          setStatus("Lost");
                        }}
                        sx={{
                          background:
                            status === "Lost"
                              ? `${theme.palette.primary.main}`
                              : "default",
                          color: status === "Lost" ? "white" : "default",
                          borderBottom: status === "Lost" ? "2px solid" : "0px",
                          borderRadius: 0,
                        }}
                      >
                        Loss
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="h6">Customer Details</Typography>
                      <Divider variant="inset" />
                      <InputLabel htmlFor="customer-name">Name *</InputLabel>
                      <TextField
                        id="customer-name"
                        variant="outlined"
                        required
                        fullWidth
                      />
                      <InputLabel htmlFor="customer-email">Email</InputLabel>
                      <TextField
                        id="customer-email"
                        variant="outlined"
                        fullWidth
                      />
                      <InputLabel htmlFor="customer-phone">Phone</InputLabel>
                      <TextField
                        id="customer-phone"
                        variant="outlined"
                        fullWidth
                      />
                    </Stack>
                  </Grid>

                  {status === "Won" ? (
                    <>
                      <Grid container item xs={6}>
                        <Stack direction="column" spacing={1}>
                          <Typography variant="h6">Win Details</Typography>
                          <Divider variant="inset" />
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Sell Rate *
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            value={finalRate}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFinalRate(event.target.value);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </Stack>
                      </Grid>
                    </>
                  ) : null}

                  {status === "Lost" ? (
                    <Grid container item xs={6}>
                      <Stack direction="column" width="100%" spacing={1}>
                        <Typography variant="h6">Loss Details</Typography>
                        <Divider variant="inset" />

                        <InputLabel htmlFor="loss-reason">
                          Reason for Loss
                        </InputLabel>
                        <Autocomplete
                          name="loss-reason"
                          id="loss-reason"
                          multiple={false}
                          required={false}
                          options={optionsData.reasonLostOptions ?? []}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.reason ?? ""}
                          disableCloseOnSelect={false}
                          renderOption={(props, option) => (
                            <li {...props}>{option.reason}</li>
                          )}
                          freeSolo={false}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                        />

                        <InputLabel htmlFor="outlined-adornment-amount">
                          Sell Rate
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          value={lossRate}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setLossRate(event.target.value);
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />

                        <InputLabel htmlFor="multiline-notes">Notes</InputLabel>
                        <TextField
                          id="multiline-notes"
                          multiline
                          fullWidth
                          rows={4}
                          placeholder="Capture any additional details related to the quote"
                        />
                      </Stack>
                    </Grid>
                  ) : null}
                </Grid>
              </form>
            )}
          ></Form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={handleUpdateStatus}>
            Update Status
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default QuoteStatus;
