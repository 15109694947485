import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { ListItemDto } from "api/PricingPlatformApi.generated";

interface ListItemTableProps {
  dataList: ListItemDto[];
}

const ListItemTable = (props: ListItemTableProps) => {
  const columns: GridColDef[] = [
    // { field: "value", headerName: "ID", flex: 0 },
    { field: "label", headerName: "Name", flex: 1.1 },
  ];
  const { dataList } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={dataList}
        getRowId={(row) => row.value}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight={true}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default ListItemTable;
