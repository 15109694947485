import { Alert, Paper, Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { setIn } from "final-form";
import { Autocomplete, TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { CreateAPIUserValidationSchema } from "utilities/form-validation/CreateUserValidationSchema";
import {
  AccountDto,
  AddUserRequestDto,
  UpdateApiUserRequestDto,
  UserDto,
} from "../../../api/PricingPlatformApi.generated";
import Close from "../../../icons/Close";
import {
  CreateUserFormValues,
  InitialCreatePublicAPIUserFormValues,
} from "../../../utilities/form-initial-values/AddUserInitialValues";
import { useApiContext } from "contexts/ApiProvider";
import { RoleTypeIds } from "constants/RoleConstant";

interface AddNewPublicAPIUserDrawerProps {
  state: boolean;
  setState: any;
  newUserAdded?: any;
  toggleDrawer: any;
  user?: UserDto | null;
}

const AddNewPublicAPIUserDrawer = (props: AddNewPublicAPIUserDrawerProps) => {
  const { state, setState, newUserAdded, toggleDrawer, user } = props;
  const editMode = user !== null && user !== undefined;
  const editUserFormValues: CreateUserFormValues = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    accountId: user?.account?.id?.toString() ?? "",
    userRoleId: RoleTypeIds.PublicAPI.toString(),
    isAdmin: false,
    officeId: "",
    id: user?.id ?? null,
  };
  const [showSuccessToast, setShowSuccessToast] = React.useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showEmailExistsMsg, setShowEmailExistsMsg] = React.useState(false);
  const [accounts, setAccounts] = useState<AccountDto[]>([]);

  const { userApi, accountApi } = useApiContext();

  useEffect(() => {
    const getFilteredAccounts = async () => {
      const data = await accountApi.search("apiKey");
      setAccounts(data);
    };

    getFilteredAccounts();
  }, [state]);

  const submitUser = async (values: CreateUserFormValues) => {
    setShowEmailExistsMsg(false);
    userApi
      .getUserByEmail(values.email)
      .then((response) => {
        if (response) {
          setShowEmailExistsMsg(true);
        }
      })
      .catch(() => {
        if (editMode) {
          callUpdateUserApi(values);
        } else {
          callCreateUserApi(values);
        }
      });
  };
  const callCreateUserApi = async (values: CreateUserFormValues) => {
    const addUserRequestDto = AddUserRequestDto.fromJS(values);
    if (!addUserRequestDto.officeId) addUserRequestDto.officeId = undefined;
    userApi
      .create(addUserRequestDto)
      .then((response) => {
        setShowSuccessToast(true);
        newUserAdded(response);
        setState(false);
      })
      .catch((error) => {
        setShowErrorToast(true);
      });
  };
  const callUpdateUserApi = async (values: CreateUserFormValues) => {
    const updateApiUserRequestDto = UpdateApiUserRequestDto.fromJS(values);

    userApi
      .updatePublicApiUser(updateApiUserRequestDto)
      .then((response) => {
        setShowSuccessToast(true);
        newUserAdded(response);
        setState(false);
      })
      .catch((error) => {
        setShowErrorToast(true);
      });
  };

  const validateFormValues =
    (schema: any) => async (values: CreateUserFormValues) => {
      if (typeof schema === "function") {
        schema = schema();
      }
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (err: any) {
        const errors = err.inner.reduce((formError: any, innerError: any) => {
          return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
      }
    };
  const validate = validateFormValues(CreateAPIUserValidationSchema);

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor={"right"}
          open={state}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              backgroundColor: "#DAE8EE",
            },
          }}
        >
          <Grid container mt={9} direction="column">
            <Grid container justifyContent="flex-end">
              <Button onClick={toggleDrawer(false)} color="secondary">
                <Close />
              </Button>
            </Grid>
            <Grid ml={2}>
              <Typography color="secondary" variant="h6">
                {editMode ? "Update Public API User" : "Create Public API User"}
              </Typography>
            </Grid>
            <Grid>
              <Paper
                elevation={10}
                sx={{
                  margin: "10px 10px 10px 10px",
                  width: "350px",
                  minHeight: "calc(100vh - 160px)",
                }}
              >
                <Grid container direction="column">
                  <Form
                    initialValues={
                      editMode
                        ? editUserFormValues
                        : InitialCreatePublicAPIUserFormValues
                    }
                    onSubmit={(values: CreateUserFormValues) => {
                      submitUser(values);
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, values }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            pl={1}
                            pt={1}
                          >
                            {showEmailExistsMsg ? (
                              <Grid>
                                <Alert severity="error" sx={{ width: "100%" }}>
                                  Email is already in use.
                                </Alert>
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid>
                              <Typography variant="h6" mb={1}>
                                Contact Name{" "}
                              </Typography>
                              <TextField
                                id="firstName"
                                value={values.firstName}
                                label="First Name"
                                required={true}
                                variant="outlined"
                                name="firstName"
                                sx={{ width: "75%" }}
                              />
                            </Grid>
                            <Grid>
                              <TextField
                                id="lastName"
                                value={values.lastName}
                                label="Last Name"
                                required={true}
                                variant="outlined"
                                name="lastName"
                                sx={{ width: "75%" }}
                              />
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mb={1}>
                                Contact Email{" "}
                              </Typography>
                              <TextField
                                id="email"
                                value={values.email}
                                label="Email"
                                required={true}
                                variant="outlined"
                                name="email"
                                sx={{ width: "75%" }}
                              />
                            </Grid>

                            <Grid>
                              <Typography variant="h6" mb={1}>
                                Accounts{" "}
                              </Typography>
                              <Autocomplete
                                id="accountId"
                                label="Account*"
                                name="accountId"
                                multiple={false}
                                required={false}
                                disabled={editMode}
                                value={user?.account}
                                sx={{ width: "75%" }}
                                options={accounts}
                                getOptionValue={(option) => "" + option.id}
                                getOptionLabel={(option) => "" + option.name}
                                disableCloseOnSelect={false}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                freeSolo={false}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                              />
                            </Grid>

                            <Grid>
                              <Typography variant="h6">
                                {user?.apiKey ? "APIKey" : ""}
                              </Typography>
                              <Typography color="secondary">
                                {" "}
                                {editMode ? user?.apiKey : ""}{" "}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              mt={10}
                              pr={2}
                            >
                              <Button
                                disabled={form.getState().invalid}
                                type="submit"
                                variant="contained"
                              >
                                {editMode
                                  ? "Update User"
                                  : "Create Public API Key"}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </>
                    )}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Drawer>
        <Snackbar
          open={showSuccessToast}
          autoHideDuration={4000}
          onClose={() => setShowSuccessToast(false)}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {editMode
              ? "Public API User updated successfully"
              : "Public API User created successfully"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={4000}
          onClose={() => setShowErrorToast(false)}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {editMode
              ? "Error Updating Public API User"
              : "Error Creating Public API User"}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </div>
  );
};

export default AddNewPublicAPIUserDrawer;
