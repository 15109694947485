import { debounce } from "@mui/material";
import { CityStateLookup } from "api/PricingPlatformApi.generated";
import axios, { CancelTokenSource } from "axios";
import { useApiContext } from "contexts/ApiProvider";
import { Autocomplete } from "mui-rff";
import { useCallback, useEffect, useState } from "react";

export type ZipCodeLookupProps = {
  id?: string;
  name: string;
  label?: string;
  required: boolean;
  onZipCodeSelected?: any;
  disabled?: boolean;
  defaultValue?: CityStateLookup;
};

const ZipCodeLookup = (props: ZipCodeLookupProps) => {
  const initialResults: Partial<CityStateLookup>[] = [];
  const [results, setResults] = useState(initialResults);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(
    null
  );
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { locationApi } = useApiContext();

  const getOptionsDelayed = useCallback(
    debounce(
      async (query: string, callback: (options: CityStateLookup[]) => void) => {
        let CancelToken = axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        setCancelToken(cancelTokenSource);
        setResults(initialResults);
        setIsLoading(true);

        locationApi
          .search(query, cancelTokenSource.token)
          .then(callback)
          .catch((err) => {
            setResults(initialResults);
            setIsLoading(false);
          });
      },
      900
    ),
    []
  );
  useEffect(() => {
    if (searchText === "") {
      setResults(initialResults);
    } else {
      getOptionsDelayed(searchText, (searchResults: CityStateLookup[]) => {
        setResults(searchResults);
        setIsLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptionsDelayed, searchText]);

  const onChange = (_event: any, value: CityStateLookup) => {
    if (_event?.currentTarget.zipcode !== "" && props.onZipCodeSelected) {
      props.onZipCodeSelected(value);
    }
  };
  const onSearchTextChange = (event: any, value: string) => {
    if (event?.type === "change") {
      cancelToken?.cancel();
      setSearchText(value);
    }
  };

  return (
    <Autocomplete
      multiple={false}
      {...props}
      options={results}
      loading={isLoading}
      getOptionValue={(option) => option}
      noOptionsText={"Please enter a valid city or ZIP Code"}
      getOptionLabel={(option) =>
        `${option.city}, ${option.state} ${option.country} ${option.zipcode}`
      }
      isOptionEqualToValue={(option, value) => {
        if (value.zipcode === undefined) return true;
        return option.zipcode === value.zipcode;
      }}
      renderOption={(props, option) => (
        <li
          {...props}
        >{`${option.city}, ${option.state} ${option.country} ${option.zipcode}`}</li>
      )}
      onChange={(e, value: any) => onChange(e, value)}
      onInputChange={(event, value) => onSearchTextChange(event, value)}
      filterOptions={(options) => results}
      freeSolo={false}
    />
  );
};

export default ZipCodeLookup;
