import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutes from "./components/pages/Routes";
import { selectPageTitle } from "./utilities/PageTitleUtility";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Provider } from "react-redux";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import oktaConfig from "config/oktaConfig";
import { UserProvider } from "contexts/UserProvider";
import { ApiProvider } from "contexts/ApiProvider";
import { CustomThemeProvider } from "contexts/CustomThemeProvider";
import "@fontsource/roboto";
import "@fontsource/poppins";
import { store } from "redux/Store";
import "@mantine/core/styles.css";

const oktaAuth = new OktaAuth(oktaConfig.oidc);

const App = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    selectPageTitle(location.pathname);
  }, [location]);

  function checkEnv() {
    console.log(`${process.env.REACT_APP_FRONTEND_ENV}`);
  }

  (window as any).checkEnv = checkEnv;

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={store}>
        <ApiProvider>
          <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomThemeProvider>
                <AppRoutes />
              </CustomThemeProvider>
            </LocalizationProvider>
          </UserProvider>
        </ApiProvider>
      </Provider>
    </Security>
  );
};

export default App;
