import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PricingParametersInterface } from "./interface";
import { PricingParametersDto } from "api/PricingPlatformApi.generated";
import { mergeUpdatedValues } from "../../Utils/mergeUpdatedValues";

const initialState: PricingParametersInterface = {
    regions: [],
    leadTimeBuckets: [],
    mileageBuckets: [],
    equipmentTypes: [],
    pricingParameterValues: []
};

const pricingParametersSlice = createSlice({
    name: "pricingParameters",
    initialState,
    reducers: {
        savePricingParameters: (state, action: PayloadAction<PricingParametersDto>) => {
            state.regions = action.payload.regions;
            state.leadTimeBuckets = action.payload.leadTimeBuckets;
            state.mileageBuckets = action.payload.mileageBuckets;
            state.equipmentTypes = action.payload.equipmentTypes;
            state.pricingParameterValues = action.payload.values;
        },
        updatePricingParameters: (state, action: PayloadAction<any>) => {
            const updatedValues = action.payload;
            const mergedValues = mergeUpdatedValues(
                state.pricingParameterValues,
                updatedValues
            );
            state.pricingParameterValues = mergedValues;
        }
    }
});

export const { savePricingParameters,updatePricingParameters } = pricingParametersSlice.actions;

export const getPricingParametersSlice = (state: any) => state.pricingParametersData;

export default pricingParametersSlice.reducer;