import { UserDto } from "api/PricingPlatformApi.generated";
import React from "react";
import { Navigate } from "react-router-dom";
//import Loading from "./Loading";

interface RequireAuthorizationProps {
  user: UserDto | null;
  authorizedRoles: string[];
  children: any;
}

export const RequireAuthorization: React.FC<RequireAuthorizationProps> = ({
  user,
  authorizedRoles,
  children,
}) => {
  // F5 issue calls this before user is populated
  if (user === null) {
    return <>{children}</>;
  }

  console.log("checking authorization", user);
  if (authorizedRoles.includes(user?.userRole as string)) {
    return <>{children}</>;
  } else {
    console.log(`Not authorized for role ${user?.userRole}`);
    return <Navigate to="/404" replace />;
  }
};
