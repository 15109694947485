import { SvgIcon } from "@mui/material";

const ArrowUpIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M13 21.5V7.33L16.59 10.91L18 9.5L12 3.5L6 9.5L7.42 10.91L11 7.33V21.5H13Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowUpIcon;
