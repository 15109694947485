import { Paper, Typography } from "@mui/material";

const HistoricalLoadsInsights = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Typography variant="insightLabel">Historical Loads </Typography>
    </Paper>
  );
};

export default HistoricalLoadsInsights;
