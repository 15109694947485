import { SvgIcon } from "@mui/material";

const OpenFolderIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M6.76666 10L4.66666 18V8H21.6667C21.6667 7.46957 21.4559 6.96086 21.0809 6.58579C20.7058 6.21071 20.1971 6 19.6667 6H12.6667L10.6667 4H4.66666C4.13622 4 3.62752 4.21071 3.25244 4.58579C2.87737 4.96086 2.66666 5.46957 2.66666 6V18C2.66666 18.5304 2.87737 19.0391 3.25244 19.4142C3.62752 19.7893 4.13622 20 4.66666 20H19.6667C20.5667 20 21.3667 19.4 21.5667 18.5L23.8667 10H6.76666ZM19.6667 18H6.66666L8.26666 12H21.2667L19.6667 18Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default OpenFolderIcon;
