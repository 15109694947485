import { SvgIcon } from "@mui/material";

const CalendarWithDateIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M7.33331 11H9.33331V13H7.33331V11ZM19.3333 3H18.3333V1H16.3333V3H8.33331V1H6.33331V3H5.33331C4.23331 3 3.33331 3.9 3.33331 5V19C3.33331 20.1 4.23331 21 5.33331 21H19.3333C20.4333 21 21.3333 20.1 21.3333 19V5C21.3333 3.9 20.4333 3 19.3333 3ZM19.3333 5V7H5.33331V5H19.3333ZM5.33331 19V9H19.3333V19H5.33331ZM11.3333 15H13.3333V17H11.3333V15ZM15.3333 15H17.3333V17H15.3333V15ZM15.3333 11H17.3333V13H15.3333V11Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default CalendarWithDateIcon;
