import { SvgIcon } from "@mui/material";

const MetaforaHistoryIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M13.8501 5H21.8501V19H13.8501V5ZM3.8501 5H11.8501V7H3.8501V5ZM3.8501 11V9H11.8501V11H3.8501ZM3.8501 19V17H11.8501V19H3.8501ZM3.8501 15V13H11.8501V15H3.8501Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default MetaforaHistoryIcon;
