import { useEffect } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import PricingBreakdown from "../PricingBreakdown";
import { useSelector } from "react-redux";
import { useApiContext } from "contexts/ApiProvider";
import { useDispatch } from "react-redux";
import { saveQuote } from "redux/slices/Quote/quoteSlice";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import AccountDetails from "../AccountDetails/AccountDetailsCardLayout";
import QuoteDetailsHeader from "../QuoteDetailsHeader";
import QuoteLaneInsights from "components/pages/quote-insights/QuoteLaneInsights";
import QuoteCustomerInsights from "components/pages/quote-insights/QuoteCustomerInsights";
import MarketPricingV2 from "../../quote-pricing/MarketPricing";
import InsightLists from "components/pages/quote-insights/InsightLists";
import { useFlags } from "launchdarkly-react-client-sdk";

const QuoteDetailsLayout = () => {
  const { hashedId } = useParams();
  const { quoteApi } = useApiContext();
  const dispatch = useDispatch();
  const ldFlags = useFlags();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  useEffect(() => {
    quoteApi.getQuoteDetails(hashedId).then((response) => {
      // setTimeout(() => {
      //   dispatch(saveQuote(response));
      // }, 50000);

      dispatch(saveQuote(response));
    });
  }, [dispatch, hashedId, quoteApi]);

  return (
    <Paper variant="basePaper" sx={{ pt: 0 }}>
      <Box
        sx={{
          mt: "16px",
          mb: "26px",
          ml: 4,
        }}
      >
        <QuoteDetailsHeader
          hashId={hashedId}
          status={quoteData.status}
        ></QuoteDetailsHeader>
      </Box>
      <Box mt={2} ml={4} mr={4}>
        <Grid container spacing={2}>
          <Grid container direction="column" size={{ xs: 12, sm: 12, md: 5 }}>
            <AccountDetails quote={quoteData} />
            {/* <PricingCombined /> */}
            {/* <PricingBreakdown /> */}
            {/* <RecommendedPricing /> */}

            <PricingBreakdown />

            {/* <RecommendedPricing /> */}
          </Grid>
          <Grid container direction="column" size={{ xs: 12, sm: 12, md: 7 }}>
            <QuoteLaneInsights />
            <QuoteCustomerInsights />

            {ldFlags["quote-details-show-similar-quotes-insight"] && (
              <InsightLists />
            )}
            <MarketPricingV2 quote={quoteData} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default QuoteDetailsLayout;
