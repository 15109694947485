import { Alert, Grid, Paper, Snackbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ZipCodeLookup from "components/global/common/ZipCodeLookup/ZipCodeLookup";
import { setIn } from "final-form";
import { DateTime } from "luxon";
import { Autocomplete, Checkboxes, DatePicker, TimePicker } from "mui-rff";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import {
  ListItemDto,
  QuoteRequestDto,
  RequestQuoteOptionsDto,
} from "../../../api/PricingPlatformApi.generated";
import {
  RequestQuoteForm,
  RequestQuoteInitialValues,
} from "../../../utilities/form-initial-values/RequestQuoteInitialValues";
import { RequestQuoteValidationSchema } from "../../../utilities/form-validation/RequestQuoteValidationSchema";
import AdditionalDetails from "./AdditionalDetails";
import EquipmentDetails from "./EquipmentDetails";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import RoleTypeNames from "constants/RoleConstant";
import { useUserContext } from "contexts/UserProvider";
import { useApiContext } from "contexts/ApiProvider";
import { saveQuote } from "redux/slices/Quote/quoteSlice";

const RequestQuote = () => {
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useUserContext();
  const { requestQuoteApi } = useApiContext();

  const [options, setOptions] = useState<RequestQuoteOptionsDto | undefined>(
    undefined
  );
  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const submitQuote = async (form: RequestQuoteForm) => {
    setIsLoading(true);

    const quoteRequest = new QuoteRequestDto({
      accountId: typeof form.account === "number" ? form.account : undefined,
      equipmentId:
        typeof form.equipment === "number" ? form.equipment : undefined,
      commodityId:
        typeof form.commodity === "number" ? form.commodity : undefined,
      pickupDate: form.pickupDateUnknown
        ? undefined
        : DateTime.fromJSDate(form.pickupDate ?? new Date()).toLocaleString(),
      deliveryDate: form.deliveryDate
        ? DateTime.fromJSDate(form.deliveryDate).toLocaleString()
        : undefined,
      estimatedPickupHour:
        form.estimatedPickupTime != null
          ? DateTime.fromJSDate(form.estimatedPickupTime).hour
          : undefined,
      estimatedDeliveryHour:
        form.estimatedDeliveryTime != null
          ? DateTime.fromJSDate(form.estimatedDeliveryTime).hour
          : undefined,
      estimatedPickupMinute:
        form.estimatedPickupTime != null
          ? DateTime.fromJSDate(form.estimatedPickupTime).minute
          : undefined,
      estimatedDeliveryMinute:
        form.estimatedDeliveryTime != null
          ? DateTime.fromJSDate(form.estimatedDeliveryTime).minute
          : undefined,
      referenceNumber:
        typeof form.referenceNumber === "string"
          ? form.referenceNumber
          : undefined,
      shipperName:
        typeof form.shipperName === "string" ? form.shipperName : undefined,
      receiverName:
        typeof form.receiverName === "string" ? form.receiverName : undefined,
      temperatureMax:
        typeof form.maxTemp === "string" ? form.maxTemp : undefined,
      temperatureMin:
        typeof form.minimumTemp === "string" ? form.minimumTemp : undefined,
      tarpsRequired:
        typeof form.tarpsRequirement === "boolean"
          ? form.tarpsRequirement
          : false,
      oversize:
        typeof form.oversizeTarp === "boolean" ? form.oversizeTarp : false,
      specializedServiceIds: form.specializedServices,
      originAddressZipCode: form.origin ? form.origin.zipcode : undefined,
      originAddressCity: form.origin ? form.origin.city : undefined,
      originAddressState: form.origin ? form.origin.state : undefined,
      originLat: form.origin?.lat,
      originLng: form.origin?.long,
      destinationAddressZipCode: form.destination
        ? form.destination.zipcode
        : undefined,
      destinationAddressCity: form.destination
        ? form.destination.city
        : undefined,
      destinationAddressState: form.destination
        ? form.destination.state
        : undefined,
      destinationLat: form.destination?.lat,
      destinationLng: form.destination?.long,
      isTestQuote:
        userData.userRole === RoleTypeNames.OrgAdmin ||
        userData.userRole === RoleTypeNames.Pricing
          ? true
          : false,
    });

    try {
      const response = await requestQuoteApi.requestQuote(quoteRequest);
      if (!response.hashedId && response.message) {
        throw new Error(response.message.message);
      }
      dispatch(saveQuote(response));
      navigate(`/quote/${response.hashedId}`);
    } catch (error: any) {
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(
      "getting quote options {currentUser, userData}",
      currentUser,
      userData
    );

    const getQuoteOptions = async () => {
      const data = await requestQuoteApi.getRequestQuoteOptions();
      setOptions(data);
    };

    getQuoteOptions();
  }, []);

  const validateFormValues =
    (schema: any) => async (values: RequestQuoteForm) => {
      if (typeof schema === "function") {
        schema = schema();
      }
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (err: any) {
        const errors = err.inner.reduce((formError: any, innerError: any) => {
          return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
      }
    };

  const validate = validateFormValues(RequestQuoteValidationSchema);

  return (
    <Paper variant="basePaper">
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Typography variant="h4">New Spot Quote</Typography>
        </Grid>
        <Grid item xs={12} ml={2}>
          <Form
            initialValues={RequestQuoteInitialValues}
            onSubmit={(values) => {
              submitQuote(values as RequestQuoteForm);
            }}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <>
                <form onSubmit={handleSubmit}>
                  {/* 3 containers -> 
                    container 1 is Basic Info
                    container 2 is Stops
                    container 3 is Additional Details */}

                  {/* All container parent (including button) */}
                  <Grid container style={{ minHeight: "70vh" }}>
                    {/* Container 1,2,3 parent */}
                    <Grid container direction="row">
                      {/* Container 1 and 2 Parent */}
                      <Grid item xs={8} sx={{}}>
                        {/* Container 1 - Basic Info */}
                        <Grid item container direction="column" spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Basic Information
                            </Typography>
                          </Grid>
                          <Grid item container spacing={2}>
                            <Grid item xs={6}>
                              <Autocomplete
                                id="account"
                                label="Account*"
                                name="account"
                                multiple={false}
                                required={false}
                                options={options?.accounts ?? []}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option: ListItemDto) =>
                                  option.label
                                }
                                disableCloseOnSelect={false}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.label}</li>
                                )}
                                freeSolo={false}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <EquipmentDetails
                                quoteOptions={options}
                                values={values}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Container two - Stops */}
                        <Grid
                          item
                          container
                          spacing={1}
                          direction="column"
                          mt={2}
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6">Stops</Typography>
                            <Typography sx={{ fontSize: 12 }}>
                              For most accurate pricing, enter both pickup date
                              and time.
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* Origin */}
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          spacing={1}
                          mb={3}
                          mt={0.1}
                        >
                          <Grid item xs={5}>
                            <ZipCodeLookup
                              required={true}
                              id="originInput"
                              label="Origin"
                              name="origin"
                            ></ZipCodeLookup>
                          </Grid>
                          <Grid item xs={4} container direction="column">
                            <Grid item>
                              <DatePicker
                                name="pickupDate"
                                label={
                                  values.pickupDateUnknown
                                    ? "Pickup Date"
                                    : "Pickup Date*"
                                }
                                minDate={new Date()}
                              />
                            </Grid>
                            <Grid item alignItems="center">
                              <Checkboxes
                                id="pickupDateUnknown"
                                name="pickupDateUnknown"
                                data={{
                                  label: "Pickup Date Unknown",
                                  value: "pickupDateUnknown",
                                }}
                              ></Checkboxes>
                            </Grid>
                          </Grid>

                          <Grid item xs={3}>
                            <TimePicker
                              name="estimatedPickupTime"
                              value={values.estimatedPickupTime}
                              label="Estimated Pickup Time"
                              ampm={false}
                            />
                          </Grid>
                        </Grid>

                        {/* Destination */}
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          spacing={1}
                        >
                          <Grid item xs={5}>
                            <ZipCodeLookup
                              required={true}
                              id="destinationInput"
                              label="Destination"
                              name="destination"
                            ></ZipCodeLookup>
                          </Grid>

                          <Grid item xs={4} container direction="column">
                            <Grid item>
                              <DatePicker
                                name="deliveryDate"
                                label="Delivery Date"
                                minDate={new Date()}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={3}>
                            <TimePicker
                              name="estimatedDeliveryTime"
                              value={values.estimatedDeliveryTime}
                              label="Estimated Delivery Time"
                              ampm={false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container xs={4} justifyContent="center">
                        {/* Container 3 parent */}
                        <Grid item xs={8}>
                          <AdditionalDetails
                            quoteOptions={options}
                            values={values}
                          />
                        </Grid>
                      </Grid>
                      <Snackbar
                        open={showToast}
                        autoHideDuration={15000}
                        onClose={() => {
                          setShowToast(false);
                          setErrorMessage("");
                        }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        <Alert severity="error" sx={{ width: "100%" }}>
                          {errorMessage}
                        </Alert>
                      </Snackbar>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      alignContent="end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isLoading}
                          sx={{ margin: "16px" }}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} />
                          ) : userData.userRole === RoleTypeNames.OrgAdmin ||
                            userData.userRole === RoleTypeNames.Pricing ? (
                            "CREATE TEST QUOTE"
                          ) : (
                            "CREATE QUOTE"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default RequestQuote;
