import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Stack, TextField } from "@mui/material";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import { useSelector } from "react-redux";
import { PricingOverrideActionTypes } from "redux/ActionTypes/pricingOverrideActionTypes";
import { PricingOverrideInterface } from "redux/slices/PricingOverride/interface";

interface ReadOnlyFieldProps {
  label: string;
  value: string | number | undefined;
  variant?: "body2" | "h6" | "subtitle1";
  boldLabel?: boolean;
  boldValue?: boolean;
  type?: "currency";
  valueFontSize?: number;
  valueUpdated?: any;
  editable?: boolean;
  originalValue?: string | number | undefined;
  labelColor?: string;
}

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 50,
  },
};
const ReadOnlyField = (props: ReadOnlyFieldProps) => {
  const [value, setValue] = useState(props.value);
  const handleTextFieldChange = (event: any) => {
    setValue(event.target.value);
  };
  const handleKeyPress = (event: any) => {
    if (event.keyCode == 13 || event.keyCode == 9) {
      if (event.target.value && event.target.value !== props.value) {
        props.valueUpdated(event.target.value);
      }
    }
  };
  const handleBlur = (event: any) => {
    if (event.target.value && event.target.value !== props.value) {
      props.valueUpdated(event.target.value);
    }
  };
  const pricingOverrideState: PricingOverrideInterface = useSelector(
    (state: any) => state.pricingOverrideReducer,
  );
  useEffect(() => {
    if (
      pricingOverrideState?.type ==
        PricingOverrideActionTypes.ResetPricingOverride &&
      !pricingOverrideState?.quote?.pricingOverrideDto
    ) {
      setValue(
        pricingOverrideState?.quote?.projectedCost?.companyRate?.targetBuyRate?.toFixed(
          2,
        ) || "0",
      );
    }
  }, [pricingOverrideState]);
  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography
          sx={{
            color: props.labelColor ?? "#00000099",
            fontWeight: props.boldLabel ? 600 : 0,
          }}
          variant={props.variant || "body2"}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid item>
        {props.editable ? (
          <Stack direction="row">
            {" "}
            <TextField
              id="totalBuyRateInput"
              value={value}
              variant="outlined"
              fullWidth
              inputProps={{
                style: {
                  fontWeight: props.boldValue ? 600 : 0,
                  fontSize: props.valueFontSize,
                  padding: 0,
                  paddingLeft: 5,
                },
              }}
              onChange={handleTextFieldChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
            />
            {props.originalValue &&
            Number(props.originalValue).toFixed(2) !== props.value ? (
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.40)",
                  fontSize: "14px",
                  fontWeight: 600,
                  margin: "30px 0 0 6px",
                  textDecoration: "line-through",
                }}
                variant="body2"
              >
                {formatCurrency(props.originalValue)}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
        ) : (
          <Typography
            sx={{
              fontWeight: props.boldValue ? 600 : 0,
              fontSize: props.valueFontSize,
            }}
            variant={props.variant || "body2"}
          >
            {(props.type === "currency"
              ? formatCurrency(props.value)
              : props.value) ?? " - "}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ReadOnlyField;
