import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { QuoteResponseDto, StopDto } from "api/PricingPlatformApi.generated";
import StopSummary from "components/pages/quote-details/StopSummary";
import React from "react";
import ArrowCircleDown from "../../../icons/ArrowCircleDown";
import ArrowCircleUp from "../../../icons/ArrowCircleUp";
import ExpandLess from "../../../icons/ExpandLess";
import ExpandMore from "../../../icons/ExpandMore";
import { formatDateV1 } from "../../../utilities/functions/FormatDate";
import AdditionalQuoteDetails from "./AdditionalQuoteDetails";

interface QuoteSummaryProps {
  quote: QuoteResponseDto;
}

const QuoteSummary = (props: QuoteSummaryProps) => {
  const { quote } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const getOrigin = (): StopDto | null => {
    if (quote.stops) {
      return quote.stops[0];
    }
    return null;
  };
  const getDestination = (): StopDto | null => {
    if (quote.stops) {
      return quote.stops[1];
    }
    return null;
  };
  return (
    <Box
      sx={{
        borderBottom: "2px solid #ccc ",
        borderRight: ".5px solid #ccc ",
        borderLeft: ".5px solid #ccc ",
        width: "100%",
        marginBottom: "6px",
      }}
    >
      <Grid container>
        <Grid container spacing={2} sx={{ margin: "2px" }}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h5">Quote Summary</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            size={{ xs: 12 }}
            justifyContent="space-between"
            mx={2}
          >
            <Grid container direction="column" size={{ xs: 4 }} spacing={1}>
              <Grid>
                <Typography variant="body2" sx={{ fontSize: 12 }}>
                  Account
                </Typography>
              </Grid>
              <Grid>
                <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                  {quote?.account?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
              <Grid>
                <Typography variant="body2" sx={{ fontSize: 12 }}>
                  Origin
                </Typography>
              </Grid>
              <Grid container>
                <Grid mr={1}>
                  <ArrowCircleUp color="secondary" />
                </Grid>
                <Grid>
                  {getOrigin() ? <StopSummary stop={getOrigin()} /> : ""}
                  <Typography sx={{ fontSize: 12 }}>
                    {quote?.pickupDate ? (
                      formatDateV1(quote?.pickupDate)
                    ) : (
                      <></>
                    )}{" "}
                    {quote?.estimatedPickupHour ? (
                      quote?.estimatedPickupHour.toString().padStart(2, "0") +
                      ":" +
                      (quote.estimatedPickupMinute
                        ? quote.estimatedPickupMinute
                            .toString()
                            .padStart(2, "0")
                        : "00")
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
              <Grid>
                <Typography variant="body2" sx={{ fontSize: 12 }}>
                  Destination
                </Typography>
              </Grid>
              <Grid container>
                <Grid mr={1}>
                  <ArrowCircleDown color="secondary" />
                </Grid>
                <Grid>
                  {getDestination() ? (
                    <StopSummary stop={getDestination()} />
                  ) : (
                    ""
                  )}
                  <Typography sx={{ fontSize: 12 }}>
                    {quote?.deliveryDate ? (
                      formatDateV1(quote?.deliveryDate)
                    ) : (
                      <></>
                    )}{" "}
                    {quote?.estimatedDeliveryHour ? (
                      quote?.estimatedDeliveryHour.toString().padStart(2, "0") +
                      ":" +
                      (quote.estimatedDeliveryMinute
                        ? quote.estimatedDeliveryMinute
                            .toString()
                            .padStart(2, "0")
                        : "00")
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column" size={{ xs: 2 }} spacing={1}>
              <Grid>
                <Typography variant="body2" sx={{ fontSize: 12 }}>
                  Equipment
                </Typography>
              </Grid>
              <Grid>
                <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                  {quote?.equipmentType?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container size={{ xs: 12 }} justifyContent="flex-end"></Grid>
        </Grid>
        <Grid
          container
          size={{ xs: 12 }}
          justifyContent="flex-end"
          sx={{ margin: "6px 16px" }}
        >
          {expanded && (
            <Grid size={{ xs: 12 }}>
              <AdditionalQuoteDetails quote={quote} />
            </Grid>
          )}
          <Typography
            variant="button"
            color="secondary"
            onClick={handleChange}
            style={{ cursor: "pointer", fontSize: 12 }}
          >
            {expanded ? "HIDE ADDITIONAL DETAILS" : "VIEW ADDITIONAL DETAILS"}
          </Typography>
          {expanded ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuoteSummary;
