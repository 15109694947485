import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PricingOverrideInterface } from "./interface";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";

const initialState: PricingOverrideInterface = {
    quote: undefined,
    type: undefined
};

const pricingOverrideSlice = createSlice({
    name: "pricingOverride",
    initialState,
    reducers: {
        quoteLoaded: (state, action: PayloadAction<QuoteResponseDto>) => {
            state.quote = action.payload;
            state.type = action.type;
        },
        resetPricingOverride: (state, action: PayloadAction<QuoteResponseDto>) => {
            state.quote = action.payload;
            state.type = action.type;
        },
        pricingOverrideSuccess: (state, action: PayloadAction<QuoteResponseDto>) => {
            state.quote = action.payload;
            state.type = action.type;
        },
        pricingMarkupChange: (state, action: PayloadAction<QuoteResponseDto>) => {
            state.quote = action.payload;
            state.type = action.type;
        },
    }
});

export const { quoteLoaded, resetPricingOverride, pricingOverrideSuccess, pricingMarkupChange} = pricingOverrideSlice.actions;

export default pricingOverrideSlice.reducer;