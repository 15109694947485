import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { formatDateTime } from "../../../utilities/functions/FormatDate";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import { Stack } from "@mui/material";

interface AdditionalQuoteDetailsProps {
  quote: QuoteResponseDto;
}

const AdditionalQuoteDetails = (props: AdditionalQuoteDetailsProps) => {
  const { quote } = props;

  return (
    <Grid container direction="column">
      <Grid
        size={{ xs: 12 }}
        container
        direction="row"
        sx={{ borderTop: "1px solid #ccc" }}
      >
        <Grid
          size={{ xs: 3 }}
          container
          direction="column"
          spacing={1}
          my={2}
          px={2}
        >
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Shipper
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {" "}
                {quote?.shipperName ? quote?.shipperName : <></>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Receiver
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {" "}
                {quote?.receiverName ? quote?.receiverName : <></>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Reference Number
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {quote?.referenceNumber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          size={{ xs: 6 }}
          spacing={1}
          my={2}
          pl={10}
          sx={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc" }}
        >
          <Grid container direction="column" spacing={2} size={{ xs: 4 }}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Region
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {quote?.regionName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2} size={{ xs: 4 }}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Lead Time
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {quote?.pickupDate
                  ? `${quote?.leadTimeInHours} hours`
                  : "Pickup Date Unknown"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2} size={{ xs: 4 }}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Mileage
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {" "}
                {quote?.mileage ? `${quote?.mileage} mi` : <></>}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          size={{ xs: 3 }}
          container
          direction="column"
          spacing={1}
          my={2}
          pl={2}
        >
          {quote?.equipmentType?.value === 2 &&
            quote?.temperatureMin &&
            quote?.temperatureMax && (
              <Grid container direction="column" spacing={2}>
                <Grid>
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Temperature Range
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {quote?.temperatureMin} - {quote?.temperatureMax} F
                  </Typography>
                </Grid>
              </Grid>
            )}

          {quote?.equipmentType?.value === 3 && (
            <Grid container direction="column" spacing={2}>
              <Grid>
                <Typography variant="body2" sx={{ fontSize: 12 }}>
                  Flatbed Options
                </Typography>
              </Grid>
              {quote?.oversize && (
                <Grid>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    Oversize/
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    Overdimensional
                  </Typography>
                </Grid>
              )}
              {quote?.tarpsRequired && (
                <Grid>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    Tarps Required
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Commodity
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {" "}
                {quote?.commodity?.label ? quote?.commodity?.label : <></>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Specialized Services
              </Typography>
            </Grid>
            {quote?.specializedServices?.map((s: any) => {
              return (
                <Grid key={s?.value}>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {s?.label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }} sx={{ borderTop: "1px solid #ccc" }}>
        <Grid
          container
          direction="row"
          size={{ xs: 12 }}
          justifyContent="space-between"
          px={2}
          mt={3}
        >
          <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Team
              </Typography>
            </Grid>
            <Grid>{/*Team*/}</Grid>
          </Grid>
          <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Office
              </Typography>
            </Grid>
            <Grid container>
              <Grid>
                <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                  {quote.account?.offices?.length === 1
                    ? quote?.account?.offices[0].name
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Created By
              </Typography>
            </Grid>
            <Grid container>
              <Grid>
                <Stack direction="column">
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {quote?.createdBy?.email}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {formatDateTime(quote?.createdOnUtc, "custom")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" size={{ xs: 3 }} spacing={1}>
            <Grid>
              <Typography variant="body2" sx={{ fontSize: 12 }}>
                Override Updated By
              </Typography>
            </Grid>
            <Grid>
              {quote?.overrideUpdatedOnUtc && (
                <Stack direction="column">
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {quote?.overrideUpdatedBy?.email}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {formatDateTime(quote?.overrideUpdatedOnUtc, "custom")}
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalQuoteDetails;
