import * as yup from "yup";

export const RequestQuoteValidationSchema = yup.object().shape({
  origin: yup.object().required("Required"),
  destination: yup.object().required("Required"),
  account: yup.string().required("Required"),
  pickupDateUnknown: yup.boolean(),
  pickupDate: yup
    .date()
    .nullable()
    .test("conditional-validation", "Required", function (value) {
      const pickupDateUnknown = this.parent.pickupDateUnknown;
      if (!pickupDateUnknown) {
        return !!value;
      }
      return true;
    }),
  deliveryDate: yup
    .date()
    .nullable()
    .min(
      yup.ref("pickupDate"),
      "Delivery date must be after or equal to pick up date",
    ),
  equipment: yup.string().required("Required"),

  // MPP-277 - Make Temperature Range optional

  //minimumTemp: yup.string().test('conditional-validation', 'Required', function (value) {
  //    const equipment = this.parent.equipment;
  //    if (equipment === "2") {

  //        return !!value
  //    }
  //    return true;
  //})
  //    .matches(/^[0-9\-.]*$/, 'Must contain only numeric digits')
  //,
  //maxTemp: yup.string().test('conditional-validation', 'Required', function (value) {
  //    const equipment = this.parent.equipment;
  //    if (equipment === "2" ) {

  //        return !!value
  //    }
  //    return true;
  //})
  //    .matches(/^[0-9\-.]*$/, 'Must contain only numeric digits')
  //,
  //estimatedPickupTime: yup.date().nullable().test('2-business-day', 'Estimated pickup time is required within 2 business days', function (value) {
  //    const pickupDate = this.parent.pickupDate;
  //    const numberOfBusinessDays = 1;
  //    if (pickupDate) {
  //        const targetDate = calculateBusinessDays(pickupDate, numberOfBusinessDays);
  //        if (targetDate) {
  //            return !!value;
  //        }
  //    }
  //    return true;
  //}),
});
