import React, { useEffect } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Box,
  Stack,
  Divider,
  Typography,
  Skeleton,
  Accordion,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PricingBreakdown from "../quote-details-2/PricingBreakdown";
import RecommendedPricing from "../quote-details-2/RecommendedPricing";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  MarketPricingDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";

interface MarketPricingProps {
  quote: QuoteResponseDto;
  pricing: MarketPricingDto;
}

const PricingGreenscreensNetwork = (props: MarketPricingProps) => {
  useEffect(() => {}, [props.quote]);

  return (
    <Grid container>
      <Typography>Placeholder</Typography>
    </Grid>
  );
};

export default PricingGreenscreensNetwork;
