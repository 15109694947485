enum RoleTypeNames {
  SystemAdmin = "SystemAdmin",
  OrgAdmin = "Org Admin",
  Pricing = "Pricing",
  CompanyStore = "CompanyStore",
  Agent = "Agent",
  PublicAPI = "Public API",
}

export enum RoleTypeIds {
  SystemAdmin = 1,
  Pricing = 2,
  CompanyStore = 3,
  Agent = 4,
  OrgAdmin = 5,
  PublicAPI = 6,
}

export const TenantAdminRoles = [RoleTypeNames.SystemAdmin];
export const OrgAdminRoles = [
  RoleTypeNames.SystemAdmin,
  RoleTypeNames.OrgAdmin,
];
export const SocketAccessRoles = [
  RoleTypeNames.SystemAdmin,
  RoleTypeNames.OrgAdmin,
  RoleTypeNames.Pricing,
];

export const SettingsAccessRoles = [
  RoleTypeNames.SystemAdmin,
  RoleTypeNames.OrgAdmin,
  RoleTypeNames.Pricing,
];

export default RoleTypeNames;
