import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GreenScreensProjectedCostDto } from "api/PricingPlatformApi.generated";
import ConfidenceLevelIndicator from "components/global/common/ConfidenceLevelIndicator/ConfidenceLevelIndicator";
import ReadOnlyField from "components/global/common/ReadOnlyField/ReadOnlyField";
import GreenScreensLogo from "icons/GreenScreensLogo";

interface GreenscreensNetworkRateProps {
  networkRate: GreenScreensProjectedCostDto | undefined;
}

const GreenscreensNetworkRate = (props: GreenscreensNetworkRateProps) => {
  const { networkRate } = props;

  return (
    <>
      <Stack direction="row" width={400} spacing={1}>
        <ReadOnlyField
          boldLabel={false}
          boldValue={true}
          type="currency"
          variant="subtitle1"
          label="Target Buy Rate"
          valueFontSize={34}
          value={networkRate?.targetBuyRate}
        ></ReadOnlyField>
        <ConfidenceLevelIndicator
          boldValue={true}
          variant="subtitle1"
          label="Confidence Level"
          valueFontSize={44}
          value={networkRate?.confidenceLevel ?? 0}
        ></ConfidenceLevelIndicator>
      </Stack>

      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyField
            boldLabel={false}
            label="Low"
            type="currency"
            value={networkRate?.lowBuyRate}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            boldLabel={false}
            label="High"
            type="currency"
            value={networkRate?.highBuyRate}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <Typography variant="body2">
            <GreenScreensLogo />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GreenscreensNetworkRate;
