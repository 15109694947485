import React from "react";
import Typography from "@mui/material/Typography";
import {
  GreenScreensProjectedCostDto,
  OverridePricingRequestDto,
  PricingOverrideDto,
  UserDto,
} from "api/PricingPlatformApi.generated";
import GreenScreensLogo from "icons/GreenScreensLogo";
import { Grid, Stack } from "@mui/material";
import ReadOnlyField from "components/global/common/ReadOnlyField/ReadOnlyField";
import ConfidenceLevelIndicator from "components/global/common/ConfidenceLevelIndicator/ConfidenceLevelIndicator";
import { useSelector } from "react-redux";
import RoleTypeNames from "constants/RoleConstant";
import PricingOverrideTypes from "constants/PricingOverrideType";
import { roundNumber } from "utilities/functions/FormatNumber";

interface ProjectedCostProps {
  projectedCost?: GreenScreensProjectedCostDto;
  pricingOverrideDto?: PricingOverrideDto;
  onPricingOverride?: any;
  quoteCreatedByUser?: UserDto;
}

const ProjectedCost = (props: ProjectedCostProps) => {
  const userData = useSelector((state: any) => state.userRequestReducer.user);
  const handleBuyRateOverride = (newBuyRate: number) => {
    var overridePricingRequestDto = new OverridePricingRequestDto();
    overridePricingRequestDto.overrideType = PricingOverrideTypes.TargetBuyRate;
    overridePricingRequestDto.overrideValue = newBuyRate;
    props.onPricingOverride(overridePricingRequestDto);
  };
  const canOverrideBuyRate =
    userData.userRole === RoleTypeNames.OrgAdmin ||
    userData.userRole === RoleTypeNames.Pricing ||
    (userData.userRole === RoleTypeNames.CompanyStore && userData.isAdmin) ||
    (userData.userRole === RoleTypeNames.Agent && userData.isAdmin) ||
    userData.id === props.quoteCreatedByUser?.id;

  const canEdit = () => {
    return false;
    // return (
    //   canOverrideBuyRate &&
    //   (props.pricingOverrideDto == null ||
    //     props.pricingOverrideDto.overrideType ===
    //       PricingOverrideTypes.TargetBuyRate)
    // );
  };

  return (
    <Stack spacing={1} pb={2}>
      <Stack direction="row" alignItems="flex-end">
        {" "}
        <Typography variant="h6">Projected Cost</Typography>
        <Typography style={{ fontSize: ".75rem" }} ml={1}>
          powered by
        </Typography>{" "}
        <GreenScreensLogo />
      </Stack>

      <Stack direction="row" width={400} spacing={1} pl={2}>
        <ReadOnlyField
          boldValue={true}
          type="currency"
          variant="subtitle1"
          label={
            props.pricingOverrideDto?.overrideType ===
            PricingOverrideTypes.TargetBuyRate
              ? "Override Buy Rate"
              : "Target Buy Rate"
          }
          valueFontSize={34}
          editable={canEdit()}
          valueUpdated={handleBuyRateOverride}
          originalValue={
            props.pricingOverrideDto?.overrideType ===
            PricingOverrideTypes.TargetBuyRate
              ? props.projectedCost?.targetBuyRate
              : ""
          }
          value={Number(
            props.pricingOverrideDto?.overrideType ===
              PricingOverrideTypes.TargetBuyRate
              ? props.pricingOverrideDto?.overrideValue
              : props.projectedCost?.targetBuyRate
          ).toFixed(2)}
          labelColor={
            props.pricingOverrideDto?.overrideType ===
            PricingOverrideTypes.TargetBuyRate
              ? "#ed6c03"
              : "#00000099"
          }
        ></ReadOnlyField>

        <ConfidenceLevelIndicator
          boldValue={true}
          variant="subtitle1"
          label="Confidence Level"
          valueFontSize={44}
          disabled={
            props.pricingOverrideDto?.overrideType ===
            PricingOverrideTypes.TargetBuyRate
          }
          value={props.projectedCost?.confidenceLevel ?? 0}
        ></ConfidenceLevelIndicator>
      </Stack>

      <Grid container pl={2}>
        <Grid item xs={3}>
          <ReadOnlyField
            label="Low"
            type="currency"
            value={props.projectedCost?.lowBuyRate}
          ></ReadOnlyField>
        </Grid>

        <Grid item xs={3}>
          <ReadOnlyField
            label="High"
            type="currency"
            value={props.projectedCost?.highBuyRate}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            type="currency"
            label="Fuel"
            value={props.projectedCost?.fuelRate}
          ></ReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            label="Mileage"
            value={
              !!props.projectedCost?.mileage
                ? roundNumber(props.projectedCost?.mileage, 2)
                : ""
            }
          ></ReadOnlyField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProjectedCost;
