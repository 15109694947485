import * as yup from "yup";

export const CreateUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().required("Required"),
  userRoleId: yup.number().required("Required"),
});
export const CreateAPIUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().required("Required"),
  accountId: yup.number().required("Required"),
});
