import React from "react";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { Item } from "api/PricingPlatformApi.generated";
import { DateTime } from "luxon";

// Define an interface for your props

const HistoricalLoadTable = ({ data }: { data: Item[] }) => {
  const getRowId = (row: { loadProNumber: string }) => row.loadProNumber;
  const columns: GridColDef[] = [
    {
      field: "loadProNumber",
      flex: 0.5,
      renderHeader: (params: GridColumnHeaderParams) => <strong>Load #</strong>,
    },
    {
      field: "origin",
      renderHeader: (params: GridColumnHeaderParams) => <strong>Origin</strong>,
      flex: 0.5,
      valueGetter: (value, row) => `${row.originCity}, ${row.originState}`,
    },
    {
      field: "loadPickupDate",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Pickup Date</strong>
      ),
      flex: 0.25,
    },
    {
      field: "destination",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Destination</strong>
      ),
      flex: 0.5,
      valueGetter: (value, row) =>
        `${row.destinationCity}, ${row.destinationState}`,
    },
    {
      field: "deliveredDateTime",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Delivery Date</strong>
      ),
      valueGetter: (value, row) =>
        DateTime.fromISO(row.deliveredDateTime).toISODate(),
      flex: 0.25,
    },
    {
      field: "distance",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Mileage</strong>
      ),
      valueGetter: (value, row) => Number.parseFloat(row.distance).toFixed(2),
      type: "number",
      flex: 0.4,
    },
    {
      field: "totalCarrierCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>Total Carrier Cost</strong>
      ),
      valueGetter: (value, row) =>
        `$${Number.parseFloat(row.totalCarrierCost).toFixed(2)}`,
      type: "string",
      flex: 0.4,
    },
  ];

  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        rows={data as any}
        columns={columns}
        getRowId={getRowId}
        // getRowId={(row) => row.hashedId}
        disableRowSelectionOnClick
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        sx={{
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
            {
              marginTop: "1em",
              marginBottom: "1em",
            },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default HistoricalLoadTable;
