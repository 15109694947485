import React from "react";

const DATLogo = (props: any) => {
  return (
    <svg
      width="200"
      height="40"
      viewBox="0 0 825 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_193_188)">
        <path d="M300.986 0H160.986V140H300.986V0Z" fill="#0056FF" />
        <path d="M140 0H0V140H140V0Z" fill="#0056FF" />
        <path d="M461.972 0H321.972V140H461.972V0Z" fill="#0056FF" />
        <path
          d="M490.277 9.06578C490.277 14.0559 486.258 18.0757 481.295 18.0757C476.333 18.0757 472.313 14.0559 472.313 9.06578C472.313 4.07568 476.333 0.111328 481.295 0.111328C486.258 0.111328 490.277 4.13113 490.277 9.06578V9.06578ZM488.697 9.06578C488.697 5.01826 485.37 1.6638 481.267 1.6638C477.164 1.6638 473.838 4.99054 473.838 9.06578C473.838 13.141 477.164 16.4955 481.267 16.4955C485.37 16.4955 488.697 13.1688 488.697 9.06578ZM485.121 7.54103C485.121 8.844 484.483 9.75885 483.319 10.147L485.315 13.4183H483.042L481.24 10.341H479.632V13.4183H477.663V4.76875H481.961C483.957 4.76875 485.121 5.82222 485.121 7.54103V7.54103ZM481.6 8.78856C482.57 8.78856 483.069 8.31727 483.069 7.54103C483.069 6.76479 482.57 6.32123 481.6 6.32123H479.66V8.78856H481.6Z"
          fill="#0056FF"
        />
        <path
          d="M350.249 24.9785H433.861V45.632H404.558V114.551H379.58V45.632H350.249V24.9785V24.9785ZM64.9544 24.9785H29.7188V114.579H64.9544C89.9326 114.579 110.281 94.4795 110.281 69.7785C110.281 45.0775 89.9603 24.9785 64.9544 24.9785V24.9785ZM60.7405 46.3805C75.4059 46.3805 84.5267 55.3349 84.5267 69.7785C84.5267 84.2221 75.5445 93.2043 60.491 93.2043H54.4752V46.3805H60.7405V46.3805ZM281.968 114.579L244.265 24.9785H217.568L179.865 114.579H204.317L210.416 100.052H251.418L257.517 114.579H281.941H281.968ZM242.713 81.2557H219.121L230.931 53.228L242.713 81.2557Z"
          fill="white"
        />
        <path
          d="M520.273 39.7266H540.788V46.9622H520.273V63.1246H510.099V22.5107H541.841V30.0236H520.245V39.6989L520.273 39.7266Z"
          fill="white"
        />
        <path
          d="M554.4 37.6468H554.649C556.396 34.4032 559.501 32.2686 564.242 32.2686C564.935 32.2686 565.655 32.3517 566.154 32.4903V40.8626C565.406 40.7794 564.02 40.6408 562.772 40.6408C558.226 40.6408 554.816 42.4428 554.816 48.3478V63.124H545.362V32.9616H554.428V37.6468H554.4Z"
          fill="white"
        />
        <path
          d="M598.784 54.0864C597.647 58.328 593.267 63.8171 582.899 63.8171C572.53 63.8171 566.598 57.7735 566.598 48.0983C566.598 38.423 572.641 32.2686 582.871 32.2686C593.101 32.2686 599.865 38.3676 599.089 49.9002H576.273C576.356 54.6686 578.934 57.7458 582.899 57.7458C586.863 57.7458 588.582 55.3616 589.109 53.4765L598.784 54.0864V54.0864ZM576.356 44.4389H589.414C589.192 40.9181 586.891 38.2012 582.871 38.2012C578.851 38.2012 576.578 40.9181 576.356 44.4389Z"
          fill="white"
        />
        <path
          d="M611.925 21.9561V29.0254H602.471V21.9561H611.925V21.9561ZM611.925 32.9897V63.1244H602.471V32.962H611.925V32.9897Z"
          fill="white"
        />
        <path
          d="M647.631 60.7676C647.631 70.8864 640.507 74.2963 631.441 74.2963C623.679 74.2963 617.413 71.4686 615.445 64.2884L624.871 63.4567C625.841 66.4785 627.976 67.7814 631.441 67.7814C635.433 67.7814 638.178 65.8408 638.178 60.5735V57.6626H637.928C636.071 60.1854 633.021 61.5438 628.586 61.5438C621.045 61.5438 615.279 55.9715 615.279 46.8785C615.279 37.7854 621.017 32.2686 628.808 32.2686C632.716 32.2686 636.293 33.4052 638.316 36.6765H638.566V32.9893H647.631V60.7953V60.7676ZM638.649 46.8785C638.649 42.027 635.932 39.0329 631.802 39.0329C627.671 39.0329 624.926 42.027 624.926 46.8785C624.926 51.7299 627.615 54.8903 631.802 54.8903C635.988 54.8903 638.649 51.8408 638.649 46.8785Z"
          fill="white"
        />
        <path
          d="M661.493 37.0095C663.545 34.3204 666.677 32.2412 671.362 32.2412C677.378 32.2412 682.23 35.6788 682.23 43.3303V63.0966H672.776V45.5204C672.776 41.0848 670.836 39.4491 667.398 39.4491C664.238 39.4491 661.244 42.0273 661.244 47.0174V63.0966H651.79V21.9561H661.244V37.0095H661.493Z"
          fill="white"
        />
        <path
          d="M704.657 56.3875V63.0964C703.493 63.4845 700.998 63.8172 698.614 63.8172C691.849 63.8172 688.911 62.0984 688.911 55.833V38.8112H683.976V32.9617H688.911V24.6172H698.364V32.9617H704.796V38.8112H698.364V53.8924C698.364 56.2489 699.362 56.7202 701.608 56.7202C702.938 56.7202 703.992 56.5538 704.657 56.3875V56.3875Z"
          fill="white"
        />
        <path
          d="M537.849 114.495C534.329 117.267 529.893 118.792 525.18 118.792C516.503 118.792 508.408 114.994 508.408 106.788C508.408 101.354 511.457 98.1384 516.863 95.6434L518.277 95.0057C514.784 91.18 513.814 88.879 513.814 86.2176C513.814 80.6731 519.303 76.5146 526.954 76.5146C534.606 76.5146 540.095 80.6731 540.095 86.2176C540.095 90.4315 536.879 92.7602 532.887 94.8394L530.42 96.1424L536.907 102.546C537.461 100.717 537.766 98.7483 537.766 96.8077H545.806C545.806 101.327 544.752 105.263 542.95 108.507L552.57 118.016H541.426L537.877 114.523L537.849 114.495ZM525.18 112.055C528.174 112.055 530.669 111.029 532.637 109.338L523.156 99.9404L522.602 100.245C519.469 101.881 517.778 103.877 517.778 106.538C517.778 109.837 520.523 112.083 525.208 112.083L525.18 112.055ZM522.602 86.5226C522.602 87.9919 523.544 89.2394 525.79 91.5404L526.982 90.9859C529.727 89.7107 531.279 88.574 531.279 86.5226C531.279 84.6651 529.893 82.6137 526.927 82.6137C523.96 82.6137 522.574 84.6651 522.574 86.5226H522.602Z"
          fill="white"
        />
        <path
          d="M576.828 108.894L573.667 117.988H562.966L578.463 77.4014H591.964L607.461 117.988H596.622L593.434 108.894H576.828ZM585.2 85.4687H584.95L579.267 101.853H590.966L585.2 85.4687V85.4687Z"
          fill="white"
        />
        <path
          d="M618.855 91.9002C620.852 89.211 624.04 87.1318 628.919 87.1318C635.157 87.1318 640.008 90.5695 640.008 98.2209V117.987H630.555V100.411C630.555 95.9754 628.614 94.3398 625.176 94.3398C621.96 94.3398 619.022 96.6685 619.022 101.659V117.987H609.568V87.8526H618.634V91.9002H618.883H618.855Z"
          fill="white"
        />
        <path
          d="M674.717 111.501V117.988C673.913 118.21 672.416 118.431 670.614 118.431C666.677 118.431 664.376 117.433 663.323 114.966H663.073C661.132 117.378 657.473 118.709 653.564 118.709C647.659 118.709 642.697 115.41 642.697 109.81C642.697 103.35 647.16 100.938 655.144 99.9403C661.188 99.1641 662.74 98.3602 662.74 96.5859C662.74 94.5344 661.16 93.2869 658.194 93.2869C655.588 93.2869 653.481 94.7007 652.649 97.473L643.861 96.4473C645.192 90.5701 650.709 87.1602 658.36 87.1602C667.841 87.1602 672.222 91.0968 672.222 97.1958V109.976C672.222 111.445 672.721 111.75 673.58 111.75C673.913 111.75 674.412 111.667 674.717 111.556V111.501ZM662.74 103.489C661.798 104.182 659.635 104.57 657.057 104.958C654.313 105.429 652.344 106.732 652.344 109.227C652.344 111.418 653.869 112.776 656.226 112.776C658.998 112.776 662.74 110.946 662.74 106.344V103.489Z"
          fill="white"
        />
        <path
          d="M686.443 76.8193V117.988H676.99V76.8193H686.443Z"
          fill="white"
        />
        <path
          d="M711.671 117.988C707.956 127.912 703.77 129.16 698.032 129.16C695.758 129.16 693.679 129.021 692.376 128.827V121.536C693.374 121.702 694.649 121.869 696.368 121.869C699.168 121.869 700.249 121.12 701.247 118.653L701.608 117.794L688.966 87.8252H699.251L706.348 107.342H706.598L712.863 87.8252H723.01L711.699 117.96L711.671 117.988Z"
          fill="white"
        />
        <path
          d="M744.051 111.279V117.988C742.887 118.376 740.392 118.709 738.008 118.709C731.243 118.709 728.305 116.99 728.305 110.725V93.7028H723.37V87.8533H728.305V79.5088H737.758V87.8533H744.19V93.7028H737.758V108.784C737.758 111.14 738.756 111.612 741.002 111.612C742.333 111.612 743.386 111.445 744.051 111.279V111.279Z"
          fill="white"
        />
        <path
          d="M756.998 76.8193V83.8886H747.544V76.8193H756.998ZM756.998 87.853V117.988H747.544V87.853H756.998V87.853Z"
          fill="white"
        />
        <path
          d="M782.808 106.594L792.428 107.536C791.041 112.97 786.744 118.709 776.542 118.709C766.34 118.709 760.297 112.693 760.297 102.962C760.297 93.2314 766.396 87.1602 776.542 87.1602C786.689 87.1602 790.792 93.0097 792.039 97.1404L782.697 98.8037C781.976 96.4196 780.34 93.7582 776.542 93.7582C772.744 93.7582 769.944 96.6691 769.944 102.962C769.944 109.255 772.55 112.138 776.542 112.138C780.534 112.138 782.142 109.422 782.835 106.594H782.808Z"
          fill="white"
        />
        <path
          d="M814.578 97.0295C814.023 94.3681 811.833 92.871 808.95 92.871C806.233 92.871 804.293 93.98 804.293 96.0314C804.293 97.972 806.372 98.5819 809.532 99.081L812.831 99.58C818.598 100.495 824.281 102.103 824.281 109.006C824.281 115.909 817.766 118.764 809.2 118.764C800.633 118.764 794.812 115.326 793.73 109.255L802.629 108.229C803.267 111.445 805.651 113.026 809.283 113.026C812.527 113.026 814.633 111.667 814.633 109.422C814.633 107.564 812.471 106.732 809.033 106.178L805.429 105.624C800.301 104.847 794.839 103.073 794.839 96.5305C794.839 90.4592 801.465 87.1602 808.895 87.1602C816.324 87.1602 822.257 90.4592 823.338 96.0869L814.606 97.0295H814.578Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_193_188">
          <rect width="824.253" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DATLogo;
