import { Grid, Paper, Typography } from "@mui/material";
import BatchQuoteToolBar from "./BatchQuoteToolBar";
import BatchHistoryTable from "./BatchHistoryTable";
import BatchQuoteBar from "./BatchQuoteBar";
import { BatchQuoteHistoryDto } from "api/PricingPlatformApi.generated";
import { useEffect, useRef, useState } from "react";
import { useApiContext } from "contexts/ApiProvider";

const BatchQuote = () => {
  const [currentBatchStatus, setCurrentBatchStatus] =
    useState<BatchQuoteHistoryDto | null>(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const intervalIdRef: { current: NodeJS.Timer | null } = useRef(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const { batchQuoteApi } = useApiContext();

  const handleBatchCreated = (responseData: BatchQuoteHistoryDto) => {
    // Handle the response data received from the child component
    setCurrentBatchStatus(responseData);
  };

  const clearIntervalRef = () => {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current as NodeJS.Timer);
      intervalIdRef.current = null;
    }
  };
  const getBatchStatus = async () => {
    try {
      const data = await batchQuoteApi.getBatch(currentBatchStatus?.batchID);
      setCurrentBatchStatus(data);
      if (data?.status === "Completed" || data?.status === "Failed") {
        clearIntervalRef();
        setIsLoadingStatus(false);
        setRefreshTable(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (intervalIdRef.current === null) {
      if (
        currentBatchStatus?.batchID &&
        currentBatchStatus?.status !== "Completed" &&
        currentBatchStatus?.status !== "Failed"
      ) {
        getBatchStatus();
        intervalIdRef.current = setInterval(getBatchStatus, 5000);
        setIsLoadingStatus(true);
      }
    }

    return () => clearIntervalRef();
  }, [currentBatchStatus?.batchID, currentBatchStatus?.status]);

  return (
    <Paper variant="basePaper">
      <Grid container>
        <Grid container item xs={12} mb={3} spacing={3}>
          {/* Title - Batch Quote */}
          {/* Paper with New Batch title, Template, Upload, Hide instructions */}

          <Grid item xs={12}>
            <Typography variant="h4">Batch Quote</Typography>
          </Grid>

          <Grid item xs={12}>
            <BatchQuoteToolBar onBatchCreated={handleBatchCreated} />
          </Grid>

          <Grid item xs={12}>
            <BatchQuoteBar batchQuoteStatus={currentBatchStatus} />
          </Grid>

          <Grid item xs={12}>
            <BatchHistoryTable refreshTable={refreshTable} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default BatchQuote;
