import React from "react";
import Chip from "@mui/material/Chip";

export type StatusChipProps = {
  status?: "Won" | "Lost" | "Pending" | "Unknown";
};

const StatusChip = ({ status = "Unknown" }: StatusChipProps) => {
  const statusMappings = {
    Won: {
      label: "Won",
      color: "success",
    },
    Lost: {
      label: "Lost",
      color: "error",
    },
    Pending: {
      label: "Pending",
      color: "default",
    },
    Unknown: {
      label: "Unknown",
      color: "default",
    },
  };

  const { label, color } = statusMappings[status];

  return (
    <Chip
      label={label}
      color={color as "primary" | "secondary" | "default" | "success" | "error"}
      variant="outlined"
      size="small"
    />
  );
};

export default StatusChip;
