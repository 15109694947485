import { Grid, Paper, Typography } from "@mui/material";
import HistoricalLoadSearch from "./HistoricalLoadSearch";
import HistoricalLoadTable from "./HistoricalLoadTable";
import { HistoricalLoadForm } from "utilities/form-initial-values/HistoricalLoadInitialValues";

export interface HistoricalParentProps {
  readonly getHistoricalLoadDetails: (
    form: HistoricalLoadForm
  ) => Promise<void>;
  readonly loadSearchData: any[];
}

const HistoricalLoad = (props: HistoricalParentProps) => {
  return (
    <Paper
      sx={{
        padding: "20px 40px 12px 45px",
        width: "100%",
        marginTop: "10px",
        minHeight: "calc(45vh - 120px)",
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Typography variant="h4">Historical Load Search</Typography>
          <Typography variant="body1">
            Search and view similar historical loads to see data informing the
            Target Buy Rate. If no results are found, try entering a larger date
            range or region.
          </Typography>
          <HistoricalLoadSearch
            searchHistoricalLoads={props.getHistoricalLoadDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <HistoricalLoadTable data={props.loadSearchData} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default HistoricalLoad;
