import { useEffect, useState } from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Divider,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CustomerInsightsDto,
  LaneInsightsDto,
  QuoteResponseDto,
} from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import PercentIcon from "icons/svgs/PercentIcon";
import FilterIcon from "icons/svgs/FilterIcon";
import ThemeColors from "components/global/theme/theme_colors";
import OpenFolderIcon from "icons/svgs/OpenFolderIcon";
import CalendarWithDateIcon from "icons/svgs/CalendarWithDateIcon";
import CalendarDateUnknownIcon from "icons/svgs/CalendarDateUnknownIcon";

const stylingCardIcon = {
  background: "#396A6A",
  color: "white",
  borderRadius: "8px",
  padding: "5px",
  alignSelf: "center",
  width: "34px",
  height: "34px",
};

const stylingCardIconBox = {
  alignItems: "center",
  flexFlow: "row",
  p: "2px 10px",
};

const QuoteCustomerInsights = () => {
  const { quoteApi } = useApiContext();

  const { hashedId } = useParams();

  const [customerInsights, setCustomerInsights] =
    useState<CustomerInsightsDto | null>(null);
  const [range, setRange] = useState<number>(7);

  useEffect(() => {
    quoteApi.getCustomerInsights(hashedId, range).then((response) => {
      setCustomerInsights(response);
    });
  }, [quoteApi, hashedId, range]);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Grid container rowGap="12px">
        <Grid size={{ xs: 12 }} container sx={{ alignItems: "center" }}>
          <Typography variant="insightLabel" sx={{ paddingRight: "18px" }}>
            Customer Insights
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid
            container
            flexDirection="column"
            sx={{ paddingLeft: "18px", flexGrow: 1 }}
          >
            <Grid container>
              {!customerInsights && (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={240}
                />
              )}
              {customerInsights && (
                <Typography variant="insightHeader">{`${customerInsights?.customerName}`}</Typography>
              )}
            </Grid>
            <Typography variant="insightSubheader">{`Over the past ${range} days`}</Typography>
          </Grid>
          <IconButton sx={{ color: "#396A6A", px: "10px" }}>
            <FilterIcon />
          </IconButton>
          <Select
            value={range}
            size="small"
            onChange={(e) => setRange(Number(e.target.value))}
            sx={{ width: "128px" }}
          >
            <MenuItem value={7}>7 Days</MenuItem>
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={90}>90 Days</MenuItem>
            <MenuItem value={180}>180 Days</MenuItem>
          </Select>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ py: "10px" }} container>
          <Grid container size={{ xs: 3 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <CalendarDateUnknownIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!customerInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutHeader">
                  {customerInsights?.countQuotesWithoutDate}
                </Typography>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutText">
                  Quotes without date
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 3 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <CalendarWithDateIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!customerInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutHeader">
                  {customerInsights?.countQuotesWithDate}
                </Typography>
              )}

              {customerInsights && (
                <Typography variant="insightCalloutText">
                  Quotes with date
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 3 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <OpenFolderIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!customerInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutHeader">{`${customerInsights?.countPendingQuotes}`}</Typography>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutText">
                  Open pending quotes
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 3 }} sx={{ ...stylingCardIconBox }}>
            <Grid sx={{ ...stylingCardIcon }}>
              <PercentIcon width={24} height={24} />
            </Grid>
            <Grid container direction="column" sx={{ px: "8px" }}>
              {!customerInsights && (
                <Skeleton variant="rounded" width={120} height={40}></Skeleton>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutHeader">
                  {customerInsights?.winPercentage !== null
                    ? `${(Number(customerInsights?.winPercentage) * 100).toFixed(0)}%`
                    : "-"}
                </Typography>
              )}
              {customerInsights && (
                <Typography variant="insightCalloutText">
                  Win Percentage
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteCustomerInsights;
