import React, { useState } from "react";
import { formatCurrency } from "../../../utilities/functions/FormatCurrency";
import {
  QuoteResponseDto,
  StopDto,
  UserDto,
} from "api/PricingPlatformApi.generated";
import Button from "@mui/material/Button";
import { Box, Grid, Grid2 } from "@mui/material";
import { useSelector } from "react-redux";
import LinkIcon from "../../../icons/Link";

interface shareQuoteProps {
  shareQuote?: QuoteResponseDto;
  copyTextToClipboard: (textToCopy: string) => void;
  copyUrlToClipboard: () => void;
}

const ShareQuote = (props: shareQuoteProps) => {
  const { shareQuote, copyTextToClipboard, copyUrlToClipboard } = props;
  const [showLinkToast, setShowLinkToast] = useState(false);

  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );

  const getOrigin = (): StopDto | null => {
    if (shareQuote?.stops) {
      return shareQuote.stops[0];
    }
    return null;
  };
  const getDestination = (): StopDto | null => {
    if (shareQuote?.stops) {
      return shareQuote.stops[1];
    }
    return null;
  };
  const generateTextToCopy = () => {
    return `Hi [Customer Contact First Name],
    
Thank you for requesting a quote from ${userData.tenant?.name}. Please let us know if you would like to set up booking, or any additional feedback/questions you may have.


Rate: ${formatCurrency(shareQuote?.quotePricing?.targetSellRate ?? (shareQuote?.overriddenQuoteTargetSellRateDto || shareQuote?.quoteTargetSellRateDto)?.targetSellRate)}
Quote ID: ${shareQuote?.hashedId}

Origin: ${getOrigin()?.city}, ${getOrigin()?.state} ${getOrigin()?.zipCode}
Destination: ${getDestination()?.city}, ${getDestination()?.state} ${getDestination()?.zipCode}
Equipment: ${shareQuote?.equipmentType?.label}


Sincerely,
[First and Last Name]`;
  };

  return (
    <Grid2 container>
      <Grid2
        sx={{
          border: "1px solid #ccc",
          padding: "16px",
        }}
      >
        <Grid2 container flexDirection="column">
          <Grid2 size={{ xs: 12 }}>
            Hi [Customer Contact First Name],
            <br />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <br />
            Thank you for requesting a quote from {userData.tenant?.name}.
            Please let us know if you would like to set up booking, or any
            additional feedback/questions you may have.
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <br />
            Rate:{" "}
            {formatCurrency(
              (
                shareQuote?.overriddenQuoteTargetSellRateDto ||
                shareQuote?.quoteTargetSellRateDto
              )?.targetSellRate
            )}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            Quote ID: {shareQuote?.hashedId}
            <br />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <br />
            Origin: {getOrigin()?.city}, {getOrigin()?.state}{" "}
            {getOrigin()?.zipCode}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            Destination: {getDestination()?.city}, {getDestination()?.state}{" "}
            {getDestination()?.zipCode}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            Equipment: {shareQuote?.equipmentType?.label}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <br />
            Sincerely,
            <br />
            [First and Last Name]
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2
        container
        justifyContent="right"
        alignContent="center"
        mt={2}
        sx={{ width: "100%" }}
      >
        <Grid2 alignContent="center">
          <Button
            color="secondary"
            sx={{ marginRight: "16px" }}
            onClick={() => copyUrlToClipboard()}
          >
            <Box sx={{ display: "flex" }}>
              <span color="#1B4960" style={{ paddingRight: "4px" }}>
                COPY LINK
              </span>
              <LinkIcon color="#1B4960" />
            </Box>
          </Button>
        </Grid2>
        <Grid2 alignContent="center">
          <Button
            color="primary"
            variant="contained"
            onClick={() => copyTextToClipboard(generateTextToCopy())}
          >
            Copy
          </Button>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ShareQuote;
