import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import AccountCircle from "../../../icons/AccountCircle";
import Logout from "../../../icons/Logout";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import { UserDto } from "api/PricingPlatformApi.generated";
import { Badge, List, ListItem, ListItemText, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { formatCurrency } from "utilities/functions/FormatCurrency";
import { useNavigate } from "react-router-dom";
import { formatDateV1 } from "utilities/functions/FormatDate";
import ArrowCircleDown from "icons/ArrowCircleDown";
import ArrowCircleUp from "icons/ArrowCircleUp";
import { RoleTypeIds } from "constants/RoleConstant";
import TenantImpersonationDialog from "components/pages/tenants/TenantImpersonationDialog";
import SupervisedUserCircleSharpIcon from "@mui/icons-material/SupervisedUserCircleSharp";
import MetaforaBellIcon from "icons/MetaforaBellIcon";

export default function UserMenu() {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const userData = useSelector<any, UserDto>(
    (state: any) => state.userRequestReducer.user
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const userMenuOpen = Boolean(anchorEl);
  const handleUserClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserClose = () => {
    setAnchorEl(null);
  };

  const [quoteAnchorEl, setQuoteAnchorEl] = React.useState(null);
  const quoteMenuOpen = Boolean(quoteAnchorEl);
  const handleQuoteClick = (event: any) => {
    setQuoteAnchorEl(event.currentTarget);
  };
  const handleQuoteClose = () => {
    setQuoteAnchorEl(null);
  };

  const handleLogout = async () => {
    oktaAuth.signOut();
  };

  const [impersonationDialogOpen, setImpersonationDialogOpen] = useState(false);
  const handleImpersonation = async () => {
    setImpersonationDialogOpen(true);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid alignContent="center" sx={{ mr: 2 }}>
        <Badge
          badgeContent={userData.openQuotes?.length}
          color="info"
          max={99}
          overlap="circular"
        >
          <IconButton
            id="open-quotes-button"
            aria-controls={quoteMenuOpen ? "quotes-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={quoteMenuOpen ? "true" : undefined}
            disabled={userData.openQuotes?.length === 0}
            onClick={handleQuoteClick}
          >
            <MetaforaBellIcon
              sx={{
                color: userData.openQuotes?.length
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.disabled,
              }}
            />
          </IconButton>
        </Badge>
        <Menu
          id="quotes-menu"
          anchorEl={quoteAnchorEl}
          open={quoteMenuOpen}
          onClose={handleQuoteClose}
          MenuListProps={{
            "aria-labelledby": "open-quotes-button",
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: 500,
              },
            },
          }}
        >
          <Typography variant="h6" sx={{ mx: 2 }}>
            Open Quotes
          </Typography>
          <List
            sx={{
              width: "100%",
              maxWidth: 560,
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 500,
              "& ul": { padding: 0 },
            }}
          >
            {userData.openQuotes?.map((q, qIndex) => (
              <React.Fragment key={`open-quote-container-${q.hashedId}`}>
                {qIndex > 0 ? (
                  <Divider
                    key={`open-quote-divider-${q.hashedId}`}
                    variant="inset"
                  />
                ) : null}
                <ListItem
                  key={`open-quote-${q.hashedId}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="open"
                      onClick={() => navigate(`quote/${q.hashedId}`)}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    secondary={`Rate: ${formatCurrency(q.totalRate)}`}
                  >
                    <Grid container>
                      <Grid size={{ xs: 1 }}>
                        <ArrowCircleUp color="secondary" />
                      </Grid>
                      <Grid container size={{ xs: 5 }}>
                        <Typography>{`${q.stops?.[0].city}, ${q.stops?.[0].state}`}</Typography>
                      </Grid>
                      <Grid size={{ xs: 1 }}>
                        <ArrowCircleDown color="secondary" />
                      </Grid>
                      <Grid container size={{ xs: 5 }}>
                        <Typography>{`${(q.stops ?? []).at(-1)?.city}, ${(q.stops ?? []).at(-1)?.state}`}</Typography>
                      </Grid>
                      <Grid size={{ xs: 1 }} />
                      <Grid size={{ xs: 5 }}>
                        <Typography>{`${!!q.pickupDate ? formatDateV1(q.pickupDate) : "Unkown"}`}</Typography>
                      </Grid>
                      <Grid size={{ xs: 1 }} />
                      <Grid size={{ xs: 5 }}>
                        <Typography>{`${!!q.deliveryDate ? formatDateV1(q.deliveryDate) : "Unkown"}`}</Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Menu>
      </Grid>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          backgroundColor: theme.palette.text.disabled,
          alignSelf: "end",
          height: 40,
          width: 2,
        }}
      />
      <Grid>
        <IconButton
          id="user-icon-button"
          aria-controls={userMenuOpen ? "user-icon-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={userMenuOpen ? "true" : undefined}
          onClick={handleUserClick}
          sx={{ ml: 2 }}
        >
          <AccountCircle fontSize="large" sx={{ color: "white" }} />
        </IconButton>
        <Menu
          id="user-icon-menu"
          anchorEl={anchorEl}
          open={userMenuOpen}
          onClose={handleUserClose}
          MenuListProps={{
            "aria-labelledby": "user-icon-button",
          }}
        >
          <MenuItem
            disabled
            key="usermenu_userdetails"
            sx={{
              "&.Mui-disabled": {
                opacity: 1,
              },
            }}
          >
            <Grid container direction="column" mx={2}>
              <Typography>{userData?.tenant?.name}</Typography>
              <Typography>{userData?.email}</Typography>
              <Typography variant="body2">{userData?.userRole}</Typography>
            </Grid>
          </MenuItem>

          <Divider />
          {userData.originalRoleId === RoleTypeIds.SystemAdmin ? (
            <MenuItem
              key="usermenu_impersonation"
              onClick={handleImpersonation}
            >
              <SupervisedUserCircleSharpIcon sx={{ mr: 1 }} />
              Impersonate
            </MenuItem>
          ) : null}
          {userData.userRoleId === RoleTypeIds.SystemAdmin ? <Divider /> : null}
          <MenuItem key="usermenu_logout" onClick={handleLogout}>
            <Logout sx={{ mr: 1 }} />
            Logout
          </MenuItem>
        </Menu>
        <TenantImpersonationDialog
          dialogOpen={impersonationDialogOpen}
          setOpen={setImpersonationDialogOpen}
        />
      </Grid>
    </Grid>
  );
}
