import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CircularProgressWithLabel from "components/global/common/CircularProgressWithLabel/CircularProgressWithLabel";

interface ConfidenceLevelIndicatorProps {
  label: string;
  value: number;
  variant?: "body2" | "h6" | "subtitle1";
  boldLabel?: boolean;
  boldValue?: boolean;
  type?: "currency";
  valueFontSize?: number;
  disabled?: boolean;
}

const ConfidenceLevelIndicator = (props: ConfidenceLevelIndicatorProps) => {
  const getColor = () => {
    if (props.disabled) {
      return "gray";
    }
    const value = Number(props.value);
    if (value >= 88) {
      return "#4CAF50";
    }
    if (value >= 76) {
      return "#AAED56";
    }
    if (value >= 63) {
      return "#FFE247";
    }
    return "#F44336";
  };
  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography
          sx={{ color: "#00000099", fontWeight: props.boldLabel ? 600 : 0 }}
          variant={props.variant || "body2"}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid item>
        <CircularProgressWithLabel
          sx={{ color: getColor() }}
          variant="determinate"
          size={props.valueFontSize}
          value={props.value}
        ></CircularProgressWithLabel>
      </Grid>
    </Grid>
  );
};

export default ConfidenceLevelIndicator;
