import { SvgIcon } from "@mui/material";

const MarginPercentIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M17 15.3865C17 16.3665 16.2 17.1665 15.22 17.1665C14.24 17.1665 13.45 16.3665 13.45 15.3865C13.45 14.4065 14.24 13.6165 15.22 13.6165C16.2 13.6165 17 14.4065 17 15.3865ZM8.83 10.7665C9.81 10.7665 10.6 9.9765 10.6 8.9965C10.6 8.0065 9.81 7.2165 8.83 7.2165C7.84 7.2165 7.05 8.0065 7.05 8.9965C7.05 9.9765 7.84 10.7665 8.83 10.7665ZM7 15.6965L8.5 17.1965L17.03 8.6665L15.53 7.1665L7 15.6965ZM22 12.1665C22 17.6665 17.5 22.1665 12 22.1665C6.5 22.1665 2 17.6665 2 12.1665C2 6.6665 6.5 2.1665 12 2.1665C17.5 2.1665 22 6.6665 22 12.1665ZM20 12.1665C20 7.7565 16.41 4.1665 12 4.1665C7.59 4.1665 4 7.7565 4 12.1665C4 16.5765 7.59 20.1665 12 20.1665C16.41 20.1665 20 16.5765 20 12.1665Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default MarginPercentIcon;
