import { AccountDto } from "api/PricingPlatformApi.generated";

export interface CreateOfficeFormValues {
  name: string;
  accounts?: AccountDto[];
}

export const InitialCreateOfficeFormValues: CreateOfficeFormValues = {
  name: "",
  accounts: [],
};
