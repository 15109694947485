import { SvgIcon } from "@mui/material";

const FuelIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M4.23 7.73L4.22 7.72L7.94 4L9 5.06L6.89 7.17C7.83 7.53 8.5 8.43 8.5 9.5C8.5 9.8283 8.43534 10.1534 8.3097 10.4567C8.18406 10.76 7.99991 11.0356 7.76777 11.2678C7.29893 11.7366 6.66304 12 6 12C5.64 12 5.31 11.92 5 11.79V19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20C6.26522 20 6.51957 19.8946 6.70711 19.7071C6.89464 19.5196 7 19.2652 7 19V14.5C7 13.9696 7.21071 13.4609 7.58579 13.0858C7.96086 12.7107 8.46957 12.5 9 12.5H10L10 5.5C10 4.96957 10.2107 4.46086 10.5858 4.08579C10.9609 3.71071 11.4696 3.5 12 3.5L18 3.5C18.5304 3.5 19.0391 3.71071 19.4142 4.08579C19.7893 4.46086 20 4.96957 20 5.5L20 21.5H10V14H8.5V19C8.5 19.663 8.23661 20.2989 7.76777 20.7678C7.29893 21.2366 6.66304 21.5 6 21.5C5.6717 21.5 5.34661 21.4353 5.04329 21.3097C4.73998 21.1841 4.46438 20.9999 4.23223 20.7678C4.00009 20.5356 3.81594 20.26 3.6903 19.9567C3.56466 19.6534 3.5 19.3283 3.5 19L3.5 9.5C3.5 8.81 3.78 8.18 4.23 7.73ZM12 14V19.5H18V12.5L12 12.5V14ZM12 10.5H18V5.5L12 5.5V10.5ZM6 10.5C6.26522 10.5 6.51957 10.3946 6.70711 10.2071C6.89464 10.0196 7 9.76522 7 9.5C7 9.23478 6.89464 8.98043 6.70711 8.79289C6.51957 8.60536 6.26522 8.5 6 8.5C5.73478 8.5 5.48043 8.60536 5.29289 8.79289C5.10536 8.98043 5 9.23478 5 9.5C5 9.76522 5.10536 10.0196 5.29289 10.2071C5.48043 10.3946 5.73478 10.5 6 10.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default FuelIcon;
