import { PricingParametersDto } from "api/PricingPlatformApi.generated";

export const mergeUpdatedValues = (
    existingValues: PricingParametersDto[] | undefined,
    updatedValues: [],
  ) => {
    const createKey = (value: any) =>
      `${value.regionId}_${value.equipmentTypeId}_${value.mileageBucketId}_${value.leadTimeBucketId}`;
  
    const existingMap = new Map(
      existingValues?.map((value) => [createKey(value), value]),
    );
  
    updatedValues.forEach((updatedValue) => {
      const key = createKey(updatedValue);
      const existingValue = existingMap.get(key);
      if (existingValue) {
        Object.assign(existingValue, updatedValue);
      }
    });
  
    return Array.from(existingMap.values());
  };