import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TenantDto } from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import { useEffect, useState } from "react";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

const TenantManagement = () => {
  const [tenants, setTenants] = useState<TenantDto[]>([]);

  const { tenantApi } = useApiContext();

  const columns: GridColDef[] = [
    { field: "id", headerName: "Tenant ID", flex: 0 },
    { field: "name", headerName: "Tenant Name", flex: 1 },
  ];

  useEffect(() => {
    const getTenants = async () => {
      try {
        const data = await tenantApi.getTenants();
        setTenants(data);
      } catch (error) {}
    };

    getTenants();
  }, [tenantApi]);

  return (
    <Grid item xs={12} width="100%">
      <Paper variant="basePaper">
        <Grid container sx={{ paddingBottom: "2%" }}>
          <Grid item container xs={12}>
            <Typography variant="h4">Tenant Management</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            spacing={0.4}
          >
            <Grid item>
              <Button color="primary" variant="contained">
                Add Tenant
                <AddBusinessIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
            {/* <!-- Drawer --> */}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <DataGrid
            rows={tenants}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            autoHeight={true}
            pageSizeOptions={[5, 10, 20]}
            disableRowSelectionOnClick
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TenantManagement;
