import React from "react";
import { AccountDetailsProps } from "../Interfaces/interfaces";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AccountDetailsCardInformation from "./AccountDetailsCardInformation";

const AccountDetails: React.FC<AccountDetailsProps> = ({ quote }) => {
  return (
    <Box>
      <Paper
        sx={{
          borderRadius: "8px",
          border: "0.5px solid var(--Border-Primary, #E4E5E6)",
          background: "var(--Color-Neutral-White, #FFF)",
          boxShadow: "0px 0px 1.3px 0px rgba(151, 150, 149, 0.24)",
        }}
      >
        <Grid container spacing={1} direction="column" ml={2} mr={2}>
          <Typography variant="cardTitle" mt={1}>
            Details
          </Typography>
          <AccountDetailsCardInformation quote={quote} />
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccountDetails;
