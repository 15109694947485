import React, { useState } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import StatusChip from "components/global/common/StatusChip/StatusChip";
import ShareOutlined from "icons/ShareOutlined";
import { QuoteDetailsHeaderProps } from "./Interfaces/interfaces";
import { CloseIcon } from "@mantine/core";
import ShareQuote from "../quote-details/ShareQuote";
import { useDispatch, useSelector } from "react-redux";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import { showNotification } from "redux/slices/NotificationMessage/notificationMessageSlice";

const QuoteDetailsHeader: React.FC<QuoteDetailsHeaderProps> = ({
  hashId,
  status,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  const openDialog = () => {
    setOpenPopup(true);
  };

  const closeDialog = () => {
    setOpenPopup(false);
  };

  const handleCopyTextToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);

    setOpenPopup(false);

    dispatch(
      showNotification({
        message: `Quote details copied to clipboard`,
        delayMs: 2000,
        type: "success",
      })
    );
  };

  const handleCopyUrlToClipboard = () => {
    let currentUrl = window.location.href;
    console.log(window.location);
    currentUrl = currentUrl.replace("quote", "quote-details");

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        dispatch(
          showNotification({
            message: `Customer Quote link copied to clipboard`,
            delayMs: 2000,
            type: "success",
          })
        );
      })
      .catch((err) => console.error("Failed to copy link", err));
  };

  return (
    <Box mt={2}>
      <Grid container spacing={1} direction="row" sx={{ alignItems: "center" }}>
        <Typography variant="headerTitle">{hashId}</Typography>
        <StatusChip status={status} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton onClick={openDialog}>
          <ShareOutlined color="secondary" />
        </IconButton>
      </Grid>
      <Dialog
        open={openPopup}
        onClose={closeDialog}
        sx={{
          position: "fixed",
          left: "0 !important",
          top: "0 !important",
          zIndex: 9999,
        }}
      >
        <div style={{ maxWidth: "100%" }}>
          <DialogTitle component="div" sx={{ pb: 0 }}>
            <Typography
              textAlign="center"
              gutterBottom
              style={{ fontWeight: "bold" }}
              variant="h6"
            >
              Copy quote information to share it with a customer
            </Typography>
          </DialogTitle>

          <IconButton
            onClick={closeDialog}
            sx={{ position: "absolute", top: "13px", right: "10px" }}
          >
            <CloseIcon width={40} height={40} />
          </IconButton>
          <DialogContent>
            {quoteData ? (
              <ShareQuote
                shareQuote={quoteData}
                copyTextToClipboard={handleCopyTextToClipboard}
                copyUrlToClipboard={handleCopyUrlToClipboard}
              />
            ) : (
              ""
            )}
          </DialogContent>
        </div>
      </Dialog>
    </Box>
  );
};

export default QuoteDetailsHeader;
