export const confidenceColor = (confidenceLevel: number) => {
  if (confidenceLevel >= 85) {
    return "#4CAF50";
  } else if (confidenceLevel >= 76) {
    return "#AAED56";
  } else if (confidenceLevel >= 63) {
    return "#FFE247";
  }
  return "#F44336";
};

export const confidenceColorBg = (confidenceLevel: number) => {
  if (confidenceLevel >= 85) {
    return "#4CAF5033";
  } else if (confidenceLevel >= 76) {
    return "#AAED5633";
  } else if (confidenceLevel >= 63) {
    return "#FFE24733";
  }
  return "#F4433633";
};
