import React, { useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { PricingParameterValueDto } from "api/PricingPlatformApi.generated";
import { ToCurrency } from "utilities/functions/DecimalToCurrency";

const PricingParameterValueCell = ({
  value,
  editRegion,
  updateValue,
}: {
  value: PricingParameterValueDto | undefined;
  editRegion: boolean;
  updateValue: any;
}) => {
  const [val, setVal] = useState<PricingParameterValueDto | undefined>(value);
  const handleMinimumMarkupChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = { ...val, minimumMarkup: parseFloat(event.target.value) };
    // @ts-ignore
    setVal(newValue);
    updateValue(newValue);
  };

  const handleMinimumAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = { ...val, minimumAmount: parseFloat(event.target.value) };
    // @ts-ignore
    setVal(newValue);
    updateValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {editRegion ? (
          <TextField
            variant="standard"
            value={val?.minimumMarkup}
            sx={{ width: "50%" }}
            InputProps={{
              style: { fontSize: "14px", padding: "1px" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            size="small"
            onChange={handleMinimumMarkupChange}
            type="number"
          />
        ) : (
          `${val?.minimumMarkup} %`
        )}
      </Grid>
      <Grid item xs={12}>
        {editRegion ? (
          <TextField
            variant="standard"
            value={val?.minimumAmount}
            sx={{ width: "50%" }}
            InputProps={{
              style: { fontSize: "14px", padding: "1px" },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            size="small"
            onChange={handleMinimumAmountChange}
            type="number"
          />
        ) : (
          ToCurrency(val?.minimumAmount)
        )}
      </Grid>
    </Grid>
  );
};

export default PricingParameterValueCell;
