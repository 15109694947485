import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AccountDto } from "api/PricingPlatformApi.generated";
import * as React from "react";
import ClearIcon from "@mui/icons-material/RemoveCircle";
import MtfaAccountSearch from "components/global/common/MtfaAccountSearch/MtfaAccountSearch";

export interface ManageOfficeAccountsProps {
  listUpdated: any;
  accounts: any;
  setAccounts: any;
}

const ManageOfficeAccounts = (props: ManageOfficeAccountsProps) => {
  const { accounts, setAccounts } = props;
  const handleRemoveAccount = (value: AccountDto) => () => {
    const currentIndex = accounts.findIndex(
      (account: AccountDto) => account.id === value.id,
    );
    const newChecked = [...accounts];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setAccounts(newChecked);
    props.listUpdated(newChecked);
  };

  return (
    <div>
      <MtfaAccountSearch
        required={false}
        id="accountSearch"
        label="Search Account"
        name="searchAccount"
        clearAfterSelect={true}
        disabledAccounts={accounts}
        onAccountSelected={(newAccounts: AccountDto[]) => {
          if (newAccounts) {
            var newList = [...accounts, ...newAccounts];
            setAccounts(newList);
            props.listUpdated(newList);
          }
        }}
      ></MtfaAccountSearch>
      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {accounts?.map((value: AccountDto) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <ListItem
              key={value.id}
              divider={true}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={handleRemoveAccount(value)}
                >
                  <ClearIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ManageOfficeAccounts;
