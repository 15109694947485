import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AccountDto, OfficeDto } from "api/PricingPlatformApi.generated";
import Edit from "icons/Edit";
import { useNavigate } from "react-router-dom";

interface OfficeTableProps {
  officeList: OfficeDto[];
}

const OfficeTable = (props: OfficeTableProps) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Office", flex: 1.1 },
    { field: "teamsCount", headerName: "# Teams", flex: 1.1 },
    {
      field: "accounts",
      headerName: "Accounts",
      flex: 1.1,
      valueGetter: (value, row) => {
        const accountNames = row.accounts?.map(
          (account: AccountDto) => account.name
        );
        return `${accountNames?.[0] ?? ""} ${row.accounts?.length <= 1 ? "" : "+" + (row.accounts?.length - 1)} `;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <Edit
            sx={{ cursor: "pointer" }}
            onClick={(e: any) => {
              navigate("/edit_office/" + params.row.id);
            }}
          />
        );
      },
    },
  ];
  const { officeList } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={officeList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        autoHeight={true}
        pageSizeOptions={[5, 10, 20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default OfficeTable;
