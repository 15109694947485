import * as React from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Button, Drawer } from "@mui/material";

const drawerWidth = 250;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PublicLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const { hashedId } = useParams();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <Typography variant="h5" noWrap component="div" mt={1}>
                Pricing Platform - Quote #{hashedId}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }} container justifyContent="flex-end">
              <Button
                color="info"
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                REQUEST QUOTE
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <DrawerHeader />
          </Grid>
          <Grid container>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PublicLayout;
