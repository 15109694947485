import { SvgIcon } from "@mui/material";

const PercentIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M20.54 9.20994V4.49994H15.84L12.5 1.18994L9.21 4.49994H4.5V9.20994L1.19 12.4999L4.5 15.8399V20.5399H9.21L12.5 23.8499L15.84 20.5399H20.54V15.8399L23.85 12.4999L20.54 9.20994ZM9.33 7.54994C10.31 7.54994 11.1 8.33994 11.1 9.32994C11.1 9.79937 10.9135 10.2496 10.5816 10.5815C10.2496 10.9135 9.79944 11.0999 9.33 11.0999C8.34 11.0999 7.55 10.3099 7.55 9.32994C7.55 8.33994 8.34 7.54994 9.33 7.54994ZM15.72 17.4999C14.74 17.4999 13.95 16.6999 13.95 15.7199C13.95 15.2505 14.1365 14.8003 14.4684 14.4684C14.8004 14.1364 15.2506 13.9499 15.72 13.9499C16.7 13.9499 17.5 14.7399 17.5 15.7199C17.5 16.192 17.3125 16.6448 16.9787 16.9786C16.6448 17.3124 16.1921 17.4999 15.72 17.4999ZM9 17.5299L7.5 16.0299L16.03 7.49994L17.53 8.99994L9 17.5299Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default PercentIcon;
