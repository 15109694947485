import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ListItemDto,
  PricingParameterValueDto,
  PricingRegionDto,
} from "../../../api/PricingPlatformApi.generated";
import Edit from "../../../icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import PricingParametersGrid from "./PricingParametersGrid";
import { updatePricingParameters } from "redux/slices/PricingParameters/pricingParametersSlice";

const PricingRegion = ({
  region,
  equipmentType,
  canEdit,
}: {
  region: PricingRegionDto;
  equipmentType: ListItemDto;
  canEdit: boolean;
}) => {
  const dispatch = useDispatch();

  const leadTimeBuckets = useSelector(
    (state: any) => state.pricingParametersReducer.leadTimeBuckets
  );
  const mileageBuckets = useSelector(
    (state: any) => state.pricingParametersReducer.mileageBuckets
  );

  const [editRegion, setEditRegion] = useState(false);

  const [updatedValues, setUpdatedValues] = useState<
    PricingParameterValueDto[]
  >([]);

  const handleSave = async () => {
    const saveUpdatedParams = async () => {
      dispatch(updatePricingParameters(updatedValues));
    };
    saveUpdatedParams();
    setUpdatedValues([]);
    setEditRegion(false);
  };

  return (
    <Card sx={{}} variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12} container direction="row">
            <Grid item xs={6}>
              <Typography variant="h6">
                {region.name} | {equipmentType.label}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end" mb={1}>
              {canEdit ? (
                editRegion ? (
                  <ButtonGroup variant="contained">
                    <Button onClick={handleSave}>Save</Button>
                    <Button
                      onClick={() => {
                        setEditRegion(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                ) : (
                  <IconButton
                    onClick={() => setEditRegion(true)}
                    sx={{ height: 36.5 }}
                  >
                    <Edit />
                  </IconButton>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
              {region.description}
            </Typography>
          </Grid>
        </Grid>
        <PricingParametersGrid
          regionId={region.regionId ?? 0}
          equipmentTypeId={equipmentType.value}
          leadTimeBuckets={leadTimeBuckets}
          mileageBuckets={mileageBuckets}
          editRegion={editRegion}
          updatedValues={updatedValues}
          setUpdatedValues={setUpdatedValues}
        />
      </CardContent>
    </Card>
  );
};

export default PricingRegion;
