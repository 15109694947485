const batchColumns = [
  { label: "Account*", value: "Account" },
  { label: "Equipment*", value: "Equipment" },
  { label: "Origin City*", value: "Origin City" },
  { label: "Origin State*", value: "Origin State" },
  { label: "Origin ZIP Code*", value: "Origin ZIP Code" },
  { label: "Pickup Date", value: "Pickup Date" },
  { label: "Estimated Pickup Time", value: "Estimated Pickup Time" },
  { label: "Destination City*", value: "Destination City" },
  { label: "Destination State*", value: "Destination State" },
  { label: "Destination ZIP Code*", value: "Destination ZIP Code" },
  { label: "Delivery Date", value: "Delivery Date" },
  { label: "Estimated Delivery Time", value: "Estimated Delivery Time" },
  { label: "Temperature Min", value: "Temperature Min" },
  { label: "Temperature Max", value: "Temperature Max" },
  { label: "Tarps Required", value: "Tarps Required" },
  { label: "Oversize / Overdimensional", value: "Oversize / Overdimensional" },
  { label: "Commodity", value: "Commodity" },
  { label: "Specialized Services", value: "Specialized Services" },
  { label: "Reference Number", value: "Reference Number" },
  { label: "Shipper", value: "Shipper" },
  { label: "Receiver", value: "Receiver" },
];

export default batchColumns;
