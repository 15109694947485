import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  QuoteResponseDto,
  QuoteTargetSellRateDto,
} from "api/PricingPlatformApi.generated";
import PricingOverrideTypes from "constants/PricingOverrideType";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../utilities/functions/FormatCurrency";
import { PricingOverrideInterface } from "redux/slices/PricingOverride/interface";
import { PricingOverrideActionTypes } from "redux/ActionTypes/pricingOverrideActionTypes";

interface ruleBasedMarkupProps {
  targetSellRate: QuoteTargetSellRateDto | null;
  updateTargetSellRate: (
    quoteTargetSellRateDto: QuoteTargetSellRateDto
  ) => void;
  totalDistance: number;
  canEditMarkup: boolean;
  baseQuote?: QuoteResponseDto;
}

const CombinedMarkup = (props: ruleBasedMarkupProps) => {
  const {
    totalDistance,
    targetSellRate,
    updateTargetSellRate,
    canEditMarkup,
    baseQuote,
  } = props;

  const pricingOverrideState: PricingOverrideInterface = useSelector(
    (state: any) => state.pricingOverrideReducer
  );

  const [toggles, setToggles] = useState(
    (targetSellRate?.markups || []).map((markup) => markup.enabled)
  );

  useEffect(() => {
    if (
      pricingOverrideState?.type ===
        PricingOverrideActionTypes.PricingOverrideSuccess &&
      (pricingOverrideState?.quote?.pricingOverrideDto?.overrideType ===
        PricingOverrideTypes.TargetSellRate ||
        pricingOverrideState?.quote?.pricingOverrideDto?.overrideType ===
          PricingOverrideTypes.TargetSellRpm)
    ) {
      setToggles((prevToggles) => {
        return prevToggles.map(() => false);
      });
    }
    if (
      pricingOverrideState?.type ===
        PricingOverrideActionTypes.ResetPricingOverride &&
      !pricingOverrideState.quote?.pricingOverrideDto
    ) {
      setToggles((prevToggles) => {
        return prevToggles.map(() => true);
      });
    }
  }, [pricingOverrideState]);

  const handleToggle = (index: number) => {
    setToggles((prevToggles) => {
      const newToggles = [...prevToggles];
      newToggles[index] = !newToggles[index];
      if (targetSellRate?.markups?.[index]) {
        targetSellRate.markups[index].enabled = newToggles[index];
      }
      if (targetSellRate && newToggles[index]) {
        targetSellRate.targetSellRate =
          (targetSellRate.targetSellRate ?? 0) +
          (targetSellRate?.markups?.[index].amount ?? 0);
        targetSellRate.totalMarginAmount =
          (targetSellRate.totalMarginAmount ?? 0) +
          (targetSellRate?.markups?.[index].amount ?? 0);
        targetSellRate.totalMarginPercent =
          (targetSellRate.totalMarginPercent ?? 0) +
          (targetSellRate?.markups?.[index].markupPercent ?? 0);
        targetSellRate.totalRpm = targetSellRate.targetSellRate / totalDistance;

        updateTargetSellRate(targetSellRate);
      } else if (targetSellRate) {
        targetSellRate.targetSellRate =
          (targetSellRate?.targetSellRate ?? 0) -
          (targetSellRate?.markups?.[index].amount ?? 0);
        targetSellRate.totalMarginAmount =
          (targetSellRate.totalMarginAmount ?? 0) -
          (targetSellRate?.markups?.[index].amount ?? 0);
        targetSellRate.totalMarginPercent =
          (targetSellRate.totalMarginPercent ?? 0) -
          (targetSellRate?.markups?.[index].markupPercent ?? 0);

        targetSellRate.totalRpm = targetSellRate.targetSellRate / totalDistance;

        updateTargetSellRate(targetSellRate);
      }
      return newToggles;
    });
  };

  const rows = (targetSellRate?.markups || []).map(
    (ruleBasedMarkup, index) => ({
      id: index + 1,
      enabled: ruleBasedMarkup.enabled ? "Enabled" : "Disabled",
      amountPer: ruleBasedMarkup?.markupPercent
        ? `${ruleBasedMarkup?.markupPercent.toFixed(2)}%`
        : null,
      amountDol:
        ruleBasedMarkup?.amount !== undefined
          ? formatCurrency(ruleBasedMarkup?.amount)
          : null,
      conditionField: ruleBasedMarkup.conditions
        ?.map((condition) => condition.fieldName)
        .join(", "),
      matchedValue: ruleBasedMarkup.conditions
        ?.map((condition) => condition.value)
        .join(", "),
    })
  );

  return (
    <>
      <Table>
        <TableHead style={{ color: "#026EA1" }}>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Based On</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Quote Value</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>% Markup</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>$ Markup</TableCell>
            <TableCell style={{ fontWeight: "bold" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ color: "#026EA1" }}>
              <Grid container>
                <Grid item xs={12}>
                  Region
                </Grid>
                <Grid item xs={12}>
                  Milage
                </Grid>
                <Grid item xs={12}>
                  Equipment Type
                </Grid>
                <Grid item xs={12}>
                  Lead Time
                </Grid>
              </Grid>
            </TableCell>
            <TableCell style={{ color: "#026EA1" }}>
              <Grid container>
                <Grid item xs={12}>
                  {baseQuote?.regionName}
                </Grid>
                <Grid item xs={12}>
                  {targetSellRate?.mileageRange}
                </Grid>
                <Grid item xs={12}>
                  {baseQuote?.equipmentType?.label}
                </Grid>
                <Grid item xs={12}>
                  {baseQuote?.leadTimeRange}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell width={100}>
              {targetSellRate?.minimumMarkup !== undefined
                ? `${targetSellRate?.minimumMarkup.toFixed(2)}%`
                : null}
            </TableCell>
            <TableCell width={100}>
              {formatCurrency(targetSellRate?.minimumAmount ?? 0)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>

          {rows.map((row, index) => (
            <TableRow
              key={row.id}
              style={toggles[index] ? {} : { textDecoration: "line-through" }}
            >
              <TableCell style={{ color: "#026EA1" }}>
                {row.conditionField}
              </TableCell>
              <TableCell style={{ color: "#026EA1" }}>
                {row.matchedValue}
              </TableCell>
              <TableCell>{row.amountPer}</TableCell>
              <TableCell>{row.amountDol}</TableCell>
              <TableCell>
                <Switch
                  checked={toggles[index]}
                  onChange={() => handleToggle(index)}
                  disabled={!canEditMarkup}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={2}
              style={{ color: "#0E2C3B", fontWeight: "bold" }}
            >
              Rule-based Markup Total
            </TableCell>
            <TableCell style={{ color: "#0E2C3B", fontWeight: "bold" }}>
              {`${(targetSellRate?.totalMarginPercent ?? 0).toFixed(2)}%`}
            </TableCell>
            <TableCell style={{ color: "#0E2C3B", fontWeight: "bold" }}>
              {formatCurrency(targetSellRate?.totalMarginAmount ?? 0)}
            </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
export default CombinedMarkup;
