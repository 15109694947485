import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import WarningAmberRounded from "../../../icons/WarningAmberRounded";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/quote/history");
  };

  return (
    <Grid container justifyContent="center" size={{ xs: 12 }}>
      <Card
        sx={{
          width: 600,
          padding: 5,
        }}
      >
        <CardContent>
          <Grid container direction="column" alignItems="center" spacing={6}>
            <Grid>
              <WarningAmberRounded
                sx={{
                  width: "85px",
                  height: "85px",
                }}
                color="secondary"
              />
            </Grid>
            <Grid>
              <Typography variant="h2" color="black">
                Page Not Found
              </Typography>
            </Grid>
            <Grid>
              <Button color="primary" variant="contained" onClick={handleBack}>
                Go Back
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PageNotFound;
