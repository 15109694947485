import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationInterface, NotificationMessage } from "./interface";

const initialState: NotificationInterface = {
  message: null,
};

const notificationMessageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<NotificationMessage>) => {
      state.message = action.payload;
    },
    hideNotification: (state) => {
      state.message = null;
    },
  },
});

export const { showNotification, hideNotification } =
  notificationMessageSlice.actions;

export default notificationMessageSlice.reducer;
