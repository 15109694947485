import { Typography } from "@mui/material";
import { StopDto } from "api/PricingPlatformApi.generated";

interface StopSummaryProps {
  stop: StopDto | null;
}

const StopSummary = (props: StopSummaryProps) => {
  const { stop } = props;

  return (
    <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
      {stop?.city}, {stop?.state} {stop?.zipCode}
    </Typography>
  );
};

export default StopSummary;
