import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Alert, Box, Paper, Snackbar, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import AddBusiness from "../../../icons/AddBusiness";
import OfficeTable from "components/pages/office-management/OfficeTable";
import { AccountDto, OfficeDto } from "api/PricingPlatformApi.generated";
import AccountTable from "components/pages/office-management/AccountTable";
import MtfaTabPanel from "components/global/common/MtfaTabPanel/MtfaTabPanel";
import { useApiContext } from "contexts/ApiProvider";
import AccountDrawer from "./AccountDrawer";

const OfficeManagement = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = React.useState(false);
  const { state } = useLocation();
  const [officeList, setOfficeList] = React.useState<OfficeDto[]>([]);
  const [accountList, setAccountList] = React.useState<AccountDto[]>([]);

  const [tabValue, setTabValue] = React.useState(0);
  const { officeApi, accountApi } = useApiContext();

  const [accountDrawerOpen, setAccountDrawerOpen] = React.useState(false);

  const [selectedAccount, setSelectedAccount] =
    React.useState<AccountDto | null>(null);

  const toggleAccountDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setAccountDrawerOpen(open);
    };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (state?.officeCreated) {
      setShowToast(state.officeCreated);
    }
  }, [state]);

  useEffect(() => {
    const getOffices = async () => {
      try {
        const data = await officeApi.getOfficeList();
        setOfficeList(data);
      } catch (error) {
        console.error("Error retrieving offices.", error);
      }
    };

    const getAccounts = async () => {
      try {
        const data = await accountApi.search("");
        setAccountList(data);
      } catch (error) {
        console.error("Error retrieving offices.", error);
      }
    };
    getOffices();
    getAccounts();
  }, [officeApi, accountApi, selectedAccount]);

  return (
    <Grid size={{ xs: 12 }} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid size={{ xs: 12 }} mb={2}>
            <Typography variant="h4">Office Management</Typography>
          </Grid>
          <Grid size={{ xs: 12 }} mb={2}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid size={{ xs: 12 }} container alignItems="center">
                <Grid size={{ xs: 8 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Office Management"
                  >
                    <Tab label="Offices" />
                    <Tab label="Accounts" />
                  </Tabs>
                </Grid>
                <Grid
                  container
                  size={{ xs: 4 }}
                  justifyContent="flex-end"
                  direction="row"
                  spacing={0.4}
                >
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => navigate("/settings/add_office")}
                    >
                      Add Office
                      <AddBusiness sx={{ ml: 1 }} />
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleAccountDrawer(true)}
                    >
                      Add Account
                      <AddBusiness sx={{ ml: 1 }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid mt={2} container size={{ xs: 12 }}>
          <MtfaTabPanel value={tabValue} index={0}>
            <OfficeTable officeList={officeList} />
          </MtfaTabPanel>
          <MtfaTabPanel value={tabValue} index={1}>
            <AccountTable accountList={accountList} />
          </MtfaTabPanel>
        </Grid>
      </Paper>
      <Snackbar
        open={showToast}
        autoHideDuration={4000}
        onClose={() => setShowToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Office created successfully
        </Alert>
      </Snackbar>

      <AccountDrawer
        newAccountAdded={(account: AccountDto) => {
          setSelectedAccount(account);
        }}
        account={null}
        setState={setAccountDrawerOpen}
        state={accountDrawerOpen}
        toggleDrawer={toggleAccountDrawer}
        existingAccounts={accountList}
        existingOffices={officeList}
      />
    </Grid>
  );
};

export default OfficeManagement;
