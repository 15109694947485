import { AccountDto } from "api/PricingPlatformApi.generated";
import { useApiContext } from "contexts/ApiProvider";
import { Autocomplete } from "mui-rff";
import { useEffect, useState } from "react";

export type MtfaAccountSearchProps = {
  id?: string;
  name: string;
  label?: string;
  required: boolean;
  onAccountSelected?: any;
  disabled?: boolean;
  defaultValue?: AccountDto[];
  clearAfterSelect?: boolean;
  disabledAccounts?: AccountDto[];
};

const MtfaAccountSearch = (props: MtfaAccountSearchProps) => {
  const initialResults: Partial<AccountDto>[] = [];
  const [results, setResults] = useState(initialResults);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<AccountDto[]>([]);
  const { accountApi } = useApiContext();

  useEffect(() => {
    const getAccounts = async () => {
      setIsLoading(true);
      const data = await accountApi.search("");
      const accountsWoOffice = data.filter(
        (account) => account.offices?.length === 0
      );
      setResults(accountsWoOffice);
      setIsLoading(false);
    };

    getAccounts();
  }, []);

  const onChange = (_event: any, accountValue: AccountDto) => {
    if (_event?.currentTarget.id !== "") {
      props.onAccountSelected(accountValue);
    }
    if (props.clearAfterSelect) {
      setValue([]);
    }
  };

  return (
    <Autocomplete
      multiple={true}
      value={value}
      autoHighlight
      name={props.name}
      disabled={props.disabled}
      id={props.id}
      label={props.label}
      defaultValue={props.defaultValue}
      required={props.required}
      options={results}
      getOptionValue={(option) => option}
      loading={isLoading}
      noOptionsText={"Search Accounts"}
      getOptionDisabled={(option) =>
        props.disabledAccounts?.findIndex(
          (account) => account.id === option.id
        ) !== -1
      }
      getOptionLabel={(option) => `${option.name}`}
      isOptionEqualToValue={(option, value) => {
        if (value.id === undefined) return true;
        return option.id === value.id;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>{`${option.name}`}</li>
      )}
      onChange={(e, value: any) => onChange(e, value)}
      freeSolo={false}
      disableCloseOnSelect={true}
      clearOnEscape={true}
      onKeyDown={(e: any) => {
        console.log("event fired", e.currentTarget);
        if (e.key === "Enter") {
          console.log("event fired", e.target.value);
        }
      }}
    />
  );
};

export default MtfaAccountSearch;
