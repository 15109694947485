import Grid from "@mui/material/Grid2";
import {
  Paper,
  Divider,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Skeleton,
  Tabs,
  Box,
  Tab,
} from "@mui/material";
import SimilarQuotesInsights from "./SimilarQuotesInsights";
import { range } from "@mantine/hooks";
import CalendarDateUnknownIcon from "icons/svgs/CalendarDateUnknownIcon";
import CalendarWithDateIcon from "icons/svgs/CalendarWithDateIcon";
import FilterIcon from "icons/svgs/FilterIcon";
import OpenFolderIcon from "icons/svgs/OpenFolderIcon";
import PercentIcon from "icons/svgs/PercentIcon";
import React, { useState } from "react";
import UpcomingLoadsInsights from "./UpcomingLoadsInsights";
import HistoricalLoadsInsights from "./HistoricalLoadsInsights";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
            borderRadius: "8px",
            border: "1px solid  #E4E5E6",
            background: "#F9F9FA",
            boxShadow: "0px 0px 2.8px 0px rgba(151, 150, 149, 0.24)",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InsightLists = () => {
  const [value, setValue] = useState<number>(0);

  const [range, setRange] = useState<number>(7);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "16px 12px",
        gap: "12px",
        borderRadius: "8px",
        border: "1px solid #E4E5E6",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <Grid container>
            {/* <Grid ></Grid> */}
            <Tabs
              sx={{ flex: 1 }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Similar Quotes" {...a11yProps(0)} />
              <Tab label="Upcoming Loads" {...a11yProps(1)} />
              <Tab label="Historical Loads" {...a11yProps(2)} />
            </Tabs>

            <IconButton sx={{ color: "#396A6A", px: "10px" }}>
              <FilterIcon />
            </IconButton>
            <Select
              value={range}
              size="small"
              onChange={(e) => setRange(Number(e.target.value))}
              sx={{ width: "128px" }}
            >
              <MenuItem value={7}>7 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={180}>180 Days</MenuItem>
            </Select>
          </Grid>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SimilarQuotesInsights />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UpcomingLoadsInsights />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <HistoricalLoadsInsights />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default InsightLists;
