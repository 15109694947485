import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Alert, Box, Paper, Snackbar, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  EquipmentTypeDto,
  ListItemDto,
  RequestQuoteOptionsDto,
} from "api/PricingPlatformApi.generated";
import MtfaTabPanel from "components/global/common/MtfaTabPanel/MtfaTabPanel";
import { useApiContext } from "contexts/ApiProvider";
import ListItemTable from "./ListItemTable";
import EquipmentTypeTable from "./EquipmentTypeTable";
import { useSelector } from "react-redux";

const SettingsManagement = () => {
  const [showToast, setShowToast] = React.useState(false);
  const { state } = useLocation();

  const [equipmentList, setEquipmentList] = React.useState<EquipmentTypeDto[]>(
    []
  );
  const [commodityList, setCommodityList] = React.useState<ListItemDto[]>([]);
  const [specializedServiceList, setSpecializedServiceList] = React.useState<
    ListItemDto[]
  >([]);

  const [tabValue, setTabValue] = React.useState(0);
  const { equipmentApi, commodityApi, specializedServiceApi } = useApiContext();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (state?.officeCreated) {
      setShowToast(state.officeCreated);
    }
  }, [state]);

  const options = useSelector<any, RequestQuoteOptionsDto>(
    (state: any) => state.userRequestReducer.options
  );

  console.log("settings", options);

  useEffect(() => {
    if (options !== undefined) {
      setEquipmentList(options.equipmentTypes ?? []);
      setCommodityList(options.commodities ?? []);
      setSpecializedServiceList(options.specializedServices ?? []);
    }
  }, [options]);

  return (
    <Grid size={{ xs: 12 }} width="100%">
      <Paper variant="basePaper">
        <Grid container>
          <Grid size={{ xs: 12 }} mb={2}>
            <Typography variant="h4">Settings</Typography>
          </Grid>
          <Grid size={{ xs: 12 }} mb={2}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid size={{ xs: 12 }} container alignItems="center">
                <Grid size={{ xs: 8 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Settings"
                  >
                    <Tab label="Equipment Types" />
                    <Tab label="Commodities" />
                    <Tab label="Specialized Services" />
                    <Tab label="Reasons Lost" />
                  </Tabs>
                </Grid>
                <Grid
                  container
                  size={{ xs: 4 }}
                  justifyContent="flex-end"
                  direction="row"
                  spacing={0.4}
                >
                  <Grid></Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid mt={2} container size={{ xs: 12 }}>
          <MtfaTabPanel value={tabValue} index={0}>
            <EquipmentTypeTable dataList={equipmentList} />
          </MtfaTabPanel>
          <MtfaTabPanel value={tabValue} index={1}>
            <ListItemTable dataList={commodityList} />
          </MtfaTabPanel>
          <MtfaTabPanel value={tabValue} index={2}>
            <ListItemTable dataList={specializedServiceList} />
          </MtfaTabPanel>
          <MtfaTabPanel value={tabValue} index={3}>
            {/* <ListItemTable dataList={options.reasonLostOptions} /> */}
          </MtfaTabPanel>
        </Grid>
      </Paper>
      <Snackbar
        open={showToast}
        autoHideDuration={4000}
        onClose={() => setShowToast(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Office created successfully
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SettingsManagement;
