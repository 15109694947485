import { ThemeOptions, Typography } from "@mui/material";
import { createTheme, ThemeProvider, Paper } from "@mui/material";
import ThemeColors from "./theme_colors";

import type {} from "@mui/x-data-grid/themeAugmentation";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    navLinkText: React.CSSProperties;
    breadcrumbText: React.CSSProperties;
  }

  interface PaperVariants {
    basePaper: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    navLinkText?: React.CSSProperties;
    breadcrumbText?: React.CSSProperties;
    headerTitle?: React.CSSProperties;
    cardTitle?: React.CSSProperties;
    accountCardInfoTitle?: React.CSSProperties;
    gridText: React.CSSProperties;
    gridTextBold: React.CSSProperties;
    gridTextLarge: React.CSSProperties;

    cardTextLarge: React.CSSProperties;
    cardTextLargeHeader: React.CSSProperties;
    cardTextHeader: React.CSSProperties;
    cardText: React.CSSProperties;

    insightLabel: React.CSSProperties;
    insightHeader: React.CSSProperties;
    insightSubheader: React.CSSProperties;

    insightCalloutHeader: React.CSSProperties;
    insightCalloutText: React.CSSProperties;
  }

  interface PaperVariantsOptions {
    basePaper?: React.CSSProperties;
  }

  interface Palette {
    breadcrumbs: Palette["primary"];
  }
  interface PaletteOptions {
    breadcrumbs?: PaletteOptions["primary"];
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    navLinkText: true;
    breadcrumbText: true;
    headerTitle: true;
    cardTitle: true;
    accountCardInfoTitle: true;
    gridText: true;
    gridTextBold: true;
    gridTextLarge: true;

    cardTextLarge: true;
    cardTextLargeHeader: true;
    cardTextHeader: true;
    cardText: true;

    insightLabel: true;
    insightHeader: true;
    insightSubheader: true;

    insightCalloutHeader: true;
    insightCalloutText: true;
  }
}
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    basePaper: true;
  }
}

export const NewMetaforaTheme: ThemeOptions = {
  palette: {
    //dark color
    primary: {
      main: "#302E2C",
    },
    secondary: {
      // Green
      main: ThemeColors.metaforaGreen,
    },
    info: {
      // Orange
      main: "#e58552",
    },
    text: {
      disabled: "#6F6D6C",
    },
    breadcrumbs: {
      main: "#F2F2F3",
      light: "#F2F2F3",
      dark: "#F2F2F3",
      contrastText: "#595856",
    },
    action: {},
    // Light Green
    background: { default: "#c4d2d2" },
  },
  typography: {
    navLinkText: {
      fontSize: 16,
      color: "white",
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "24px",
    },
    breadcrumbText: {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Roboto",
    },
    headerTitle: {
      fontSize: "large",
    },
    cardTitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: ".8px",
    },
    accountCardInfoTitle: {
      fontSize: 12,
      fontWeight: "bold",
      letterSpacing: ".8px",
    },
    gridText: {
      fontSize: "12px",
      // lineHeight: "28px",
      color: ThemeColors.textBody,
    },
    gridTextBold: {
      fontSize: "12px",
      lineHeight: "28px",
      fontWeight: "bold",
      color: ThemeColors.textDark,
    },
    gridTextLarge: {
      fontSize: "14px",
      lineHeight: "28px",
      color: ThemeColors.textBody,
    },
    cardTextLarge: {
      fontFamily: "Poppins",
      fontSize: "24px",
      lineHeight: "20px",
      color: ThemeColors.textHeadings,
      fontWeight: "600",
    },
    cardTextLargeHeader: {
      fontSize: "12px",
      lineHeight: "20px",
      color: ThemeColors.textDisabled,
    },
    cardTextHeader: {
      fontSize: "12px",
      lineHeight: "12px",
      color: ThemeColors.textDisabled,
      fontWeight: "600",
    },
    cardText: {
      fontSize: "12px",
      lineHeight: "20px",
      color: ThemeColors.textHeadings,
      fontWeight: "600",
    },

    insightLabel: {
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "28px",
      color: ThemeColors.textHeadings,
      fontWeight: "600",
    },
    insightHeader: {
      fontFamily: "Poppins",
      fontSize: "10px",
      lineHeight: "20px",
      color: ThemeColors.textHeadings,
      fontWeight: "600",
    },
    insightSubheader: {
      fontFamily: "Roboto",
      fontSize: "8px",
      lineHeight: "13px",
      color: ThemeColors.textBody,
      fontWeight: "300",
    },

    insightCalloutHeader: {
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "20px",
      color: ThemeColors.textHeadings,
      fontWeight: "400",
    },
    insightCalloutText: {
      fontFamily: "Roboto",
      fontSize: "10px",
      lineHeight: "12px",
      color: ThemeColors.textBody,
      fontWeight: "400",
    },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          navLinkText: "span",
          breadcrumbText: "span",
        },
      },
    },

    MuiPaper: {
      variants: [
        {
          props: { variant: "basePaper" },
          style: {
            padding: "40px 40px 12px 40px",
            width: "100%",
            margin: 0,
            minHeight: "calc(100vh - 104px)",
            borderRadius: 0,

            backgroundColor: "#fff", // Customize the background color
            boxShadow: "none", // Customize shadow if needed
          },
        },
      ],
    },
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: "#F2F2F3",
    },
  },
};
